import { getCountryCodeWithoutLanguage } from '../../../../providers/CountryData';
import { getUnitByNameWithoutLanguage } from '../../../../providers/UnitData';

function isValidDate(date) {
  return date && Object.prototype.toString.call(date) === '[object Date]' && !isNaN(date);
}

function checkDateFormat(str) {
  if (!isNaN(str)) return false;
  if (!str.includes('/')) return false;

  let datesplit = str.split('/');

  if (datesplit.length !== 3) return false;

  if (datesplit[0].length === 1) {
    datesplit[0] = '0' + datesplit[0];
  }

  if (datesplit[1].length === 1) {
    datesplit[1] = '0' + datesplit[1];
  }

  if (
    datesplit[0].length === 2 &&
    !isNaN(datesplit[0]) &&
    datesplit[1].length === 2 &&
    !isNaN(datesplit[1]) &&
    datesplit[2].length === 4 &&
    !isNaN(datesplit[2])
  )
    return true;

  return false;
}

function checkNewDateFormat(number) {
  const _dateFormatString = String(number).trim(); // "20230224"

  let _year = _dateFormatString.substring(0, 4);
  let _month = _dateFormatString.substring(4, 6);
  let _day = _dateFormatString.substring(6, 8);

  if (isValidDate(new Date(`${_year}-${_month}-${_day}`))) return true;
  return false;
}

function checkExcelDateFormat(number) {
  return new Date(Math.round((number - 25569) * 86400 * 1000));
}

function checkYear(str) {
  const _year = str.split('/')[2];

  if (Number(_year) > 2563 || Number(_year) + 543 > 2563) return false;
  return true;
}

const documentTypeList = [
  'RECEIPT-TAXINVOICE-ABB',
  'ใบเสร็จรับเงิน/ใบกำกับภาษีอย่างย่อ',
  'RECEIPT-TAXINVOICE',
  'ใบเสร็จรับเงิน/ใบกำกับภาษี',
  'TAXINVOICE',
  'ใบกำกับภาษี',
  'RECEIPT',
  'ใบเสร็จรับเงิน',
  'INVOICE-TAXINVOICE',
  'ใบแจ้งหนี้/ใบกำกับภาษี',
  'DELIVERYORDER-TAXINVOICE',
  'ใบส่งของ/ใบกำกับภาษี',
  'DEBIT-NOTE',
  'ใบเพิ่มหนี้',
  'CREDIT-NOTE',
  'ใบลดหนี้',
  'QUOTATION',
  'ใบเสนอราคา',
  'INVOICE',
  'ใบแจ้งหนี้',
  'BILLING-NOTE',
  'ใบวางบิล',
  'DELIVERYORDER',
  'ใบส่งของ',
];

const columnLimit = {
  number: { type: 'string', maxLength: 35 },
  reference: { type: 'string', maxLength: 35 },
  itemsNumber: { type: 'string', maxLength: 35 },
  itemsDescription: { type: 'string', maxLength: 256 },
  itemsQuantity: { type: 'number', max: 9999999999.99 },
  itemsPrice: { type: 'number', max: 9999999999.99 },
  itemsDiscount: { type: 'number', max: 9999999999.99 },
  discount: { type: 'number', max: 9999999999.99 },
  customerName: { type: 'string', maxLength: 140 },
  customerAddressLineOne: { type: 'string', maxLength: 256 },
  customerAddressLineTwo: { type: 'string', maxLength: 256 },
  customerPostcode: { type: 'string', maxLength: 16 },
  customerTaxId: { type: 'string', maxLength: 35 },
  customerPhone: { type: 'string', maxLength: 35 },
  customerEmail: { type: 'string', maxLength: 256 },
  note: { type: 'string', maxLength: 256 },
  createdBy: { type: 'string', maxLength: 140 },
  receivedBy: { type: 'string', maxLength: 140 },
  referNumber: { type: 'string', maxLength: 35 },
  referAmountTotal: { type: 'number', max: 9999999999.99 },
  referSpecificReason: { type: 'string', maxLength: 256 },
  errTh: function (key, value) {
    if (this[key].type === 'string') if (value.length > this[key].maxLength) return key + ' ต้องมีความยาวไม่เกิน ' + this[key].maxLength;
    if (this[key].type === 'number') if (value > this[key].max) return key + ' ต้องระบุค่าไม่เกิน ' + this[key].max;
    return '';
  },
  errEn: function (key, value) {
    if (this[key].type === 'string')
      if (value.length > this[key].maxLength) return key + ' must not contain more than ' + this[key].maxLength + ' characters';
    if (this[key].type === 'number') if (value > this[key].max) return key + ' must not more than ' + this[key].max;
    return '';
  },
};

function validateColumnLimit(row) {
  const errors = [];
  Object.keys(row).forEach((key) => {
    if (columnLimit[key]) {
      const result = columnLimit.errTh(key, row[key]);
      if (result) errors.push(result);
    }
  });

  return errors;
}

function validateColumnLimitEn(row) {
  const errors = [];
  Object.keys(row).forEach((key) => {
    if (columnLimit[key]) {
      const result = columnLimit.errEn(key, row[key]);
      if (result) errors.push(result);
    }
  });

  return errors;
}

export function validateField(row) {
  // console.log('row', row);
  let error = [];

  if (!row.documentType) {
    error.push('documentType ห้ามเว้นว่าง');
  } else {
    if (!documentTypeList.includes(row.documentType)) error.push('documentType ไม่ถูกต้อง');
  }

  if (!row.number) error.push('number ห้ามเว้นว่าง');

  if (row.number?.includes(",")) error.push('number ห้ามมี comma ( , )');

  if (!row.dateBE) {
    error.push('dateBE ห้ามเว้นว่าง');
  } else {
    if (!isNaN(row.dateBE)) {
      if (String(row.dateBE).trim().length === 8) {
        if (!checkNewDateFormat(row.dateBE))
          error.push('dateBE ไม่ถูกต้อง => รูปแบบวันที่ต้องเป็น yyyymmdd (ปี เดือน วัน) เท่านั้น ตัวอย่าง: 20230224');
      } else {
        if (isValidDate(checkExcelDateFormat(row.dateBE))) {
          let _year = checkExcelDateFormat(row.dateBE).getFullYear();

          let _yearBE = 0;
          let _yearCE = 0;
          if (_year < 2100 && _year + 543 > 2563) {
            _yearBE = _year + 543;
            _yearCE = _year;
          } else {
            _yearBE = _year;
            _yearCE = _year - 543;
          }

          if (_yearBE <= 2563 || _yearCE >= 2050)
            error.push('dateBE ไม่ถูกต้อง => รูปแบบวันที่ต้องเป็น วัน/เดือน/ปี พ.ศ. เท่านั้น ตัวอย่าง: 02/03/2564');
        } else {
          error.push('dateBE ไม่ถูกต้อง => รูปแบบวันที่ต้องเป็น วัน/เดือน/ปี พ.ศ. เท่านั้น ตัวอย่าง: 02/03/2564');
        }
      }
    } else {
      if (!checkDateFormat(row.dateBE)) error.push('dateBE ไม่ถูกต้อง => รูปแบบวันที่ต้องเป็น วัน/เดือน/ปี พ.ศ. เท่านั้น ตัวอย่าง: 02/03/2564');
      if (checkYear(row.dateBE)) error.push('dateBE ไม่ถูกต้อง => กรุณาระบุปีเป็นรูปแบบ พ.ศ.');
    }
  }

  if (
    row.documentType === 'INVOICE-TAXINVOICE' ||
    row.documentType === 'ใบแจ้งหนี้/ใบกำกับภาษี' ||
    row.documentType === 'DELIVERYORDER-TAXINVOICE' ||
    row.documentType === 'ใบส่งของ/ใบกำกับภาษี' ||
    row.documentType === 'INVOICE' ||
    row.documentType === 'ใบแจ้งหนี้' ||
    row.documentType === 'BILLING-NOTE' ||
    row.documentType === 'ใบวางบิล' ||
    row.documentType === 'DELIVERYORDER' ||
    row.documentType === 'ใบส่งของ'
  ) {
    if (row.dueDateBE) {
      if (!isNaN(row.dueDateBE)) {
        if (String(row.dueDateBE).trim().length === 8) {
          if (!checkNewDateFormat(row.dueDateBE))
            error.push('dueDateBE ไม่ถูกต้อง => รูปแบบวันที่ต้องเป็น yyyymmdd (ปี เดือน วัน) เท่านั้น ตัวอย่าง: 20230224');
        } else {
          if (isValidDate(checkExcelDateFormat(row.dueDateBE))) {
            let _year = checkExcelDateFormat(row.dueDateBE).getFullYear();

            let _yearBE = 0;
            let _yearCE = 0;
            if (_year < 2100 && _year + 543 > 2563) {
              _yearBE = _year + 543;
              _yearCE = _year;
            } else {
              _yearBE = _year;
              _yearCE = _year - 543;
            }

            if (_yearBE <= 2563 || _yearCE >= 2050)
              error.push('dueDateBE ไม่ถูกต้อง => รูปแบบวันที่ต้องเป็น วัน/เดือน/ปี พ.ศ. เท่านั้น ตัวอย่าง: 02/03/2564');
          } else {
            error.push('dueDateBE ไม่ถูกต้อง => รูปแบบวันที่ต้องเป็น วัน/เดือน/ปี พ.ศ. เท่านั้น ตัวอย่าง: 02/03/2564');
          }
        }
      } else {
        if (!checkDateFormat(row.dueDateBE))
          error.push('dueDateBE ไม่ถูกต้อง => รูปแบบวันที่ต้องเป็น วัน/เดือน/ปี พ.ศ. เท่านั้น ตัวอย่าง: 02/03/2564');
        if (checkYear(row.dueDateBE)) error.push('dueDateBE ไม่ถูกต้อง => กรุณาระบุปีเป็นรูปแบบ พ.ศ.');
      }
    }
  }

  // items
  let items = row.items;
  for (let i = 0; i < items.length; i++) {
    let item = items[i];

    if (!item.itemsDescription) error.push(`ไม่พบ itemsDescription ในรายการสินค้า ลำดับที่ ${i + 1}`);
    if (isNaN(item.itemsQuantity)) error.push(`itemsQuantity ในรายการสินค้า ลำดับที่ ${i + 1} ไม่ถูกต้อง => กรุณาระบุเป็นตัวเลขเท่านั้น`);

    if (!isNaN(item.itemsQuantity)) {
      if (Number(item.itemsQuantity) < 0) error.push(`itemsQuantity ในรายการสินค้า ลำดับที่ ${i + 1} ไม่ถูกต้อง => จำนวนของสินค้าห้ามน้อยกว่า 0`);
    }

    if (item.itemsUnitName) {
      if (getUnitByNameWithoutLanguage(item.itemsUnitName).key === undefined) error.push(`itemsUnitName ในรายการสินค้า ลำดับที่ ${i + 1} ไม่ถูกต้อง`);
    }

    if (isNaN(item.itemsPrice)) error.push(`itemsPrice ในรายการสินค้า ลำดับที่ ${i + 1} ไม่ถูกต้อง => กรุณาระบุเป็นตัวเลขเท่านั้น`);
    if (isNaN(item.itemsDiscount)) error.push(`itemsDiscount ในรายการสินค้า ลำดับที่ ${i + 1} ไม่ถูกต้อง => กรุณาระบุเป็นตัวเลขเท่านั้น`);

    if (!isNaN(item.itemsDiscount)) {
      if (Number(item.itemsDiscount) < 0) error.push(`itemsDiscount ในรายการสินค้า ลำดับที่ ${i + 1} ไม่ถูกต้อง => ส่วนลดของสินค้าห้ามน้อยกว่า 0`);
      if (Number(item.itemsDiscount) > Number(item.itemsPrice)) error.push(`itemsDiscount ในรายการสินค้า ลำดับที่ ${i + 1} ไม่ถูกต้อง => ไม่สามารถระบุส่วนลดมากกว่าราคาสินค้าได้`);
    }
  }

  if (items.some((item) => Number(item.itemsPrice) < 0)) error.push(`itemsPrice ไม่ถูกต้อง => ห้ามระบุค่าน้อยกว่า 0`);

  const totalAmount = items.reduce((accumulator, item) => accumulator + (item.itemsPrice - item.itemsDiscount) * item.itemsQuantity, 0);

  if (!isNaN(row.discount)) {
    if (Number(row.discount) < 0) error.push(`discount ไม่ถูกต้อง => ส่วนลดห้ามน้อยกว่า 0`);
    if (totalAmount - Number(row.discount) < 0) error.push(`itemsPrice ไม่ถูกต้อง => ยอดรวมห้ามน้อยกว่า 0`);
  } else {
    if (totalAmount < 0) error.push(`itemsPrice ไม่ถูกต้อง => ยอดรวมห้ามน้อยกว่า 0`);
  }

  let _discountInvalid = false;
  let vatTypeArray = [];

  items.forEach((item) => {
    if (!vatTypeArray.includes(item.itemsPercentVat)) {
      vatTypeArray.push(item.itemsPercentVat);
    }
    if (vatTypeArray.length > 1) {
      _discountInvalid = true;
    }
  });

  if (_discountInvalid) {
    if (row.discount !== null && row.discount !== undefined) {
      if (Number(row.discount) !== 0) error.push('ห้ามระบุ discount เนื่องจาก รายการสินค้ามีอัตราภาษีไม่เท่ากัน');
    }
  }

  // customer
  if (row.documentType !== 'RECEIPT-TAXINVOICE-ABB' && row.documentType !== 'ใบเสร็จรับเงิน/ใบกำกับภาษีอย่างย่อ') {
    if (!row.customerName) error.push('customerName ห้ามเว้นว่าง');
    if (!row.customerAddressLineOne) error.push('customerAddressLineOne ห้ามเว้นว่าง');

    if (row.customerCountryName && row.customerCountryName !== 'ไทย' && row.customerCountryName !== 'Thailand') {
      if (getCountryCodeWithoutLanguage(row.customerCountryName).key === undefined) error.push('customerCountryName ไม่ถูกต้อง');
      if (!row.customerPostcode) {
        error.push('customerPostcode ห้ามเว้นว่าง');
      } else if (String(row.customerPostcode).length < 5) {
        error.push(`customerPostcode ไม่ถูกต้อง => รหัสไปรษณีย์ต้องมีขั้นต่ำ 5 ตัว`);
      }
    } else if (!row.customerCountryName || row.customerCountryName === 'ไทย' || row.customerCountryName === 'Thailand') {
      if (!row.customerPostcode) error.push('customerPostcode ห้ามเว้นว่าง');
      if (String(row.customerPostcode).length !== 5 || isNaN(row.customerPostcode))
        error.push(`customerPostcode ไม่ถูกต้อง => รหัสไปรษณีย์ต้องประกอบด้วยตัวเลข 5 ตัวเท่านั้น`);
    }

    if (!row.customerTaxNumberType) {
      error.push('customerTaxNumberType ห้ามเว้นว่าง');
    } else if (!isNaN(row.customerTaxNumberType)) {
      error.push('customerTaxNumberType ไม่ถูกต้อง => ค่าที่ถูกต้องมีแค่ TXID, NIDN, CCPT, OTHR เท่านั้น');
    } else {
      if (!['TXID', 'NIDN', 'CCPT', 'OTHR'].includes(row.customerTaxNumberType.toUpperCase())) {
        error.push('customerTaxNumberType ไม่ถูกต้อง => ค่าที่ถูกต้องมีแค่ TXID, NIDN, CCPT, OTHR เท่านั้น');
      }

      if (row.customerTaxNumberType?.toUpperCase() === 'TXID') {
        if (!row.customerTaxId) error.push('customerTaxId ห้ามเว้นว่าง');
        if (isNaN(row.customerTaxId)) error.push('customerTaxId ไม่ถูกต้อง => กรุณาระบุเป็นตัวเลขเท่านั้น');
        if (String(row.customerTaxId).trim().length !== 13)
          error.push(`customerTaxId ไม่ถูกต้อง => เลขประจำตัวผู้เสียภาษีอาการสำหรับนิติบุคคล 13 หลักเท่านั้น`);

        if (row.customerBranchNumber) {
          if (isNaN(row.customerBranchNumber)) {
            error.push(`customerBranchNumber ไม่ถูกต้อง => กรุณาใส่เป็นตัวเลขเท่านั้น`);
          } else {
            if (String(row.customerBranchNumber).trim().length > 5) {
              error.push(`customerBranchNumber ไม่ถูกต้อง => กรุณาใส่เป็นตัวเลข 5 ตัว เช่น ถ้าเป็นสำนักงานใหญ่จะเป็น 00000`);
            }
          }
        }
      } else if (row.customerTaxNumberType?.toUpperCase() === 'NIDN') {
        if (!row.customerTaxId) error.push('customerTaxId ห้ามเว้นว่าง');
        if (isNaN(row.customerTaxId)) error.push('customerTaxId ไม่ถูกต้อง => กรุณาระบุเป็นตัวเลขเท่านั้น');
        if (String(row.customerTaxId).trim().length !== 13)
          error.push('customerTaxId ไม่ถูกต้อง => เลขประจำตัวประชาชน 13 หลัก (สำหรับบุคคลธรรมดา) เท่านั้น');
      } else if (row.customerTaxNumberType?.toUpperCase() === 'CCPT') {
        if (!row.customerTaxId)
          error.push('customerTaxId ห้ามเว้นว่าง => กรุณาระบุเลขหนังสือเดินทาง (Passport) หากไม่มีให้ระบุ customerTaxNumberType เป็น OTHR');
      }
    }

    if (row.customerPhone) {
      if (isNaN(row.customerPhone) || /(^0[1-9][0-9]{1,13}$|^$)/g.test(row.customerPhone) !== true) {
        error.push('customerPhone ไม่ถูกต้อง');
      }
    }

    // reIssue
    if (
      row.documentType === 'RECEIPT-TAXINVOICE' ||
      row.documentType === 'ใบเสร็จรับเงิน/ใบกำกับภาษี' ||
      row.documentType === 'INVOICE-TAXINVOICE' ||
      row.documentType === 'ใบแจ้งหนี้/ใบกำกับภาษี' ||
      row.documentType === 'DELIVERYORDER-TAXINVOICE' ||
      row.documentType === 'ใบส่งของ/ใบกำกับภาษี' ||
      row.documentType === 'TAXINVOICE' ||
      row.documentType === 'ใบกำกับภาษี' ||
      row.documentType === 'RECEIPT-TAXINVOICE-ABB' ||
      row.documentType === 'ใบเสร็จรับเงิน/ใบกำกับภาษีอย่างย่อ' ||
      row.documentType === 'RECEIPT' ||
      row.documentType === 'ใบเสร็จรับเงิน'
    ) {
      if (row.reIssue === true || row.reIssue === 'TRUE' || row.reIssue === 'true') {
        if (!row.referNumber) error.push('referNumber ห้ามเว้นว่าง');
        if (row.referNumber) {
          if (row.referNumber === row.number) error.push('referNumber ห้ามใช้เลขเดียวกันกับ number');
        }
        if (!row.referDateBE) {
          error.push('referDateBE ห้ามเว้นว่าง');
        } else {
          if (!isNaN(row.referDateBE)) {
            if (String(row.referDateBE).trim().length === 8) {
              if (!checkNewDateFormat(row.referDateBE))
                error.push('referDateBE ไม่ถูกต้อง => รูปแบบวันที่ต้องเป็น yyyymmdd (ปี เดือน วัน) เท่านั้น ตัวอย่าง: 20230224');
            } else {
              if (isValidDate(checkExcelDateFormat(row.referDateBE))) {
                let _year = checkExcelDateFormat(row.referDateBE).getFullYear();

                let _yearBE = 0;
                let _yearCE = 0;
                if (_year < 2100 && _year + 543 > 2563) {
                  _yearBE = _year + 543;
                  _yearCE = _year;
                } else {
                  _yearBE = _year;
                  _yearCE = _year - 543;
                }

                if (_yearBE <= 2563 || _yearCE >= 2050)
                  error.push('referDateBE ไม่ถูกต้อง => รูปแบบวันที่ต้องเป็น วัน/เดือน/ปี พ.ศ. เท่านั้น ตัวอย่าง: 02/03/2564');
              } else {
                error.push('referDateBE ไม่ถูกต้อง => รูปแบบวันที่ต้องเป็น วัน/เดือน/ปี พ.ศ. เท่านั้น ตัวอย่าง: 02/03/2564');
              }
            }
          } else {
            if (!checkDateFormat(row.referDateBE))
              error.push('referDateBE ไม่ถูกต้อง => รูปแบบวันที่ต้องเป็น วัน/เดือน/ปี พ.ศ. เท่านั้น ตัวอย่าง: 02/03/2564');
            if (checkYear(row.referDateBE)) error.push('referDateBE ไม่ถูกต้อง => กรุณาระบุปีเป็นรูปแบบ พ.ศ.');
          }
        }

        if (!row.referReasonCode) {
          error.push('referReasonCode ห้ามเว้นว่าง');
        } else {
          if (
            (row.documentType === 'RECEIPT' || row.documentType === 'ใบเสร็จรับเงิน') &&
            !['RCTC01', 'RCTC02', 'RCTC03', 'RCTC04', 'RCTC99'].includes(row.referReasonCode)
          )
            error.push('referReasonCode ไม่ถูกต้อง => ให้ระบุเป็น RCTC01, RCTC02, RCTC03, RCTC04, RCTC99 เท่านั้น');

          if ((row.documentType === 'RECEIPT' || row.documentType === 'ใบเสร็จรับเงิน') && ['RCTC03', 'RCTC04'].includes(row.referReasonCode)) {
            if (!row.referSpecificReason) error.push(`referSpecificReason ห้ามเว้นว่างเมื่อระบุ referReasonCode เป็น RCTC03, RCTC04`);
            if (isNaN(row.referSpecificReason)) error.push(`referSpecificReason ไม่ถูกต้อง => กรุณาระบุเป็นตัวเลขเท่านั้น`);
          }

          if (
            !(row.documentType === 'RECEIPT' || row.documentType === 'ใบเสร็จรับเงิน') &&
            !['TIVC01', 'TIVC02', 'TIVC99'].includes(row.referReasonCode)
          )
            error.push(`referReasonCode ไม่ถูกต้อง => ให้ระบุเป็น 'TIVC01', 'TIVC02', 'TIVC99' เท่านั้น`);

          if (row.referReasonCode.includes('99')) {
            if (!row.referSpecificReason) error.push(`referSpecificReason ห้ามเว้นว่างเมื่อระบุ referReasonCode เป็น ${row.referReasonCode}`);
          }
        }
      }
    } else if (
      row.documentType === 'DEBIT-NOTE' ||
      row.documentType === 'ใบเพิ่มหนี้' ||
      row.documentType === 'CREDIT-NOTE' ||
      row.documentType === 'ใบลดหนี้'
    ) {
      if (!row.referTypeCode) {
        error.push(`referTypeCode ห้ามเว้นว่าง`);
      } else {
        if (!['388', 'T02', 'T03', 'T04'].includes(row.referTypeCode))
          error.push(`referTypeCode ไม่ถูกต้อง => ให้ระบุเป็น '388', 'T02', 'T03', 'T04' เท่านั้น`);
      }

      if (!row.referNumber) error.push('referNumber ห้ามเว้นว่าง');
      if (row.referNumber) {
        if (row.referNumber === row.number) error.push('referNumber ห้ามใช้เลขเดียวกันกับ number');
      }
      if (!row.referDateBE) {
        error.push('referDateBE ห้ามเว้นว่าง');
      } else {
        if (!isNaN(row.referDateBE)) {
          if (String(row.referDateBE).trim().length === 8) {
            if (!checkNewDateFormat(row.referDateBE))
              error.push('referDateBE ไม่ถูกต้อง => รูปแบบวันที่ต้องเป็น yyyymmdd (ปี เดือน วัน) เท่านั้น ตัวอย่าง: 20230224');
          } else {
            if (isValidDate(checkExcelDateFormat(row.referDateBE))) {
              let _year = checkExcelDateFormat(row.referDateBE).getFullYear();

              let _yearBE = 0;
              let _yearCE = 0;
              if (_year < 2100 && _year + 543 > 2563) {
                _yearBE = _year + 543;
                _yearCE = _year;
              } else {
                _yearBE = _year;
                _yearCE = _year - 543;
              }

              if (_yearBE <= 2563 || _yearCE >= 2050)
                error.push('referDateBE ไม่ถูกต้อง => รูปแบบวันที่ต้องเป็น วัน/เดือน/ปี พ.ศ. เท่านั้น ตัวอย่าง: 02/03/2564');
            } else {
              error.push('referDateBE ไม่ถูกต้อง => รูปแบบวันที่ต้องเป็น วัน/เดือน/ปี พ.ศ. เท่านั้น ตัวอย่าง: 02/03/2564');
            }
          }
        } else {
          if (!checkDateFormat(row.referDateBE))
            error.push('referDateBE ไม่ถูกต้อง => รูปแบบวันที่ต้องเป็น วัน/เดือน/ปี พ.ศ. เท่านั้น ตัวอย่าง: 02/03/2564');
          if (checkYear(row.referDateBE)) error.push('referDateBE ไม่ถูกต้อง => กรุณาระบุปีเป็นรูปแบบ พ.ศ.');
        }
      }

      if (isNaN(row.referAmountTotal)) error.push(`referAmountTotal ไม่ถูกต้อง => กรุณาระบุเป็นตัวเลขเท่านั้น`);
      if (row.referAmountTotal < 0) error.push(`referAmountTotal ไม่ถูกต้อง => ห้ามระบุน้อยกว่า 0`);

      if (row.documentType === 'DEBIT-NOTE' || row.documentType === 'ใบเพิ่มหนี้') {
        if (!row.referReasonCode) {
          error.push('referReasonCode ห้ามเว้นว่าง');
        } else {
          if (!['DBNG01', 'DBNG02', 'DBNG99', 'DBNS01', 'DBNS02', 'DBNS99'].includes(row.referReasonCode))
            error.push(`referReasonCode ไม่ถูกต้อง => ให้ระบุเป็น 'DBNG01', 'DBNG02', 'DBNS02', ''DBNS01, 'DBNG99', 'DBNS99' เท่านั้น`);

          if (row.referReasonCode.includes('DBNG99') || row.referReasonCode.includes('DBNS99')) {
            if (!row.referSpecificReason) error.push(`referSpecificReason ห้ามเว้นว่างเมื่อระบุ referReasonCode เป็น ${row.referReasonCode}`);
          }
        }
      } else if (row.documentType === 'CREDIT-NOTE' || row.documentType === 'ใบลดหนี้') {
        if (!row.referReasonCode) {
          error.push('referReasonCode ห้ามเว้นว่าง');
        } else {
          if (
            !['CDNG01', 'CDNG02', 'CDNG03', 'CDNG04', 'CDNG05', 'CDNG99', 'CDNS01', 'CDNS02', 'CDNS03', 'CDNS04', 'CDNS99'].includes(
              row.referReasonCode
            )
          )
            error.push(
              `referReasonCode ไม่ถูกต้อง => ให้ระบุเป็น 'CDNG01', 'CDNG02', 
                'CDNG03', 'CDNG04', 'CDNG05', 'CDNG99',
                'CDNS01', 'CDNS02', 'CDNS03', 'CDNS04', 
                'CDNS99' เท่านั้น`
            );

          if (row.referReasonCode.includes('CDNG99') || row.referReasonCode.includes('CDNS99')) {
            if (!row.referSpecificReason) error.push(`referSpecificReason ห้ามเว้นว่างเมื่อระบุ referReasonCode เป็น ${row.referReasonCode}`);
          }
        }
      }
    }
  }

  const errorsLength = validateColumnLimit(row);

  return [...error, ...errorsLength];
}

export function validateFieldEN(row) {
  let error = [];

  if (!row.documentType) {
    error.push('There is no documentType');
  } else {
    if (!documentTypeList.includes(row.documentType)) error.push('Invalid documentType');
  }

  if (!row.number) error.push('There is no number');

  if (row.number?.includes(",")) error.push('number cannot contain comma ( , )');

  if (!row.dateBE) {
    error.push('There is no dateBE');
  } else {
    if (!isNaN(row.dateBE)) {
      if (String(row.dateBE).trim().length === 8) {
        if (!checkNewDateFormat(row.dateBE)) error.push('Invalid dateBE => The date format must be yyyymmdd only. ex: 20230224');
      } else {
        if (isValidDate(checkExcelDateFormat(row.dateBE))) {
          let _year = checkExcelDateFormat(row.dateBE).getFullYear();

          let _yearBE = 0;
          let _yearCE = 0;
          if (_year < 2100 && _year + 543 > 2563) {
            _yearBE = _year + 543;
            _yearCE = _year;
          } else {
            _yearBE = _year;
            _yearCE = _year - 543;
          }

          if (_yearBE <= 2563 || _yearCE >= 2050) error.push('Invalid dateBE => The date format must be dd/mm/yyyy only. ex: 02/03/2564');
        } else {
          error.push('Invalid dateBE => The date format must be dd/mm/yyyy only. ex: 02/03/2564');
        }
      }
    } else {
      if (!checkDateFormat(row.dateBE)) error.push('Invalid dateBE => The date format must be dd/mm/yyyy only. ex: 02/03/2564');
      if (checkYear(row.dateBE)) error.push('Invalid dateBE => Year must be in Buddhist era');
    }
  }

  if (
    row.documentType === 'INVOICE-TAXINVOICE' ||
    row.documentType === 'ใบแจ้งหนี้/ใบกำกับภาษี' ||
    row.documentType === 'DELIVERYORDER-TAXINVOICE' ||
    row.documentType === 'ใบส่งของ/ใบกำกับภาษี' ||
    row.documentType === 'INVOICE' ||
    row.documentType === 'ใบแจ้งหนี้' ||
    row.documentType === 'BILLING-NOTE' ||
    row.documentType === 'ใบวางบิล' ||
    row.documentType === 'DELIVERYORDER' ||
    row.documentType === 'ใบส่งของ'
  ) {
    if (row.dueDateBE) {
      if (!isNaN(row.dueDateBE)) {
        if (String(row.dueDateBE).trim().length === 8) {
          if (!checkNewDateFormat(row.dueDateBE)) error.push('Invalid dueDateBE => The date format must be yyyymmdd only. ex: 20230224');
        } else {
          if (isValidDate(checkExcelDateFormat(row.dueDateBE))) {
            let _year = checkExcelDateFormat(row.dueDateBE).getFullYear();

            let _yearBE = 0;
            let _yearCE = 0;
            if (_year < 2100 && _year + 543 > 2563) {
              _yearBE = _year + 543;
              _yearCE = _year;
            } else {
              _yearBE = _year;
              _yearCE = _year - 543;
            }

            if (_yearBE <= 2563 || _yearCE >= 2050) error.push('Invalid dueDateBE => The date format must be dd/mm/yyyy only. ex: 02/03/2564');
          } else {
            error.push('Invalid dueDateBE => The date format must be dd/mm/yyyy only. ex: 02/03/2564');
          }
        }
      } else {
        if (!checkDateFormat(row.dueDateBE)) error.push('Invalid dueDateBE => The date format must be dd/mm/yyyy only. ex: 02/03/2564');
        if (checkYear(row.dueDateBE)) error.push('Invalid dueDateBE => Year must be in Buddhist era');
      }
    }
  }

  // items
  let items = row.items;
  for (let i = 0; i < items.length; i++) {
    let item = items[i];

    if (!item.itemsDescription) error.push(`There is no itemsDescription in itemsNumber ${i + 1}`);
    if (isNaN(item.itemsQuantity)) error.push(`Invalid itemsQuantity in itemsNumber ${i + 1} => The value must be number only`);

    if (!isNaN(item.itemsQuantity)) {
      if (Number(item.itemsQuantity) < 0) error.push(`Invalid itemsQuantity in itemsNumber ${i + 1} => The value must be greater than 0`);
    }

    if (item.itemsUnitName) {
      if (getUnitByNameWithoutLanguage(item.itemsUnitName).key === undefined) error.push(`Invalid itemsUnitName in itemsNumber ${i + 1}`);
    }

    if (isNaN(item.itemsPrice)) error.push(`Invalid itemsPrice in itemsNumber ${i + 1} => The value must be number only`);
    if (isNaN(item.itemsDiscount)) error.push(`Invalid itemsDiscount in itemsNumber ${i + 1} => The value must be number only`);
  
    if (!isNaN(item.itemsDiscount)) {
      if (Number(item.itemsDiscount) < 0) error.push(`Invalid itemsDiscount in itemsNumber ${i + 1} => The value must be greater than 0`);
      if (Number(item.itemsDiscount) > Number(item.itemsPrice)) error.push(`Invalid itemsDiscount in itemsNumber ${i + 1} => The value must not be greater than itemsPrice`);
    }
  }

  if (items.some((item) => Number(item.itemsPrice) < 0)) error.push(`Invalid itemsPrice => The value must be greater than 0`);

  const totalAmount = items.reduce((accumulator, item) => accumulator + (item.itemsPrice - item.itemsDiscount) * item.itemsQuantity, 0);

  if (!isNaN(row.discount)) {
    if (Number(row.discount) < 0) error.push(`Invalid discount => Discount must be greater than 0`);
    if (totalAmount - Number(row.discount) < 0) error.push(`Invalid itemsPrice => Total must be greater than 0`);
  } else {
    if (totalAmount < 0) error.push(`Invalid itemsPrice => Total must be greater than 0`);
  }

  let _discountInvalid = false;
  let vatTypeArray = [];

  items.forEach((item) => {
    if (!vatTypeArray.includes(item.itemsPercentVat)) {
      vatTypeArray.push(item.itemsPercentVat);
    }
    if (vatTypeArray.length > 1) {
      _discountInvalid = true;
    }
  });

  if (_discountInvalid) {
    if (row.discount !== null && row.discount !== undefined) {
      if (Number(row.discount) !== 0) error.push('Cannot enter value of discount if there are more than 1 type of itemsPercentVat');
    }
  }

  // customer
  if (row.documentType !== 'RECEIPT-TAXINVOICE-ABB' && row.documentType !== 'ใบเสร็จรับเงิน/ใบกำกับภาษีอย่างย่อ') {
    if (!row.customerName) error.push('There is no customerName');
    if (!row.customerAddressLineOne) error.push('There is no customerAddressLineOne');

    if (row.customerCountryName && row.customerCountryName !== 'ไทย' && row.customerCountryName !== 'Thailand') {
      if (getCountryCodeWithoutLanguage(row.customerCountryName).key === undefined) error.push('Invalid customerCountryName');
      if (!row.customerPostcode) {
        error.push('There is no customerPostcode');
      } else if (String(row.customerPostcode).length < 5) {
        error.push(`Invalid customerPostcode => Postcode must contain minimum 5 characters`);
      }
    } else if (!row.customerCountryName || row.customerCountryName === 'ไทย' || row.customerCountryName === 'Thailand') {
      if (!row.customerPostcode) error.push('There is no customerPostcode');
      if (String(row.customerPostcode).length !== 5 || isNaN(row.customerPostcode))
        error.push(`Invalid customerPostcode => Postcode must contain 5 digit numbers`);
    }

    if (!row.customerTaxNumberType) {
      error.push('There is no customerTaxNumberType');
    } else if (!isNaN(row.customerTaxNumberType)) {
      error.push('Invalid customerTaxNumberType => The available values are TXID, NIDN, CCPT, OTHR');
    } else {
      if (!['TXID', 'NIDN', 'CCPT', 'OTHR'].includes(row.customerTaxNumberType.toUpperCase())) {
        error.push('Invalid customerTaxNumberType => The available values are TXID, NIDN, CCPT, OTHR');
      }

      if (row.customerTaxNumberType?.toUpperCase() === 'TXID') {
        if (!row.customerTaxId) error.push('There is no customerTaxId');
        if (isNaN(row.customerTaxId)) error.push('Invalid customerTaxId => The value must be number only');
        if (String(row.customerTaxId).trim().length !== 13) error.push(`Invalid customerTaxId => Tax ID must contain 13 digit numbers`);

        if (isNaN(row.customerBranchNumber)) {
          error.push(`Invalid customerBranchNumber => The value must be number only`);
        } else {
          if (String(row.customerBranchNumber).trim().length > 5) {
            error.push(`Invalid customerBranchNumber => Branch number must contain 5 digit numbers ex: 00001`);
          }
        }
      } else if (row.customerTaxNumberType?.toUpperCase() === 'NIDN') {
        if (!row.customerTaxId) error.push('There is no customerTaxId');
        if (isNaN(row.customerTaxId)) error.push('Invalid customerTaxId => The value must be number only');
        if (String(row.customerTaxId).trim().length !== 13) error.push('Invalid customerTaxId => ID Number must contain 13 digit numbers');
      } else if (row.customerTaxNumberType?.toUpperCase() === 'CCPT') {
        if (!row.customerTaxId) error.push('There is no customerTaxId => Please enter Passport Number');
      }
    }

    if (row.customerPhone) {
      if (isNaN(row.customerPhone) || /(^0[1-9][0-9]{1,13}$|^$)/g.test(row.customerPhone) !== true) {
        error.push('Invalid customerPhone');
      }
    }

    // reIssue
    if (
      row.documentType === 'RECEIPT-TAXINVOICE' ||
      row.documentType === 'ใบเสร็จรับเงิน/ใบกำกับภาษี' ||
      row.documentType === 'INVOICE-TAXINVOICE' ||
      row.documentType === 'ใบแจ้งหนี้/ใบกำกับภาษี' ||
      row.documentType === 'DELIVERYORDER-TAXINVOICE' ||
      row.documentType === 'ใบส่งของ/ใบกำกับภาษี' ||
      row.documentType === 'TAXINVOICE' ||
      row.documentType === 'ใบกำกับภาษี' ||
      row.documentType === 'RECEIPT-TAXINVOICE-ABB' ||
      row.documentType === 'ใบเสร็จรับเงิน/ใบกำกับภาษีอย่างย่อ' ||
      row.documentType === 'RECEIPT' ||
      row.documentType === 'ใบเสร็จรับเงิน'
    ) {
      if (row.reIssue === true || row.reIssue === 'TRUE' || row.reIssue === 'true') {
        if (!row.referNumber) error.push('There is no referNumber');
        if (row.referNumber) {
          if (row.referNumber === row.number) error.push('referNumber cannot use the same value as number');
        }
        if (!row.referDateBE) {
          error.push('There is no referDateBE');
        } else {
          if (!isNaN(row.referDateBE)) {
            if (String(row.referDateBE).trim().length === 8) {
              if (!checkNewDateFormat(row.referDateBE)) error.push('Invalid referDateBE => The date format must be yyyymmdd only. ex: 20230224');
            } else {
              if (isValidDate(checkExcelDateFormat(row.referDateBE))) {
                let _year = checkExcelDateFormat(row.referDateBE).getFullYear();

                let _yearBE = 0;
                let _yearCE = 0;
                if (_year < 2100 && _year + 543 > 2563) {
                  _yearBE = _year + 543;
                  _yearCE = _year;
                } else {
                  _yearBE = _year;
                  _yearCE = _year - 543;
                }

                if (_yearBE <= 2563 || _yearCE >= 2050) error.push('Invalid referDateBE => The date format must be dd/mm/yyyy only. ex: 02/03/2564');
              } else {
                error.push('Invalid referDateBE => The date format must be dd/mm/yyyy only. ex: 02/03/2564');
              }
            }
          } else {
            if (!checkDateFormat(row.referDateBE)) error.push('Invalid referDateBE => The date format must be dd/mm/yyyy only. ex: 02/03/2564');
            if (checkYear(row.referDateBE)) error.push('Invalid referDateBE => Year must be in Buddhist era');
          }
        }

        if (!row.referReasonCode) {
          error.push('There is no referReasonCode');
        } else {
          if (
            (row.documentType === 'RECEIPT' || row.documentType === 'ใบเสร็จรับเงิน') &&
            !['RCTC01', 'RCTC02', 'RCTC03', 'RCTC04', 'RCTC99'].includes(row.referReasonCode)
          )
            error.push('Invalid referReasonCode => The value can be only RCTC01, RCTC02, RCTC03, RCTC04, RCTC99');

          if ((row.documentType === 'RECEIPT' || row.documentType === 'ใบเสร็จรับเงิน') && ['RCTC03', 'RCTC04'].includes(row.referReasonCode)) {
            if (!row.referSpecificReason) error.push(`referSpecificReason must not be empty if referReasonCode is RCTC03, RCTC04`);
            if (isNaN(row.referSpecificReason)) error.push(`Invalid referSpecificReason => The value must be number only`);
          }

          if (
            !(row.documentType === 'RECEIPT' || row.documentType === 'ใบเสร็จรับเงิน') &&
            !['TIVC01', 'TIVC02', 'TIVC99'].includes(row.referReasonCode)
          )
            error.push(`Invalid referReasonCode => The value can be only 'TIVC01', 'TIVC02', 'TIVC99'`);

          if (row.referReasonCode.includes('99')) {
            if (!row.referSpecificReason) error.push(`referSpecificReason must not be empty if referReasonCode is ${row.referReasonCode}`);
          }
        }
      }
    } else if (
      row.documentType === 'DEBIT-NOTE' ||
      row.documentType === 'ใบเพิ่มหนี้' ||
      row.documentType === 'CREDIT-NOTE' ||
      row.documentType === 'ใบลดหนี้'
    ) {
      if (!row.referTypeCode) {
        error.push(`There is no referTypeCode`);
      } else {
        if (!['388', 'T02', 'T03', 'T04'].includes(row.referTypeCode))
          error.push(`Invalid referTypeCode => The value can be only '388', 'T02', 'T03', 'T04'`);
      }

      if (!row.referNumber) error.push('There is no referNumber');
      if (row.referNumber) {
        if (row.referNumber === row.number) error.push('referNumber cannot use the same value as number');
      }
      if (!row.referDateBE) {
        error.push('There is no referDateBE');
      } else {
        if (!isNaN(row.referDateBE)) {
          if (String(row.referDateBE).trim().length === 8) {
            if (!checkNewDateFormat(row.referDateBE)) error.push('Invalid referDateBE => The date format must be yyyymmdd only. ex: 20230224');
          } else {
            if (isValidDate(checkExcelDateFormat(row.referDateBE))) {
              let _year = checkExcelDateFormat(row.referDateBE).getFullYear();

              let _yearBE = 0;
              let _yearCE = 0;
              if (_year < 2100 && _year + 543 > 2563) {
                _yearBE = _year + 543;
                _yearCE = _year;
              } else {
                _yearBE = _year;
                _yearCE = _year - 543;
              }

              if (_yearBE <= 2563 || _yearCE >= 2050) error.push('Invalid referDateBE => The date format must be dd/mm/yyyy only. ex: 02/03/2564');
            } else {
              error.push('Invalid referDateBE => The date format must be dd/mm/yyyy only. ex: 02/03/2564');
            }
          }
        } else {
          if (!checkDateFormat(row.referDateBE)) error.push('Invalid referDateBE => The date format must be dd/mm/yyyy only. ex: 02/03/2564');
          if (checkYear(row.referDateBE)) error.push('Invalid referDateBE => Year must be in Buddhist era');
        }
      }

      if (isNaN(row.referAmountTotal)) error.push(`Invalid referAmountTotal => The value must be number only`);
      if (row.referAmountTotal < 0) error.push(`Invalid referAmountTotal => The value cannot be less than 0`);

      if (row.documentType === 'DEBIT-NOTE' || row.documentType === 'ใบเพิ่มหนี้') {
        if (!row.referReasonCode) {
          error.push('referReasonCode ห้ามเว้นว่าง');
        } else {
          if (!['DBNG01', 'DBNG02', 'DBNG99', 'DBNS01', 'DBNS02', 'DBNS99'].includes(row.referReasonCode))
            error.push(`Invalid referReasonCode => The value can be only 'DBNG01', 'DBNG02', 'DBNS02', ''DBNS01, 'DBNG99', 'DBNS99'`);

          if (row.referReasonCode.includes('DBNG99') || row.referReasonCode.includes('DBNS99')) {
            if (!row.referSpecificReason) error.push(`referSpecificReason must not be empty if referReasonCode is ${row.referReasonCode}`);
          }
        }
      } else if (row.documentType === 'CREDIT-NOTE' || row.documentType === 'ใบลดหนี้') {
        if (!row.referReasonCode) {
          error.push('referReasonCode ห้ามเว้นว่าง');
        } else {
          if (
            !['CDNG01', 'CDNG02', 'CDNG03', 'CDNG04', 'CDNG05', 'CDNG99', 'CDNS01', 'CDNS02', 'CDNS03', 'CDNS04', 'CDNS99'].includes(
              row.referReasonCode
            )
          )
            error.push(
              `Invalid referReasonCode => The value can be only 'CDNG01', 'CDNG02', 
              'CDNG03', 'CDNG04', 'CDNG05', 'CDNG99',
              'CDNS01', 'CDNS02', 'CDNS03', 'CDNS04', 
              'CDNS99'`
            );

          if (row.referReasonCode.includes('CDNG99') || row.referReasonCode.includes('CDNS99')) {
            if (!row.referSpecificReason) error.push(`referSpecificReason must not be empty if referReasonCode is ${row.referReasonCode}`);
          }
        }
      }
    }
  }

  const errorsLength = validateColumnLimitEn(row);

  return [...error, ...errorsLength];
}
