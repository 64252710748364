import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { silentRequest } from "../../../authConfig";
import { useMsal, useAccount } from "@azure/msal-react";

import paginate from "jw-paginate";

import {
    DetailsListLayoutMode,
    ShimmeredDetailsList,
    Selection,
    SelectionMode,
    Spinner,
    SpinnerSize,
    CommandBar,
    Icon,
    Stack,
    PrimaryButton,
    DefaultButton,
    SearchBox,
    TextField,
    ContextualMenu,
    CheckboxVisibility,
    Toggle,
    Dialog,
    DialogType,
    DialogFooter,
    DatePicker,
    Text,
    Dropdown,
} from "@fluentui/react";

// import { Pagination } from "@uifabric/experiments/lib/Pagination";
import { Pagination } from '@fluentui/react-experiments/lib/Pagination';
import axios from "axios";

const homeStyles = {
    border: 0,
    margin: 0,
    width: "100%",
    background: "#FAF9F8",
};

const spinnerStyles = {
    circle: {
        height: 56,
        width: 56,
        borderWidth: 4,
    },
};

const onFormatDate = (date, format) => {
    if (format === "CE") {
        return `${date.getDate()}`.padStart(2, "0") + "/" + `${date.getMonth() + 1}`.padStart(2, "0") + "/" + date.getFullYear();
    } else {
        return `${date.getDate()}`.padStart(2, "0") + "/" + `${date.getMonth() + 1}`.padStart(2, "0") + "/" + (date.getFullYear() + 543);
    }
};

const formatDate = (d) => {
    let month = "" + (d.getMonth() + 1);
    let day = "" + d.getDate();
    let year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
};

const styles = {
    table: {
      borderCollapse: 'collapse', width: '100%', 
    },
    th: {
      border: '1px solid #ddd', padding: '8px', textAlign: 'left', backgroundColor: '#f2f2f2'
    },
    td: {
      border: '1px solid #ddd', padding: '8px', textAlign: 'left'
    },
    scrollable: {
      maxHeight: '200px', overflowY: 'auto', border: '1px solid #ddd'
    }
    
  }

  
function Shopee() {
    const API_URL = process.env.REACT_APP_API_URL;

    const { instance, accounts } = useMsal();
    const account = useAccount(accounts[0] || {});

    const { t, i18n } = useTranslation();

    const [company, setCompany] = useState("");
    const [items, setItems] = useState([]);

    const percentVat = 7;
    const zeroVat = 0;
    // const nonVat = -1;

    const percentVatText = "7%";
    const zeroVatText = "0%";
    // const nonVatText = '-';

    const [loadDataComplete, setLoadDataComplete] = useState(false);
    const [getNewDocuments, setGetNewDocuments] = useState(false);

    const [pageData, setPageData] = useState({});
    const [pageSize, setPageSize] = useState(sessionStorage.getItem("shopee-pageSize") * 1 || 10);
    const [currentPage, setCurrentPage] = useState(sessionStorage.getItem("shopee-currentPage") * 1 || 1);

    //CommandBars Items
    const [orderType, setOrderType] = useState(
        (sessionStorage.getItem("shopee-orderType-key") && {
            key: sessionStorage.getItem("shopee-orderType-key"),
            text: sessionStorage.getItem("shopee-orderType-text"),
        }) || {
            key: "COMPLETED",
            text: t("shopee.COMPLETED"),
        }
    );

    const [timeRangeField, setTimeRangeField] = useState(
        (sessionStorage.getItem("shopee-timeRangeField-key") && {
            key: sessionStorage.getItem("shopee-timeRangeField-key"),
            text: sessionStorage.getItem("shopee-timeRangeField-text"),
        }) || {
            key: "update_time",
            text: t("shopee.updateTime"),
        }
    );

    // const [unMaskInfo, setUnMaskInfo] = useState(() => {
    //   if (sessionStorage.getItem('shopee-unMaskInfo') === 'true') {
    //     return true;
    //   } else {
    //     return false;
    //   }
    // });

    const [search, setSearch] = useState("");

    const [includeVat, setIncludeVat] = useState(true);

    const [isLoading, setIsLoading] = useState(false);

    const [registerVat, setRegisterVat] = useState(true);

    const isCompactMode = false;
    const [isCreateDocument, setIsCreateDocument] = useState(false);
    const [isCreateDocumentComplete, setIsCreateDocumentComplete] = useState(false);

    const [selectedItems, setSelectedItems] = useState([]);

    const [selection] = useState(
        new Selection({
            onSelectionChanged: () => {
                console.log("isCreateDocument: ", isCreateDocument);
                console.log("isCreateDocumentComplete: ", isCreateDocumentComplete);
                let _selectedItems = selection.getSelection();

                setSelectedItems(_selectedItems);
            },
        })
    );

    const [dialogConfirm, setDialogConfirm] = useState({
        isClick: false,
        header: "",
        message: "",
        onConfirm: null,
        onCancel: null,
    });

    const [createdBy, setCreatedBy] = useState("");

    const [prefixNumber, setPrefixNumber] = useState("");
    const [countingNumber, setCountingNumber] = useState("");

    // const [enableAutomation, setEnableAutomation] = useState(false);

    const [shopId, setShopId] = useState(0);

    const companyId = sessionStorage.getItem("companyId") || "";

    const [primarySort, setPrimarySort] = useState(sessionStorage.getItem("shopee-primarySort") || "updatedTime");
    const [isSortedDescendingCreatedTime, setIsSortedDescendingCreatedTime] = useState(() => {
        if (sessionStorage.getItem("shopee-sort-createdTime")) {
            if (sessionStorage.getItem("shopee-sort-createdTime") === "true") {
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
    });

    const [isSortedDescendingUpdatedTime, setIsSortedDescendingUpdatedTime] = useState(() => {
        if (sessionStorage.getItem("shopee-sort-updatedTime") === "false") {
            return false;
        } else {
            return true;
        }
    });
    const [isSortedDescendingNumber, setIsSortedDescendingNumber] = useState(() => {
        if (sessionStorage.getItem("shopee-sort-number")) {
            if (sessionStorage.getItem("shopee-sort-number") === "true") {
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
    });
    const [isSortedDescendingCustomerName, setIsSortedDescendingCustomerName] = useState(() => {
        if (sessionStorage.getItem("shopee-sort-customerName")) {
            if (sessionStorage.getItem("shopee-sort-customerName") === "true") {
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
    });

    const [isSortedDescendingBuyerPayment, setIsSortedDescendingBuyerPayment] = useState(() => {
        if (sessionStorage.getItem("shopee-sort-buyerPayment")) {
            if (sessionStorage.getItem("shopee-sort-buyerPayment") === "true") {
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
    });

    const [isSortedDescendingIsRequest, setIsSortedDescendingIsRequest] = useState(() => {
        if (sessionStorage.getItem("shopee-sort-isRequest")) {
            if (sessionStorage.getItem("shopee-sort-isRequest") === "true") {
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
    });

    const [showShippingFee, setShowShippingFee] = useState(true);

    const [documentDateTypeOptions, setDocumentDateTypeOptions] = useState(() => {
        let _updatedTimeText = ''

        const _orderType = sessionStorage.getItem("shopee-orderType-key") || 'COMPLETED' 
        if (_orderType === 'all') {
            _updatedTimeText = t("shopee.day1") + t("shopee.updated") + t("shopee.time")
        } else if (_orderType === 'COMPLETED') {
            _updatedTimeText = t("shopee.day1") + t("shopee.COMPLETED") + t("shopee.time")
        } else if (_orderType === 'SHIPPED') {
            _updatedTimeText = t("shopee.day1") + t("shopee.SHIPPED") + t("shopee.time")
        } else if (_orderType === 'READY_TO_SHIP') {
            _updatedTimeText = t("shopee.day1") + t("shopee.readyToSHIPPED") + t("shopee.time")
        } else if (_orderType === 'PROCESSED') {
            _updatedTimeText = t("shopee.day1") + t("shopee.PROCESSED") + t("shopee.time")
        } else if (_orderType === 'INVOICE_PENDING') {
            _updatedTimeText = t("shopee.day1") + t("shopee.InvoicePENDING") + t("shopee.time")
        } else if (_orderType === 'UNPAID') {
            _updatedTimeText = t("shopee.day1") + t("shopee.UNPAID") + t("shopee.time")
        } else if (_orderType === 'IN_CANCEL') {
            _updatedTimeText = t("shopee.day1") + t("shopee.InCANCEL") + t("shopee.time")
        } else if (_orderType === 'CANCELLED') {
            _updatedTimeText = t("shopee.day1") + t("shopee.CANCELLED") + t("shopee.time")
        }

        const _options = [
            {
                key: "updatedTime",
                text: _updatedTimeText,
            },
            {
                key: "createdTime",
                text: t("shopee.createdTimeDate"),
            },
            {
                key: "paidTime",
                text: t("shopee.paidTimeDate"),
            },
            ...(_orderType === "all" || _orderType === "COMPLETED" ? [{
                key: "pickupDoneTime",
                text: t("shopee.pickupDoneTime"),
            }] : []),
        ]

        return _options
    })
    const [selectedDocumentDateType, setSelectedDocumentDateType] = useState(sessionStorage.getItem('shopee-documentDateType') || 'updatedTime')

    const DayPickerStrings = {
        months: [t("shopee.months01"), t("shopee.months02"), t("shopee.months03"), t("shopee.months04"), t("shopee.months05"), t("shopee.months06"), t("shopee.months07"), t("shopee.months08"), t("shopee.months09"), t("shopee.months10"), t("shopee.months11"), t("shopee.months12")],

        shortMonths: [
            t("shopee.shortMonths01"),
            t("shopee.shortMonths02"),
            t("shopee.shortMonths03"),
            t("shopee.shortMonths04"),
            t("shopee.shortMonths05"),
            t("shopee.shortMonths06"),
            t("shopee.shortMonths07"),
            t("shopee.shortMonths08"),
            t("shopee.shortMonths09"),
            t("shopee.shortMonths10"),
            t("shopee.shortMonths11"),
            t("shopee.shortMonths12"),
        ],

        days: ["อาทิตย์", "จันทร์", "อังคาร", "พุธ", "พฤหัสบดี", "ศุกร์", "เสาร์"],

        shortDays: ["อา.", "จ.", "อ.", "พ.", "พฤ.", "ศ.", "ส."],

        goToToday: t("shopee.goToToday"),
        prevMonthAriaLabel: "ไปเดือนที่แล้ว",
        nextMonthAriaLabel: "ไปเดือนถัดไป",
        prevYearAriaLabel: "ไปปีที่แล้ว",
        nextYearAriaLabel: "ไปปีถัดไป",
        closeButtonAriaLabel: "ปิด",
        monthPickerHeaderAriaLabel: "{0}, เลือกเพื่อเปลี่ยนปี",
        yearPickerHeaderAriaLabel: "{0}, เลือกเพื่อเปลี่ยนเดือน",

        isRequiredErrorMessage: t("shopee.isRequiredErrorMessage"),

        invalidInputErrorMessage: "รูปแบบวันที่ไม่ถูกต้อง",
    };

    const createDocument = async (documentType, item) => {
        setIsLoading(true);

        let documentPath = "/etax/documents/receipts";

        switch (documentType) {
            case "RECEIPT-TAXINVOICE":
                documentPath = "/etax/documents/receipts-taxinvoices";
                break;
            case "RECEIPT-TAXINVOICE-ABB":
                documentPath = "/etax/documents/receipts-taxinvoices-abb";
                break;
            case "RECEIPT":
                documentPath = "/etax/documents/receipts";
                break;
            case "INVOICE-TAXINVOICE":
                documentPath = "/etax/documents/invoices-taxinvoices";
                break;
            case "DELIVERYORDER-TAXINVOICE":
                documentPath = "/etax/documents/deliveryorders-taxinvoices";
                break;
            case "TAXINVOICE":
                documentPath = "/etax/documents/taxinvoices";
                break;
            default:
                break;
        }

        instance
            .acquireTokenSilent({ ...silentRequest, account: account })
            .then(async (tokenResponse) => {
                const [response] = await Promise.all([
                    axios
                        .get(`${API_URL}/sources/shopee/escrowdetails?order_sn=${item.key}&shop_id=${shopId}`, {
                            headers: {
                                Authorization: "Bearer " + tokenResponse.accessToken,
                                "Content-Type": "application/json",
                                "cid": companyId
                            },
                        })
                        .catch((error) => console.log(error)),
                ]);

                const orderDetail = response.data;

                console.log("orderDetail", orderDetail);

                let _discount = orderDetail.voucher_from_seller;
                let _amountTotal = 0;
                let _vatTotal = 0;
                let _grandTotal = 0;

                const _items = [];
                orderDetail.items.forEach((item, index) => {
                    let _variation = ''
                    if (item.model_name) {
                        _variation = ` (${item.model_name})`
                    }

                    let _sku = ""
                    if (item.model_sku) {
                        _sku = item.model_sku
                    } else {
                        _sku = item.item_sku
                    }

                    if (registerVat) {
                        _items.push({
                            number: index + 1 + "",
                            description: item.item_name + _variation,
                            quantity: item.quantity_purchased,
                            unitCode: "",
                            unitName: "-",
                            price: item.original_price / item.quantity_purchased,
                            discount: item.seller_discount / item.quantity_purchased,
                            percentVat: percentVat,
                            percentVatText: percentVatText,
                            total: item.discounted_price + item.shopee_discount,
                            sku: _sku
                        });
                    } else {
                        _items.push({
                            number: index + 1 + "",
                            description: item.item_name + _variation,
                            quantity: item.quantity_purchased,
                            unitCode: "",
                            unitName: "-",
                            price: item.original_price / item.quantity_purchased,
                            discount: item.seller_discount / item.quantity_purchased,
                            percentVat: zeroVat,
                            percentVatText: zeroVatText,
                            total: item.discounted_price + item.shopee_discount,
                            sku: _sku
                        });
                    }
                });

                if (showShippingFee) {
                    _items.push({
                        number: (_items.length + 1) + "",
                        description: "ค่าขนส่ง",
                        quantity: 1,
                        unitCode: "",
                        unitName: "-",
                        price: orderDetail.buyer_paid_shipping_fee > 0 ? orderDetail.buyer_paid_shipping_fee : 0,
                        discount: 0,
                        percentVat: registerVat ? percentVat : zeroVat,
                        percentVatText: registerVat ? percentVatText : zeroVatText,
                        total: orderDetail.buyer_paid_shipping_fee > 0 ? orderDetail.buyer_paid_shipping_fee : 0,
                        sku: ""
                    });
                }

                if (includeVat) {
                    _grandTotal = _items.reduce((accumulator, item) => accumulator + item.total, 0) - _discount;
                    _vatTotal = Math.round(((_grandTotal * percentVat) / (100 + percentVat)) * 100) / 100;
                    _amountTotal = Math.round((_grandTotal - _vatTotal) * 100) / 100;
                } else {
                    _amountTotal = _items.reduce((accumulator, item) => accumulator + item.total, 0) - _discount;
                    _vatTotal = Math.round(((_grandTotal * percentVat) / (100 + percentVat)) * 100) / 100;
                    _grandTotal = Math.round((_amountTotal + _vatTotal) * 100) / 100;
                }

                let _date = new Date(item.updatedTimestamp * 1000);
                if (selectedDocumentDateType === "createdTime") {
                    _date = new Date(item.createdTimestamp * 1000);
                } else if (selectedDocumentDateType === "paidTime") {
                    if (item.paidTimestamp) {
                        _date = new Date(item.paidTimestamp * 1000);
                    } else {
                        _date = new Date(item.createdTimestamp * 1000);
                    }
                } else if (selectedDocumentDateType === "pickupDoneTime") {
                    if (item.pickupDoneTimestamp) {
                        _date = new Date(item.pickupDoneTimestamp * 1000);
                    } else {
                        _date = new Date(item.createdTimestamp * 1000);
                    }
                }

                let _dateBE = `${_date.getDate()}`.padStart(2, "0") + "/" + `${_date.getMonth() + 1}`.padStart(2, "0") + "/" + (_date.getFullYear() + 543);
                let _dateCE = `${_date.getDate()}`.padStart(2, "0") + "/" + `${_date.getMonth() + 1}`.padStart(2, "0") + "/" + _date.getFullYear();

                let _customer = {
                    addressLineOne: item.customerAddress,
                    addressLineTwo: "",
                    branchNumber: item.branchNumber,
                    branchText: item.branchText,
                    email: item.customerEmail,
                    name: item.customerName,
                    phone: item.customerPhone !== "" ? item.customerPhone : "",
                    phoneCountryCode: "+66",
                    postcode: item.customerPostcode,
                    taxId: item.customerTaxId,
                    taxNumberType: item.taxNumberType,
                    language: "th",
                    countryCode: "TH",
                    countryName: "ไทย",
                    unstructure: true,
                };

                console.log("_customer: ", _customer);
                console.log(_date);
                console.log(_dateBE);
                console.log(_dateCE);

                // console.log({
                //     pathname: documentPath,
                //     state: {
                //         command: "EDIT",
                //         amountTotal: _amountTotal,
                //         company: {
                //             ...company,
                //             includeVat: includeVat,
                //         },
                //         reference: item.orderId,
                //         customer: _customer,
                //         date: _date,
                //         dateBE: _dateBE,
                //         dateCE: _dateCE,
                //         grandTotal: _grandTotal,
                //         items: _items,
                //         percentVat: registerVat ? percentVat : 0,
                //         receivedBy: "Shopee",
                //         vatTotal: _vatTotal,
                //         reIssue: false,
                //         discount: _discount,
                //         backUrl: "/etax/sources/shopee",
                //         includeVat: includeVat,
                //         note: item.note,
                //     },
                // });

                history.push({
                    pathname: documentPath,
                    state: {
                        command: "EDIT",
                        amountTotal: _amountTotal,
                        company: {
                            ...company,
                            includeVat: includeVat,
                        },
                        reference: item.orderId,
                        customer: _customer,
                        date: _date,
                        dateBE: _dateBE,
                        dateCE: _dateCE,
                        grandTotal: _grandTotal,
                        items: _items,
                        percentVat: registerVat ? percentVat : 0,
                        receivedBy: "Shopee",
                        vatTotal: _vatTotal,
                        reIssue: false,
                        discount: _discount,
                        backUrl: "/etax/sources/shopee",
                        includeVat: includeVat,
                        note: item.note,
                    },
                });
            })
            .catch((error) => {
                //Acquire token silent failure, and send an interactive request
                console.log(error);
                instance.acquireTokenRedirect(silentRequest);
            });
    };

    let columns = [
        {
            key: "column1",
            name: t("shopee.createdTime"),
            fieldName: "createdTime",
            minWidth: 70,
            maxWidth: 115,
            isResizable: true,
            isSorted: primarySort === "createdTime" ? true : false,
            isSortedDescending: isSortedDescendingCreatedTime,
            isSortedAscending: !isSortedDescendingCreatedTime,
            onColumnClick: () => {
                if (orderType.key === "all") {
                    console.log("Sort document createTime!");
                    setPrimarySort("createdTime");
                    setIsSortedDescendingCreatedTime(!isSortedDescendingCreatedTime);

                    handleSort("createdTime", !isSortedDescendingCreatedTime);

                    sessionStorage.setItem("shopee-primarySort", "createdTime");
                    sessionStorage.setItem("shopee-sort-createdTime", !isSortedDescendingCreatedTime === false ? "false" : "true");

                    sessionStorage.removeItem("shopee-sort-updatedTime");
                    sessionStorage.removeItem("shopee-sort-number");
                    sessionStorage.removeItem("shopee-sort-customerName");
                    sessionStorage.removeItem("shopee-sort-vatTotal");
                    sessionStorage.removeItem("shopee-sort-buyerPayment");
                    sessionStorage.removeItem("shopee-sort-isRequest");

                    setIsCreateDocumentComplete(false);
                    setSelectedItems([]);
                }
            },
            data: "string",
            onRender: (item) => {
                return <span>{item.createdTime}</span>;
            },
        },
        {
            key: "column2",
            // name: t('shopee.day1') + (orderType.key === 'all' ? t('shopee.updatedDate') : `${orderType.text}${t('shopee.day2')}`),
            name:
                t("shopee.day1") +
                (orderType.key === "all"
                    ? t("shopee.updatedDate")
                    : orderType.key === "COMPLETED"
                    ? `${t("shopee.COMPLETED")}${t("shopee.day2")}`
                    : orderType.key === "SHIPPED"
                    ? `${t("shopee.SHIPPED")}${t("shopee.day2")}`
                    : orderType.key === "READY_TO_SHIP"
                    ? `${t("shopee.readyToSHIPPED")}${t("shopee.day2")}`
                    : orderType.key === "PROCESSED"
                    ? `${t("shopee.PROCESSED")}${t("shopee.day2")}`
                    : orderType.key === "INVOICE_PENDING"
                    ? `${t("shopee.InvoicePENDING")}${t("shopee.day2")}`
                    : orderType.key === "UNPAID"
                    ? `${t("shopee.UNPAID")}${t("shopee.day2")}`
                    : orderType.key === "IN_CANCEL"
                    ? `${t("shopee.InCANCEL")}${t("shopee.day2")}`
                    : orderType.key === "CANCELLED"
                    ? `${t("shopee.CANCELLED")}${t("shopee.day2")}`
                    : `${orderType.text}${t("shopee.day2")}`),
            fieldName: "updatedTime",
            minWidth: 70,
            maxWidth: 125,
            isResizable: true,
            isSorted: primarySort === "updatedTime" ? true : false,
            isSortedDescending: isSortedDescendingUpdatedTime,
            isSortedAscending: !isSortedDescendingUpdatedTime,
            onColumnClick: () => {
                if (orderType.key !== "all") {
                    console.log("Sort document updatedTime!");
                    setPrimarySort("updatedTime");
                    setIsSortedDescendingUpdatedTime(!isSortedDescendingUpdatedTime);

                    handleSort("updatedTime", !isSortedDescendingUpdatedTime);

                    sessionStorage.setItem("shopee-primarySort", "updatedTime");
                    sessionStorage.setItem("shopee-sort-updatedTime", !isSortedDescendingUpdatedTime === false ? "false" : "true");

                    sessionStorage.removeItem("shopee-sort-createdTime");
                    sessionStorage.removeItem("shopee-sort-number");
                    sessionStorage.removeItem("shopee-sort-customerName");
                    sessionStorage.removeItem("shopee-sort-vatTotal");
                    sessionStorage.removeItem("shopee-sort-buyerPayment");
                    sessionStorage.removeItem("shopee-sort-isRequest");

                    setIsCreateDocumentComplete(false);
                    setSelectedItems([]);
                }
            },
            data: "string",
            onRender: (item) => {
                return <span>{item.updatedTime}</span>;
            },
        },
        {
            key: "column3",
            name: t("shopee.orderId"),
            fieldName: "orderId",
            minWidth: 120,
            maxWidth: 120,
            isRowHeader: true,
            isResizable: true,
            isSorted: primarySort === "number" ? true : false,
            isSortedDescending: isSortedDescendingNumber,
            isSortedAscending: !isSortedDescendingNumber,
            onColumnClick: () => {
                console.log("Sort document number!");
                setPrimarySort("number");
                setIsSortedDescendingNumber(!isSortedDescendingNumber);

                handleSort("number", !isSortedDescendingNumber);

                sessionStorage.setItem("shopee-primarySort", "number");
                sessionStorage.setItem("shopee-sort-number", !isSortedDescendingNumber === false ? "false" : "true");

                sessionStorage.removeItem("shopee-sort-createdTime");
                sessionStorage.removeItem("shopee-sort-updatedTime");
                sessionStorage.removeItem("shopee-sort-customerName");
                sessionStorage.removeItem("shopee-sort-vatTotal");
                sessionStorage.removeItem("shopee-sort-buyerPayment");
                sessionStorage.removeItem("shopee-sort-isRequest");

                setIsCreateDocumentComplete(false);
                setSelectedItems([]);
            },
            data: "string",
            onRender: (item) => {
                return <span>{item.orderId}</span>;
            },
        },
        {
            key: "column4",
            name: t("shopee.customerName"),
            fieldName: "customerName",
            minWidth: 100,
            maxWidth: 110,
            isResizable: true,
            isSorted: primarySort === "customerName" ? true : false,
            isSortedDescending: isSortedDescendingCustomerName,
            isSortedAscending: !isSortedDescendingCustomerName,
            onColumnClick: () => {
                console.log("Sort document number!");
                setPrimarySort("customerName");
                setIsSortedDescendingCustomerName(!isSortedDescendingCustomerName);

                handleSort("customerName", !isSortedDescendingCustomerName);

                sessionStorage.setItem("shopee-primarySort", "customerName");
                sessionStorage.setItem("shopee-sort-customerName", !isSortedDescendingCustomerName === false ? "false" : "true");

                sessionStorage.removeItem("shopee-sort-createdTime");
                sessionStorage.removeItem("shopee-sort-updatedTime");
                sessionStorage.removeItem("shopee-sort-number");
                sessionStorage.removeItem("shopee-sort-vatTotal");
                sessionStorage.removeItem("shopee-sort-buyerPayment");
                sessionStorage.removeItem("shopee-sort-isRequest");

                setIsCreateDocumentComplete(false);
                setSelectedItems([]);
            },
            data: "string",
            onRender: (item) => {
                return <span>{item.customerName}</span>;
            },
        },
        {
            key: "column5",
            name: t("shopee.customerAddress"),
            fieldName: "customerAddress",
            minWidth: 120,
            maxWidth: 270,
            isRowHeader: true,
            isResizable: true,
            isSorted: false,
            isSortedDescending: false,
            sortAscendingAriaLabel: "Sorted A to Z",
            sortDescendingAriaLabel: "Sorted Z to A",
            onColumnClick: "",
            data: "string",
            onRender: (item) => {
                return <span>{item.customerAddress}</span>;
            },
        },
        {
            key: "column6",
            name: t("shopee.phone"),
            fieldName: "name",
            minWidth: 80,
            maxWidth: 80,
            isCollapsible: true,
            isRowHeader: true,
            isResizable: true,
            isSorted: false,
            isSortedDescending: false,
            sortAscendingAriaLabel: "Sorted A to Z",
            sortDescendingAriaLabel: "Sorted Z to A",
            onColumnClick: "",
            data: "string",
            onRender: (item) => {
                return <span>{item.customerPhone}</span>;
            },
        },
        // {
        //   key: 'column7',
        //   name: t('shopee.vatTotal'),
        //   fieldName: 'vatTotal',
        //   minWidth: 60,
        //   maxWidth: 60,
        //   isResizable: true,
        //   isSorted: primarySort === 'vatTotal' ? true : false,
        //   isSortedDescending: isSortedDescendingVatTotal,
        //   isSortedAscending: !isSortedDescendingVatTotal,
        //   onColumnClick: () => {
        //     console.log('Sort document vatTotal!');
        //     setPrimarySort('vatTotal');
        //     setIsSortedDescendingVatTotal(!isSortedDescendingVatTotal);

        //     handleSort('vatTotal', !isSortedDescendingVatTotal);

        //     sessionStorage.setItem('shopee-primarySort', 'vatTotal');
        //     sessionStorage.setItem('shopee-sort-vatTotal', !isSortedDescendingVatTotal === false ? 'false' : 'true');

        //     sessionStorage.removeItem('shopee-sort-createdTime');
        //     sessionStorage.removeItem('shopee-sort-updatedTime');
        //     sessionStorage.removeItem('shopee-sort-number');
        //     sessionStorage.removeItem('shopee-sort-customerName');
        //     sessionStorage.removeItem('shopee-sort-grandTotal');

        //     setIsCreateDocumentComplete(false);
        //     setSelectedItems([]);
        //   },
        //   data: 'string',
        //   onRender: (item) => {
        //     return (
        //       <span style={{ display: 'block', textAlign: 'right' }}>
        //         {item.vatTotal.toLocaleString(undefined, {
        //           maximumFractionDigits: 2,
        //           minimumFractionDigits: 2,
        //         })}
        //       </span>
        //     );
        //   },
        // },
        // {
        //   key: 'column8',
        //   name: registerVat ? t('shopee.grandTotal') : t('shopee.total'),
        //   fieldName: 'grandTotal',
        //   minWidth: 70,
        //   maxWidth: 70,
        //   isResizable: true,
        //   isSorted: primarySort === 'grandTotal' ? true : false,
        //   isSortedDescending: isSortedDescendingGrandTotal,
        //   isSortedAscending: !isSortedDescendingGrandTotal,
        //   onColumnClick: () => {
        //     console.log('Sort document grandTotal!');
        //     setPrimarySort('grandTotal');
        //     setIsSortedDescendingGrandTotal(!isSortedDescendingGrandTotal);

        //     handleSort('grandTotal', !isSortedDescendingGrandTotal);

        //     sessionStorage.setItem('shopee-primarySort', 'grandTotal');
        //     sessionStorage.setItem('shopee-sort-grandTotal', !isSortedDescendingGrandTotal === false ? 'false' : 'true');

        //     sessionStorage.removeItem('shopee-sort-createdTime');
        //     sessionStorage.removeItem('shopee-sort-updatedTime');
        //     sessionStorage.removeItem('shopee-sort-customerName');
        //     sessionStorage.removeItem('shopee-sort-number');
        //     sessionStorage.removeItem('shopee-sort-vatTotal');

        //     setIsCreateDocumentComplete(false);
        //     setSelectedItems([]);
        //   },
        //   data: 'string',
        //   onRender: (item) => {
        //     return (
        //       <span style={{ display: 'block', textAlign: 'right' }}>
        //         {item.grandTotal.toLocaleString(undefined, {
        //           maximumFractionDigits: 2,
        //           minimumFractionDigits: 2,
        //         })}
        //       </span>
        //     );
        //   },
        // },
        {
            key: "column8",
            name: t("shopee.buyerPayment"),
            fieldName: "buyerPayment",
            minWidth: 100,
            maxWidth: 100,
            isResizable: true,
            isSorted: primarySort === "buyerPayment" ? true : false,
            isSortedDescending: isSortedDescendingBuyerPayment,
            isSortedAscending: !isSortedDescendingBuyerPayment,
            onColumnClick: () => {
                console.log("Sort document buyerPayment!");
                setPrimarySort("buyerPayment");
                setIsSortedDescendingBuyerPayment(!isSortedDescendingBuyerPayment);

                handleSort("buyerPayment", !isSortedDescendingBuyerPayment);

                sessionStorage.setItem("shopee-primarySort", "buyerPayment");
                sessionStorage.setItem("shopee-sort-buyerPayment", !isSortedDescendingBuyerPayment === false ? "false" : "true");

                sessionStorage.removeItem("shopee-sort-createdTime");
                sessionStorage.removeItem("shopee-sort-updatedTime");
                sessionStorage.removeItem("shopee-sort-customerName");
                sessionStorage.removeItem("shopee-sort-number");
                sessionStorage.removeItem("shopee-sort-vatTotal");
                sessionStorage.removeItem("shopee-sort-isRequest");

                setIsCreateDocumentComplete(false);
                setSelectedItems([]);
            },
            data: "string",
            onRender: (item) => {
                return (
                    <span style={{ display: "block", textAlign: "right" }}>
                        {item.buyerPayment.toLocaleString(undefined, {
                            maximumFractionDigits: 2,
                            minimumFractionDigits: 2,
                        })}
                    </span>
                );
            },
        },
        {
            key: "column9",
            name: t("shopee.isRequest"),
            fieldName: "isRequest",
            minWidth: 50,
            maxWidth: 50,
            isResizable: true,
            isSorted: primarySort === "isRequest" ? true : false,
            isSortedDescending: isSortedDescendingIsRequest,
            isSortedAscending: !isSortedDescendingIsRequest,
            onColumnClick: () => {
                console.log("Sort document number!");
                setPrimarySort("isRequest");
                setIsSortedDescendingIsRequest(!isSortedDescendingIsRequest);

                handleSort("isRequest", !isSortedDescendingIsRequest);

                sessionStorage.setItem("shopee-primarySort", "isRequest");
                sessionStorage.setItem("shopee-sort-isRequest", !isSortedDescendingIsRequest === false ? "false" : "true");

                sessionStorage.removeItem("shopee-sort-createdTime");
                sessionStorage.removeItem("shopee-sort-updatedTime");
                sessionStorage.removeItem("shopee-sort-customerName");
                sessionStorage.removeItem("shopee-sort-vatTotal");
                sessionStorage.removeItem("shopee-sort-buyerPayment");
                sessionStorage.removeItem("shopee-sort-number");

                setIsCreateDocumentComplete(false);
                setSelectedItems([]);
            },
            data: "string",
            onRender: (item) => {
                if (item.isRequest) {
                    return (
                        <div style={{ textAlign: "center" }}>
                            <Icon iconName="SkypeCircleCheck" style={{ fontSize: "20px", color: "green" }} />
                        </div>
                    );
                }
            },
        },
        {
            key: "column10",
            name: "Leceipt",
            fieldName: "etaxCreated",
            minWidth: 50,
            maxWidth: 50,
            isResizable: true,
            data: "string",
            onRender: (item) => {
                if (item.etaxCreated) {
                    return (
                        <div style={{ textAlign: "center" }}>
                            <Icon iconName="SkypeCircleCheck" style={{ fontSize: "20px", color: "green" }} />
                        </div>
                    );
                }
            },
        },
        {
            key: "column11",
            name: "",
            fieldName: "command",
            minWidth: 120,
            maxWidth: 120,
            isResizable: true,
            data: "string",
            onColumnClick: "",
            onRender: (item) => {
                if (isCreateDocument || isCreateDocumentComplete) {
                    if (item.status === "pending") {
                        return <span style={{ color: "#106EBE" }}>{t("shopee.pending")}</span>;
                    } else if (item.status === "processing") {
                        return <Spinner label={t("shopee.processing")} labelPosition="right" style={{ marginLeft: "0px" }} />;
                    } else if (item.status === "successful") {
                        return <span style={{ color: "green" }}>{t("shopee.success")}</span>;
                    } else if (item.status === "fail") {
                        return <span style={{ color: "red" }}>{t("shopee.fail")}</span>;
                    }
                } else {
                    return (
                        <span data-selection-disabled={true}>
                            <DefaultButton
                                text={t("shopee.create")}
                                iconProps={{ iconName: "Add", style: { color: "#106ebe" } }}
                                menuProps={{
                                    items: [
                                        {
                                            key: "RECEIPT-TAXINVOICE",
                                            text: t("shopee.receipt-taxinvoice"),
                                            iconProps: { iconName: "PageAdd" },
                                            onClick: () => {
                                                createDocument("RECEIPT-TAXINVOICE", item);
                                            },
                                        },
                                        {
                                            key: "RECEIPT-TAXINVOICE-ABB",
                                            text: t("shopee.receipt-taxinvoice-abb"),
                                            iconProps: { iconName: "PageAdd" },
                                            onClick: () => {
                                                createDocument("RECEIPT-TAXINVOICE-ABB", item);
                                            },
                                        },
                                        {
                                            key: "RECEIPT",
                                            text: t("shopee.receipt"),
                                            iconProps: { iconName: "PageAdd" },
                                            onClick: () => {
                                                createDocument("RECEIPT", item);
                                            },
                                        },
                                        {
                                            key: "INVOICE-TAXINVOICE",
                                            text: t("shopee.invoice-taxinvoice"),
                                            iconProps: { iconName: "PageAdd" },
                                            onClick: () => {
                                                createDocument("INVOICE-TAXINVOICE", item);
                                            },
                                        },
                                        {
                                            key: "DELIVERYORDER-TAXINVOICE",
                                            text: t("shopee.deliveryorder-taxinvoice"),
                                            iconProps: { iconName: "PageAdd" },
                                            onClick: () => {
                                                createDocument("DELIVERYORDER-TAXINVOICE", item);
                                            },
                                        },
                                        {
                                            key: "TAXINVOICE",
                                            text: t("shopee.taxinvoice"),
                                            iconProps: { iconName: "PageAdd" },
                                            onClick: () => {
                                                createDocument("TAXINVOICE", item);
                                            },
                                        },
                                    ],
                                    shouldFocusOnMount: true,
                                    directionalHintFixed: true,
                                }}
                                // Optional callback to customize menu rendering
                                menuAs={(props) => <ContextualMenu {...props} />}
                            />
                        </span>
                    );
                }
            },
        },
    ];

    const timestamp_toDate = (timestamp) => {
        let date = new Date(timestamp * 1000);

        let month = "" + (date.getMonth() + 1);
        let day = "" + date.getDate();
        let year = date.getFullYear() + 543;

        if (month.length < 2) month = "0" + month;
        if (day.length < 2) day = "0" + day;

        return [day, month, year].join("/");
    };

    const [queryMonth, setQueryMonth] = useState(
        (sessionStorage.getItem("shopee-queryMonth-key") && {
            type: "dateRange",
            key: sessionStorage.getItem("shopee-queryMonth-key"),
            text: sessionStorage.getItem("shopee-queryMonth-text"),
        }) || {
            type: "dateRange",
            key: formatDate(new Date()) + ":" + formatDate(new Date()),
            text: formatDate(new Date()) + " - " + formatDate(new Date()),
        }
    );

    const [fromDate, setFromDate] = useState(sessionStorage.getItem("shopee-fromDate") ? new Date(sessionStorage.getItem("shopee-fromDate")) : new Date());
    const [toDate, setToDate] = useState(sessionStorage.getItem("shopee-toDate") ? new Date(sessionStorage.getItem("shopee-toDate")) : new Date());
    const [timeRangeError, setTimeRangeError] = useState(false);

    const getFrom = (day, month, year) => {
        let _firstDay = new Date(year, month, day);
        console.log("First day: ", formatDate(_firstDay));

        return formatDate(_firstDay);
    };

    const getTo = (day, month, year) => {
        let _lastDay = new Date(year, month, day + 1);
        console.log("Last day: ", formatDate(_lastDay));

        return formatDate(_lastDay);
    };

    const getFrom2 = (day, month, year) => {
        let _firstDay = new Date(year, month, day);

        return Math.floor(_firstDay / 1000);
    };

    const getTo2 = (day, month, year) => {
        let _lastDay = new Date(year, month, day + 1);

        return Math.floor(_lastDay / 1000);
    };

    const [from2, setFrom2] = useState(sessionStorage.getItem("shopee-from2") || getFrom2(new Date().getDate(), new Date().getMonth(), new Date().getFullYear()));
    const [to2, setTo2] = useState(sessionStorage.getItem("shopee-to2") || getTo2(new Date().getDate(), new Date().getMonth(), new Date().getFullYear()));

    let _pageDropdown = [];

    for (let i = 1; i <= 100; i++) {
        _pageDropdown.push({
            key: i,
            text: t("shipnity.page") + " " + i,
            iconProps: { iconName: "Page" },
            onClick: () => {
                console.log("Selected page: " + i);
                setCurrentPage(i);
            },
        });
    }

    const pageDropdown = _pageDropdown;

    const history = useHistory();

    function getDateText(monthObject) {
        if (monthObject.type === "dateRange") {
            let _splitDate = monthObject.key.split(":");
            let _fromSplit = _splitDate[0].split("-");
            let _toSplit = _splitDate[1].split("-");

            if (i18n.language === "th") {
                let _from = _fromSplit[2] + "/" + _fromSplit[1] + "/" + (Number(_fromSplit[0]) + 543);
                let _to = _toSplit[2] + "/" + _toSplit[1] + "/" + (Number(_toSplit[0]) + 543);

                return _from + " - " + _to;
            }
            let _from = _fromSplit[2] + "/" + _fromSplit[1] + "/" + Number(_fromSplit[0]);
            let _to = _toSplit[2] + "/" + _toSplit[1] + "/" + Number(_toSplit[0]);

            return _from + " - " + _to;
        }

        if (monthObject.text === "เดือนนี้" || monthObject.text === "This Month") return t("shopee.thisMonth");

        console.log("monthObject", monthObject);
        let monthNames = [t("shopee.months01"), t("shopee.months02"), t("shopee.months03"), t("shopee.months04"), t("shopee.months05"), t("shopee.months06"), t("shopee.months07"), t("shopee.months08"), t("shopee.months09"), t("shopee.months10"), t("shopee.months11"), t("shopee.months12")];

        let _monthIndex = Number(monthObject.key.substring(4, 6)) - 1;
        let _year = Number(monthObject.key.substring(0, 4));
        if (i18n.language === "th") {
            _year = _year + 543;
        }

        return monthNames[_monthIndex] + " " + _year;
    }

    function documentDateTypeOptionsUpdate(_status) {
        let _updatedTimeText = ''
        if (_status === 'all') {
            _updatedTimeText = t("shopee.day1") + t("shopee.updated") + t("shopee.time")
        } else if (_status === 'COMPLETED') {
            _updatedTimeText = t("shopee.day1") + t("shopee.COMPLETED") + t("shopee.time")
        } else if (_status === 'SHIPPED') {
            _updatedTimeText = t("shopee.day1") + t("shopee.SHIPPED") + t("shopee.time")
        } else if (_status === 'READY_TO_SHIP') {
            _updatedTimeText = t("shopee.day1") + t("shopee.readyToSHIPPED") + t("shopee.time")
        } else if (_status === 'PROCESSED') {
            _updatedTimeText = t("shopee.day1") + t("shopee.PROCESSED") + t("shopee.time")
        } else if (_status === 'INVOICE_PENDING') {
            _updatedTimeText = t("shopee.day1") + t("shopee.InvoicePENDING") + t("shopee.time")
        } else if (_status === 'UNPAID') {
            _updatedTimeText = t("shopee.day1") + t("shopee.UNPAID") + t("shopee.time")
        } else if (_status === 'IN_CANCEL') {
            _updatedTimeText = t("shopee.day1") + t("shopee.InCANCEL") + t("shopee.time")
        } else if (_status === 'CANCELLED') {
            _updatedTimeText = t("shopee.day1") + t("shopee.CANCELLED") + t("shopee.time")
        }
        
        const _options = [
            {
                key: "updatedTime",
                text: _updatedTimeText,
            },
            {
                key: "createdTime",
                text: t("shopee.createdTimeDate"),
            },
            {
                key: "paidTime",
                text: t("shopee.paidTimeDate"),
            },
            ...(_status === "all" || _status === "COMPLETED" ? [{
                key: "pickupDoneTime",
                text: t("shopee.pickupDoneTime"),
            }] : []),
        ]

        setDocumentDateTypeOptions(_options)

        if ((_status !== "all" && _status !== "COMPLETED") && selectedDocumentDateType === "pickupDoneTime") {
            setSelectedDocumentDateType("updatedTime")

            sessionStorage.setItem("shopee-documentDateType", 'updatedTime');
        }
    }

    const commandBarItems = [
        {
            key: orderType.key,
            text:
                orderType.key === "all"
                    ? t("shopee.allType")
                    : orderType.key === "COMPLETED"
                    ? t("shopee.COMPLETED")
                    : orderType.key === "SHIPPED"
                    ? t("shopee.SHIPPED")
                    : orderType.key === "READY_TO_SHIP"
                    ? t("shopee.readyToSHIPPED")
                    : orderType.key === "PROCESSED"
                    ? t("shopee.PROCESSED")
                    : orderType.key === "INVOICE_PENDING"
                    ? t("shopee.InvoicePENDING")
                    : orderType.key === "UNPAID"
                    ? t("shopee.UNPAID")
                    : orderType.key === "IN_CANCEL"
                    ? t("shopee.InCANCEL")
                    : orderType.key === "CANCELLED"
                    ? t("shopee.CANCELLED")
                    : orderType.text,

            // text: orderType.text,
            cacheKey: "myCacheKey", // changing this key will invalidate this item's cache
            iconProps: { iconName: "ProductList" },
            disabled: !loadDataComplete || isCreateDocument || search,
            subMenuProps: {
                items: [
                    {
                        key: "all",
                        text: t("shopee.allType"),
                        iconProps: { iconName: "ProductList" },
                        onClick: () => {
                            console.log("เอกสารทุกประเภท");
                            setOrderType({ key: "all", text: t("shopee.allType") });

                            sessionStorage.setItem("shopee-orderType-key", "all");
                            sessionStorage.setItem("shopee-orderType-text", t("shopee.allType"));

                            setPrimarySort("createdTime");
                            setIsSortedDescendingCreatedTime(false);

                            setTimeRangeField({
                                key: "create_time",
                                text: t("shopee.createTime"),
                            });

                            sessionStorage.setItem("shopee-timeRangeField-key", "create_time");
                            sessionStorage.setItem("shopee-timeRangeField-text", t("shopee.createTime"));

                            sessionStorage.setItem("shopee-primarySort", "createdTime");
                            sessionStorage.setItem("shopee-sort-createdTime", "false");

                            sessionStorage.removeItem("shopee-sort-updatedTime");
                            sessionStorage.removeItem("shopee-sort-number");
                            sessionStorage.removeItem("shopee-sort-customerName");
                            sessionStorage.removeItem("shopee-sort-vatTotal");
                            sessionStorage.removeItem("shopee-sort-grandTotal");

                            setLoadDataComplete(false);
                            setItems([]);
                            setGetNewDocuments(true);

                            setSearch("");

                            setCurrentPage(1);

                            documentDateTypeOptionsUpdate('all')
                        },
                    },
                    {
                        key: "COMPLETED",
                        text: t("shopee.COMPLETED"),
                        iconProps: { iconName: "ProductList" },
                        onClick: () => {
                            console.log("สำเร็จ");
                            setOrderType({
                                key: "COMPLETED",
                                text: t("shopee.COMPLETED"),
                            });

                            sessionStorage.setItem("shopee-orderType-key", "COMPLETED");
                            sessionStorage.setItem("shopee-orderType-text", t("shopee.COMPLETED"));

                            setTimeRangeField({
                                key: "update_time",
                                text: t("shopee.updateTime"),
                            });

                            sessionStorage.setItem("shopee-timeRangeField-key", "update_time");
                            sessionStorage.setItem("shopee-timeRangeField-text", t("shopee.updateTime"));

                            setPrimarySort("updatedTime");
                            setIsSortedDescendingUpdatedTime(false);

                            sessionStorage.setItem("shopee-primarySort", "updatedTime");
                            sessionStorage.setItem("shopee-sort-updatedTime", "false");

                            sessionStorage.removeItem("shopee-sort-createdTime");
                            sessionStorage.removeItem("shopee-sort-number");
                            sessionStorage.removeItem("shopee-sort-customerName");
                            sessionStorage.removeItem("shopee-sort-vatTotal");
                            sessionStorage.removeItem("shopee-sort-grandTotal");

                            setLoadDataComplete(false);
                            setItems([]);
                            setGetNewDocuments(true);

                            setSearch("");

                            setCurrentPage(1);

                            documentDateTypeOptionsUpdate('COMPLETED')
                        },
                    },
                    {
                        key: "SHIPPED",
                        text: t("shopee.SHIPPED"),
                        iconProps: { iconName: "ProductList" },
                        onClick: () => {
                            console.log("อยู่ระหว่างการจัดส่ง");
                            setOrderType({
                                key: "SHIPPED",
                                text: t("shopee.SHIPPED"),
                            });

                            sessionStorage.setItem("shopee-orderType-key", "SHIPPED");
                            sessionStorage.setItem("shopee-orderType-text", t("shopee.SHIPPED"));

                            setTimeRangeField({
                                key: "update_time",
                                text: t("shopee.updateTime"),
                            });

                            sessionStorage.setItem("shopee-timeRangeField-key", "update_time");
                            sessionStorage.setItem("shopee-timeRangeField-text", t("shopee.updateTime"));

                            setPrimarySort("updatedTime");
                            setIsSortedDescendingUpdatedTime(false);

                            sessionStorage.setItem("shopee-primarySort", "updatedTime");
                            sessionStorage.setItem("shopee-sort-updatedTime", "false");

                            sessionStorage.removeItem("shopee-sort-createdTime");
                            sessionStorage.removeItem("shopee-sort-number");
                            sessionStorage.removeItem("shopee-sort-customerName");
                            sessionStorage.removeItem("shopee-sort-vatTotal");
                            sessionStorage.removeItem("shopee-sort-grandTotal");

                            setLoadDataComplete(false);
                            setItems([]);
                            setGetNewDocuments(true);

                            setSearch("");

                            setCurrentPage(1);

                            documentDateTypeOptionsUpdate('SHIPPED')
                        },
                    },
                    {
                        key: "READY_TO_SHIP",
                        text: t("shopee.readyToSHIPPED"),
                        iconProps: { iconName: "ProductList" },
                        onClick: () => {
                            console.log("พร้อมส่ง");
                            setOrderType({
                                key: "READY_TO_SHIP",
                                text: t("shopee.readyToSHIPPED"),
                            });

                            sessionStorage.setItem("shopee-orderType-key", "READY_TO_SHIP");
                            sessionStorage.setItem("shopee-orderType-text", t("shopee.readyToSHIPPED"));

                            setTimeRangeField({
                                key: "update_time",
                                text: t("shopee.updateTime"),
                            });

                            sessionStorage.setItem("shopee-timeRangeField-key", "update_time");
                            sessionStorage.setItem("shopee-timeRangeField-text", t("shopee.updateTime"));

                            setPrimarySort("updatedTime");
                            setIsSortedDescendingUpdatedTime(false);

                            sessionStorage.setItem("shopee-primarySort", "updatedTime");
                            sessionStorage.setItem("shopee-sort-updatedTime", "false");

                            sessionStorage.removeItem("shopee-sort-createdTime");
                            sessionStorage.removeItem("shopee-sort-number");
                            sessionStorage.removeItem("shopee-sort-customerName");
                            sessionStorage.removeItem("shopee-sort-vatTotal");
                            sessionStorage.removeItem("shopee-sort-grandTotal");

                            setLoadDataComplete(false);
                            setItems([]);
                            setGetNewDocuments(true);

                            setSearch("");

                            setCurrentPage(1);

                            documentDateTypeOptionsUpdate('READY_TO_SHIP')
                        },
                    },
                    {
                        key: "PROCESSED",
                        text: t("shopee.PROCESSED"),
                        iconProps: { iconName: "ProductList" },
                        onClick: () => {
                            console.log("กำลังดำเนินการ");
                            setOrderType({
                                key: "PROCESSED",
                                text: t("shopee.PROCESSED"),
                            });

                            sessionStorage.setItem("shopee-orderType-key", "PROCESSED");
                            sessionStorage.setItem("shopee-orderType-text", t("shopee.PROCESSED"));

                            setTimeRangeField({
                                key: "update_time",
                                text: t("shopee.updateTime"),
                            });

                            sessionStorage.setItem("shopee-timeRangeField-key", "update_time");
                            sessionStorage.setItem("shopee-timeRangeField-text", t("shopee.updateTime"));

                            setPrimarySort("updatedTime");
                            setIsSortedDescendingUpdatedTime(false);

                            sessionStorage.setItem("shopee-primarySort", "updatedTime");
                            sessionStorage.setItem("shopee-sort-updatedTime", "false");

                            sessionStorage.removeItem("shopee-sort-createdTime");
                            sessionStorage.removeItem("shopee-sort-number");
                            sessionStorage.removeItem("shopee-sort-customerName");
                            sessionStorage.removeItem("shopee-sort-vatTotal");
                            sessionStorage.removeItem("shopee-sort-grandTotal");

                            setLoadDataComplete(false);
                            setItems([]);
                            setGetNewDocuments(true);

                            setSearch("");

                            setCurrentPage(1);

                            documentDateTypeOptionsUpdate('PROCESSED')
                        },
                    },
                    {
                        key: "INVOICE_PENDING",
                        text: t("shopee.InvoicePENDING"),
                        iconProps: { iconName: "ProductList" },
                        onClick: () => {
                            console.log("รอการชำระเงิน");
                            setOrderType({
                                key: "INVOICE_PENDING",
                                text: t("shopee.InvoicePENDING"),
                            });

                            sessionStorage.setItem("shopee-orderType-key", "INVOICE_PENDING");
                            sessionStorage.setItem("shopee-orderType-text", t("shopee.InvoicePENDING"));

                            setTimeRangeField({
                                key: "update_time",
                                text: t("shopee.updateTime"),
                            });

                            sessionStorage.setItem("shopee-timeRangeField-key", "update_time");
                            sessionStorage.setItem("shopee-timeRangeField-text", t("shopee.updateTime"));

                            setPrimarySort("updatedTime");
                            setIsSortedDescendingUpdatedTime(false);

                            sessionStorage.setItem("shopee-primarySort", "updatedTime");
                            sessionStorage.setItem("shopee-sort-updatedTime", "false");

                            sessionStorage.removeItem("shopee-sort-createdTime");
                            sessionStorage.removeItem("shopee-sort-number");
                            sessionStorage.removeItem("shopee-sort-customerName");
                            sessionStorage.removeItem("shopee-sort-vatTotal");
                            sessionStorage.removeItem("shopee-sort-grandTotal");

                            setLoadDataComplete(false);
                            setItems([]);
                            setGetNewDocuments(true);

                            setSearch("");

                            setCurrentPage(1);

                            documentDateTypeOptionsUpdate('INVOICE_PENDING')
                        },
                    },
                    {
                        key: "UNPAID",
                        text: t("shopee.UNPAID"),
                        iconProps: { iconName: "ProductList" },
                        onClick: () => {
                            console.log("ไม่ได้ชำระเงิน");
                            setOrderType({
                                key: "UNPAID",
                                text: t("shopee.UNPAID"),
                            });

                            sessionStorage.setItem("shopee-orderType-key", "UNPAID");
                            sessionStorage.setItem("shopee-orderType-text", t("shopee.UNPAID"));

                            setTimeRangeField({
                                key: "update_time",
                                text: t("shopee.updateTime"),
                            });

                            sessionStorage.setItem("shopee-timeRangeField-key", "update_time");
                            sessionStorage.setItem("shopee-timeRangeField-text", t("shopee.updateTime"));

                            setPrimarySort("updatedTime");
                            setIsSortedDescendingUpdatedTime(false);

                            sessionStorage.setItem("shopee-primarySort", "updatedTime");
                            sessionStorage.setItem("shopee-sort-updatedTime", "false");

                            sessionStorage.removeItem("shopee-sort-createdTime");
                            sessionStorage.removeItem("shopee-sort-number");
                            sessionStorage.removeItem("shopee-sort-customerName");
                            sessionStorage.removeItem("shopee-sort-vatTotal");
                            sessionStorage.removeItem("shopee-sort-grandTotal");

                            setLoadDataComplete(false);
                            setItems([]);
                            setGetNewDocuments(true);

                            setSearch("");

                            setCurrentPage(1);

                            documentDateTypeOptionsUpdate('UNPAID')
                        },
                    },
                    {
                        key: "IN_CANCEL",
                        text: t("shopee.InCANCEL"),
                        iconProps: { iconName: "ProductList" },
                        onClick: () => {
                            console.log("อยู่ระหว่างการยกเลิก");
                            setOrderType({
                                key: "IN_CANCEL",
                                text: t("shopee.InCANCEL"),
                            });

                            sessionStorage.setItem("shopee-orderType-key", "IN_CANCEL");
                            sessionStorage.setItem("shopee-orderType-text", t("shopee.InCANCEL"));

                            setTimeRangeField({
                                key: "update_time",
                                text: t("shopee.updateTime"),
                            });

                            sessionStorage.setItem("shopee-timeRangeField-key", "update_time");
                            sessionStorage.setItem("shopee-timeRangeField-text", t("shopee.updateTime"));

                            setPrimarySort("updatedTime");
                            setIsSortedDescendingUpdatedTime(false);

                            sessionStorage.setItem("shopee-primarySort", "updatedTime");
                            sessionStorage.setItem("shopee-sort-updatedTime", "false");

                            sessionStorage.removeItem("shopee-sort-createdTime");
                            sessionStorage.removeItem("shopee-sort-number");
                            sessionStorage.removeItem("shopee-sort-customerName");
                            sessionStorage.removeItem("shopee-sort-vatTotal");
                            sessionStorage.removeItem("shopee-sort-grandTotal");

                            setLoadDataComplete(false);
                            setItems([]);
                            setGetNewDocuments(true);

                            setSearch("");

                            setCurrentPage(1);

                            documentDateTypeOptionsUpdate('IN_CANCEL')
                        },
                    },
                    {
                        key: "CANCELLED",
                        text: t("shopee.CANCELLED"),
                        iconProps: { iconName: "ProductList" },
                        onClick: () => {
                            console.log("ยกเลิก");
                            setOrderType({
                                key: "CANCELLED",
                                text: t("shopee.CANCELLED"),
                            });

                            sessionStorage.setItem("shopee-orderType-key", "CANCELLED");
                            sessionStorage.setItem("shopee-orderType-text", t("shopee.CANCELLED"));

                            setTimeRangeField({
                                key: "update_time",
                                text: t("shopee.updateTime"),
                            });

                            sessionStorage.setItem("shopee-timeRangeField-key", "update_time");
                            sessionStorage.setItem("shopee-timeRangeField-text", t("shopee.updateTime"));

                            setPrimarySort("updatedTime");
                            setIsSortedDescendingUpdatedTime(false);

                            sessionStorage.setItem("shopee-primarySort", "updatedTime");
                            sessionStorage.setItem("shopee-sort-updatedTime", "false");

                            sessionStorage.removeItem("shopee-sort-createdTime");
                            sessionStorage.removeItem("shopee-sort-number");
                            sessionStorage.removeItem("shopee-sort-customerName");
                            sessionStorage.removeItem("shopee-sort-vatTotal");
                            sessionStorage.removeItem("shopee-sort-grandTotal");

                            setLoadDataComplete(false);
                            setItems([]);
                            setGetNewDocuments(true);

                            setSearch("");

                            setCurrentPage(1);

                            documentDateTypeOptionsUpdate('CANCELLED')
                        },
                    },
                ],
            },
        },
        {
            key: timeRangeField.key,
            // text: timeRangeField.text,
            text: timeRangeField.key === "update_time" ? t("shopee.updateTime") : timeRangeField.key === "create_time" ? t("shopee.createTime") : timeRangeField.text,
            cacheKey: "myCacheKey", // changing this key will invalidate this item's cache
            iconProps: { iconName: "CalendarSettings" },
            disabled: !loadDataComplete || isCreateDocument || search || orderType.key !== "all",
            subMenuProps: {
                items: [
                    {
                        key: "update_time",
                        text: t("shopee.updateTime"),
                        iconProps: { iconName: "CalendarSettings" },
                        onClick: () => {
                            console.log("วันที่อัปเดตสถานะ");
                            setTimeRangeField({
                                key: "update_time",
                                text: t("shopee.updateTime"),
                            });

                            sessionStorage.setItem("shopee-timeRangeField-key", "update_time");
                            sessionStorage.setItem("shopee-timeRangeField-text", t("shopee.updateTime"));

                            setPrimarySort("updatedTime");
                            setIsSortedDescendingUpdatedTime(false);

                            sessionStorage.setItem("shopee-primarySort", "updatedTime");
                            sessionStorage.setItem("shopee-sort-updatedTime", "false");

                            sessionStorage.removeItem("shopee-sort-createdTime");
                            sessionStorage.removeItem("shopee-sort-number");
                            sessionStorage.removeItem("shopee-sort-customerName");
                            sessionStorage.removeItem("shopee-sort-vatTotal");
                            sessionStorage.removeItem("shopee-sort-grandTotal");

                            setLoadDataComplete(false);
                            setItems([]);
                            setGetNewDocuments(true);

                            setSearch("");

                            setCurrentPage(1);
                        },
                    },
                    {
                        key: "create_time",
                        text: t("shopee.createTime"),
                        iconProps: { iconName: "CalendarSettings" },
                        onClick: () => {
                            console.log("วันที่สร้างเอกสาร");
                            setTimeRangeField({
                                key: "create_time",
                                text: t("shopee.createTime"),
                            });

                            sessionStorage.setItem("shopee-timeRangeField-key", "create_time");
                            sessionStorage.setItem("shopee-timeRangeField-text", t("shopee.createTime"));

                            setPrimarySort("createdTime");
                            setIsSortedDescendingCreatedTime(false);

                            sessionStorage.setItem("shopee-primarySort", "createdTime");
                            sessionStorage.setItem("shopee-sort-createdTime", "false");

                            sessionStorage.removeItem("shopee-sort-updatedTime");
                            sessionStorage.removeItem("shopee-sort-number");
                            sessionStorage.removeItem("shopee-sort-customerName");
                            sessionStorage.removeItem("shopee-sort-vatTotal");
                            sessionStorage.removeItem("shopee-sort-grandTotal");

                            setLoadDataComplete(false);
                            setItems([]);
                            setGetNewDocuments(true);

                            setSearch("");

                            setCurrentPage(1);
                        },
                    },
                ],
            },
        },
        {
            key: queryMonth.key,
            text: getDateText(queryMonth),
            iconProps: { iconName: "Calendar" },
            disabled: !loadDataComplete || isCreateDocument || search,
            subMenuProps: {
                items: [
                    {
                        key: "queryMonth",
                    },
                ],
                onRenderMenuList: () => {
                    return (
                        <div style={{ width: 220 }}>
                            <Stack>
                                <Stack horizontal styles={{ root: { height: 42, paddingTop: 10 } }}>
                                    <Stack styles={{ root: { width: "20%", justifyContent: "center", alignItems: "center" } }}>
                                        <Text>{t("sellSummary.fromRange")}</Text>
                                    </Stack>
                                    <Stack styles={{ root: { width: "80%", justifyContent: "center" } }}>
                                        <DatePicker
                                            styles={{ root: { width: "90%" } }}
                                            placeholder={t("shopee.pleaseSelect")}
                                            strings={DayPickerStrings}
                                            value={fromDate}
                                            maxDate={toDate}
                                            formatDate={onFormatDate}
                                            onSelectDate={(_date) => {
                                                setFromDate(_date);

                                                // let adjustDate = _date;

                                                // setFrom2(getFrom3(adjustDate.getDate(), adjustDate.getMonth(), adjustDate.getFullYear()));

                                                setTimeRangeError(false);
                                                if (Math.ceil(Math.abs(_date - toDate) / (1000 * 60 * 60 * 24)) > 14) setTimeRangeError(true);
                                            }}
                                        />
                                    </Stack>
                                </Stack>
                                <Stack horizontal styles={{ root: { height: 42, paddingTop: 10 } }}>
                                    <Stack styles={{ root: { width: "20%", justifyContent: "center", alignItems: "center" } }}>
                                        <Text>{t("sellSummary.toRange")}</Text>
                                    </Stack>
                                    <Stack styles={{ root: { width: "80%" } }}>
                                        <DatePicker
                                            styles={{ root: { width: "90%" } }}
                                            placeholder={t("shopee.pleaseSelect")}
                                            strings={DayPickerStrings}
                                            value={toDate}
                                            minDate={fromDate}
                                            formatDate={onFormatDate}
                                            onSelectDate={(_date) => {
                                                setToDate(_date);

                                                // let adjustDate = _date;

                                                // setTo2(getTo3(adjustDate.getDate(), adjustDate.getMonth(), adjustDate.getFullYear()));
                                                setTimeRangeError(false);
                                                if (Math.ceil(Math.abs(_date - fromDate) / (1000 * 60 * 60 * 24)) > 14) setTimeRangeError(true);
                                            }}
                                        />
                                    </Stack>
                                </Stack>
                                {timeRangeError ? (
                                    <Stack styles={{ root: { padding: "12px 0px 0px 12px" } }}>
                                        <Text styles={{ root: { fontSize: 12, color: "#A4262C" } }}>{t("sellSummary.timeRangeError2")}</Text>
                                    </Stack>
                                ) : null}
                                <Stack horizontal horizontalAlign="end" styles={{ root: { width: "92%", height: 52, padding: "10px 0px 10px 0px" } }}>
                                    <PrimaryButton
                                        text={t("sellSummary.search")}
                                        onClick={() => {
                                            if (!timeRangeError) {
                                                setQueryMonth({
                                                    type: "dateRange",
                                                    key: formatDate(fromDate) + ":" + formatDate(toDate),
                                                    text: formatDate(fromDate) + " - " + formatDate(toDate),
                                                });

                                                sessionStorage.setItem("shopee-queryMonth-key", formatDate(fromDate) + ":" + formatDate(toDate));
                                                sessionStorage.setItem("shopee-queryMonth-text", formatDate(fromDate) + " - " + formatDate(toDate));

                                                sessionStorage.setItem("shopee-from", getFrom(fromDate.getDate() - 15, fromDate.getMonth(), fromDate.getFullYear()));
                                                sessionStorage.setItem("shopee-to", getTo(toDate.getDate(), toDate.getMonth(), toDate.getFullYear()));

                                                setFrom2(getFrom2(fromDate.getDate(), fromDate.getMonth(), fromDate.getFullYear()));
                                                setTo2(getTo2(toDate.getDate(), toDate.getMonth(), toDate.getFullYear()));

                                                sessionStorage.setItem("shopee-from2", getFrom2(fromDate.getDate(), fromDate.getMonth(), fromDate.getFullYear()));
                                                sessionStorage.setItem("shopee-to2", getTo2(toDate.getDate(), toDate.getMonth(), toDate.getFullYear()));

                                                sessionStorage.setItem("shopee-fromDate", fromDate.toISOString());
                                                sessionStorage.setItem("shopee-toDate", toDate.toISOString());

                                                setSearch("");

                                                setLoadDataComplete(false);
                                                setItems([]);
                                                setGetNewDocuments(true);

                                                setCurrentPage(1);
                                            }
                                        }}
                                        style={{ width: "125px", letterSpacing: "1px" }}
                                    />
                                </Stack>
                            </Stack>
                        </div>
                    );
                },
            },
        },
        {
            key: "pageSize " + pageSize,
            text: pageSize + " " + t("shopee.documentPerPage"),
            cacheKey: "myCacheKey", // changing this key will invalidate this item's cache
            iconProps: { iconName: "NumberedList" },
            disabled: !loadDataComplete || isCreateDocument || search,
            subMenuProps: {
                items: [
                    {
                        key: "pageSize " + 10,
                        text: "10 " + t("shopee.documentPerPage"),
                        iconProps: { iconName: "NumberedList" },
                        onClick: () => {
                            console.log("10 ออเดอร์/หน้า");
                            setPageSize(10);
                            sessionStorage.setItem("shopee-pageSize", 10);

                            setSearch("");

                            setLoadDataComplete(false);
                            setItems([]);
                            setGetNewDocuments(true);

                            setCurrentPage(1);
                        },
                    },
                    {
                        key: "pageSize " + 20,
                        text: "20 " + t("shopee.documentPerPage"),
                        iconProps: { iconName: "NumberedList" },
                        onClick: () => {
                            console.log("20 ออเดอร์/หน้า");
                            setPageSize(20);
                            sessionStorage.setItem("shopee-pageSize", 20);

                            setSearch("");

                            setLoadDataComplete(false);
                            setItems([]);
                            setGetNewDocuments(true);

                            setCurrentPage(1);
                        },
                    },
                    {
                        key: "pageSize " + 30,
                        text: "30 " + t("shopee.documentPerPage"),
                        iconProps: { iconName: "NumberedList" },
                        onClick: () => {
                            console.log("30 ออเดอร์/หน้า");
                            setPageSize(30);
                            sessionStorage.setItem("shopee-pageSize", 30);

                            setSearch("");

                            setLoadDataComplete(false);
                            setItems([]);
                            setGetNewDocuments(true);

                            setCurrentPage(1);
                        },
                    },
                    {
                        key: "pageSize " + 40,
                        text: "40 " + t("shopee.documentPerPage"),
                        iconProps: { iconName: "NumberedList" },
                        onClick: () => {
                            console.log("40 ออเดอร์/หน้า");
                            setPageSize(40);
                            sessionStorage.setItem("shopee-pageSize", 40);

                            setSearch("");

                            setLoadDataComplete(false);
                            setItems([]);
                            setGetNewDocuments(true);

                            setCurrentPage(1);
                        },
                    },
                    {
                        key: "pageSize " + 50,
                        text: "50 " + t("shopee.documentPerPage"),
                        iconProps: { iconName: "NumberedList" },
                        onClick: () => {
                            console.log("50 ออเดอร์/หน้า");
                            setPageSize(50);
                            sessionStorage.setItem("shopee-pageSize", 50);

                            setSearch("");

                            setLoadDataComplete(false);
                            setItems([]);
                            setGetNewDocuments(true);

                            setCurrentPage(1);
                        },
                    },
                ],
            },
        },
        {
            key: currentPage,
            text: t("shopee.page") + " " + currentPage,
            iconProps: { iconName: "Page" },
            disabled: !loadDataComplete || isCreateDocument || search,
            subMenuProps: {
                items: pageDropdown,
            },
        },
        {
            key: "refresh",
            text: t("shopee.refresh"),
            iconProps: { iconName: "Refresh" },
            disabled: !loadDataComplete || isCreateDocument || search,
            onClick: () => {
                console.log("Refresh");

                setSearch("");

                setLoadDataComplete(false);
                setItems([]);
                setGetNewDocuments(true);

                setCurrentPage(1);

                setIsCreateDocumentComplete(false);
                setSelectedItems([]);
            },
        },
    ];

    const overflowItems = [
        {
            key: "disconnect",
            text: t("shopee.disconnect"),
            onClick: () => {
                console.log("Shopee disconnect!");
                setDialogConfirm((prev) => ({
                    ...prev,
                    isClick: true,
                    header: t("shopee.disconnect"),
                    message: t("shopee.disconnectConfirm"),
                    onConfirm: () => {
                        disconnect(true);
                        setDialogConfirm((prev) => ({
                            ...prev,
                            isClick: false,
                        }));
                    },
                    onCancel: () =>
                        setDialogConfirm((prev) => ({
                            ...prev,
                            isClick: false,
                        })),
                }));
            },
            iconProps: { iconName: "PlugDisconnected" },
        },
    ];

    const padLeadingZeros = (num, size) => {
        var s = num + "";
        while (s.length < size) s = "0" + s;
        return s;
    };

    const disconnect = (_confirm) => {
        let confirm = _confirm;

        if (confirm) {
            setIsLoading(true);

            instance.acquireTokenSilent({ ...silentRequest, account: account }).then(
                (tokenResponse) => {
                    axios
                        .patch(
                            API_URL + "/sources",
                            {
                                source: "shopee",
                                replace: "/disabled",
                                value: true,
                            },
                            {
                                headers: {
                                    Authorization: "Bearer " + tokenResponse.accessToken,
                                    "cid": companyId
                                },
                            }
                        )
                        .then((response) => {
                            console.log("disconnect: ", response);

                            history.push({
                                pathname: "/etax/sources/shopee/connect",
                                state: {},
                            });

                            setIsLoading(false);
                        })
                        .catch((error) => {
                            //Acquire token silent failure, and send an interactive request
                            console.log(error);
                            instance.acquireTokenRedirect(silentRequest);
                        });
                },
                (error) => {
                    console.log(error);
                }
            );
        }
    };

    const createSelectedDocuments = async (documentType) => {
        let _items = selection.getItems();

        let postUrl = "";

        switch (documentType) {
            case "RECEIPT-TAXINVOICE":
                postUrl = API_URL + "/etax/documents/receipts-taxinvoices/pdf-xml";
                break;
            case "RECEIPT-TAXINVOICE-ABB":
                postUrl = API_URL + "/etax/documents/receipts-taxinvoices-abb/pdf";
                break;
            case "RECEIPT":
                postUrl = API_URL + "/etax/documents/receipts/pdf-xml";
                break;
            case "INVOICE-TAXINVOICE":
                postUrl = API_URL + "/etax/documents/invoices-taxinvoices/pdf-xml";
                break;
            case "DELIVERYORDER-TAXINVOICE":
                postUrl = API_URL + "/etax/documents/deliveryorders-taxinvoices/pdf-xml";
                break;
            case "TAXINVOICE":
                postUrl = API_URL + "/etax/documents/taxinvoices/pdf-xml";
                break;
            default:
                break;
        }

        if (postUrl) {
            let _countingNumberLength = countingNumber.length;
            let _countingNumber = Number(countingNumber);

            await instance
                .acquireTokenSilent({ ...silentRequest, account: account })
                .then(async (tokenResponse) => {
                    for (let i = 0; i < selectedItems.length; i++) {
                        console.log("create selected document: ", selectedItems[i]);

                        for (let j = 0; j < _items.length; j++) {
                            if (_items[j].key === selectedItems[i].key) {
                                console.log("item key: ", _items[j].key);
                                _items[j].status = "processing";
                                console.log("items: ", _items);
                                setItems([..._items]);

                                console.log("item: ", _items[j]);

                                const [response] = await Promise.all([
                                    axios
                                        .get(`${API_URL}/sources/shopee/escrowdetails?order_sn=${selectedItems[i].key}&shop_id=${shopId}`, {
                                            headers: {
                                                Authorization: "Bearer " + tokenResponse.accessToken,
                                                "Content-Type": "application/json",
                                                "cid": companyId
                                            },
                                        })
                                        .catch((error) => console.log(error)),
                                ]);

                                const orderDetail = response.data;

                                let _discount = orderDetail.voucher_from_seller;
                                let _amountTotal = 0;
                                let _vatTotal = 0;
                                let _grandTotal = 0;

                                const _orderItems = [];
                                orderDetail.items.forEach((item, index) => {
                                    let _variation = ''
                                    if (item.model_name) {
                                        _variation = ` (${item.model_name})`
                                    }

                                    let _sku = ""
                                    if (item.model_sku) {
                                        _sku = item.model_sku
                                    } else {
                                        _sku = item.item_sku
                                    }

                                    if (registerVat) {
                                        _orderItems.push({
                                            number: index + 1 + "",
                                            description: item.item_name + _variation,
                                            quantity: item.quantity_purchased,
                                            unitCode: "",
                                            unitName: "-",
                                            price: item.original_price / item.quantity_purchased,
                                            discount: item.seller_discount / item.quantity_purchased,
                                            percentVat: percentVat,
                                            percentVatText: percentVatText,
                                            total: item.discounted_price + item.shopee_discount,
                                            sku: _sku
                                        });
                                    } else {
                                        _orderItems.push({
                                            number: index + 1 + "",
                                            description: item.item_name + _variation,
                                            quantity: item.quantity_purchased,
                                            unitCode: "",
                                            unitName: "-",
                                            price: item.original_price / item.quantity_purchased,
                                            discount: item.seller_discount / item.quantity_purchased,
                                            percentVat: zeroVat,
                                            percentVatText: zeroVatText,
                                            total: item.discounted_price + item.shopee_discount,
                                            sku: _sku
                                        });
                                    }
                                });

                                if (showShippingFee) {
                                    _orderItems.push({
                                        number: (_orderItems.length + 1) + "",
                                        description: "ค่าขนส่ง",
                                        quantity: 1,
                                        unitCode: "",
                                        unitName: "-",
                                        price: orderDetail.buyer_paid_shipping_fee > 0 ? orderDetail.buyer_paid_shipping_fee : 0,
                                        discount: 0,
                                        percentVat: registerVat ? percentVat : zeroVat,
                                        percentVatText: registerVat ? percentVatText : zeroVatText,
                                        total: orderDetail.buyer_paid_shipping_fee > 0 ? orderDetail.buyer_paid_shipping_fee : 0,
                                        sku: ""
                                    });
                                }

                                if (includeVat) {
                                    _grandTotal = _orderItems.reduce((accumulator, item) => accumulator + item.total, 0) - _discount;
                                    _vatTotal = Math.round(((_grandTotal * percentVat) / (100 + percentVat)) * 100) / 100;
                                    _amountTotal = Math.round((_grandTotal - _vatTotal) * 100) / 100;
                                } else {
                                    _amountTotal = _orderItems.reduce((accumulator, item) => accumulator + item.total, 0) - _discount;
                                    _vatTotal = Math.round(((_amountTotal * percentVat) / 100) * 100) / 100;
                                    _grandTotal = Math.round((_amountTotal + _vatTotal) * 100) / 100;
                                }

                                let _date = new Date(selectedItems[i].updatedTimestamp * 1000);
                                if (selectedDocumentDateType === "createdTime") {
                                    _date = new Date(selectedItems[i].createdTimestamp * 1000);
                                } else if (selectedDocumentDateType === "paidTime") {
                                    if (selectedItems[i].paidTimestamp) {
                                        _date = new Date(selectedItems[i].paidTimestamp * 1000);
                                    } else {
                                        _date = new Date(selectedItems[i].createdTimestamp * 1000);
                                    }
                                } else if (selectedDocumentDateType === "pickupDoneTime") {
                                    if (selectedItems[i].pickupDoneTimestamp) {
                                        _date = new Date(selectedItems[i].pickupDoneTimestamp * 1000);
                                    } else {
                                        _date = new Date(selectedItems[i].createdTimestamp * 1000);
                                    }
                                }

                                let _dateBE = `${_date.getDate()}`.padStart(2, "0") + "/" + `${_date.getMonth() + 1}`.padStart(2, "0") + "/" + (_date.getFullYear() + 543);
                                let _dateCE = `${_date.getDate()}`.padStart(2, "0") + "/" + `${_date.getMonth() + 1}`.padStart(2, "0") + "/" + _date.getFullYear();

                                let _phoneNumber = selectedItems[i].customerPhone;
                                let _email = selectedItems[i].customerEmail;

                                let _taxNumberType = selectedItems[i].taxNumberType;
                                let _taxId = selectedItems[i].customerTaxId;
                                let _branchNumber = selectedItems[i].branchNumber;
                                let _branchText = selectedItems[i].branchText;

                                if (_phoneNumber.includes("*")) {
                                    _phoneNumber = "";
                                }
                                if (_email.includes("*")) {
                                    _email = "";
                                }

                                if (_taxId.includes("*") || !_taxId) {
                                    _taxNumberType = "OTHR";
                                    _taxId = "N/A";
                                    _branchNumber = "";
                                    _branchText = "";
                                }

                                let _customer = {
                                    addressLineOne: selectedItems[i].customerAddress,
                                    addressLineTwo: "",
                                    branchNumber: _branchNumber,
                                    branchText: _branchText,
                                    email: _email,
                                    name: selectedItems[i].customerName,
                                    phone: _phoneNumber,
                                    phoneCountryCode: "+66",
                                    postcode: selectedItems[i].customerPostcode,
                                    taxId: _taxId,
                                    taxNumberType: _taxNumberType,
                                    language: "th",
                                    countryCode: "TH",
                                    countryName: "ไทย",
                                    unstructure: true,
                                };

                                let _vatPriceTotal = 0;
                                let _zeroVatPriceTotal = 0;
                                let _nonVatPriceTotal = 0;

                                _orderItems.forEach((item) => {
                                    if (item.percentVat === percentVat) {
                                        _vatPriceTotal = _vatPriceTotal + item.total;
                                    } else if (item.percentVat === zeroVat) {
                                        _zeroVatPriceTotal = _zeroVatPriceTotal + item.total;
                                    }
                                });

                                if (_vatPriceTotal > 0) {
                                    _vatPriceTotal = _amountTotal;
                                } else if (_zeroVatPriceTotal > 0) {
                                    _zeroVatPriceTotal = _amountTotal;
                                }

                                console.log("_items: ", selectedItems[i].items);
                                console.log("_customer: ", _customer);
                                console.log(_date);
                                console.log(_dateBE);
                                console.log(_dateCE);

                                let _number = prefixNumber + padLeadingZeros(Number(_countingNumber), _countingNumberLength);
                                _countingNumber = padLeadingZeros(Number(_countingNumber) + 1, _countingNumberLength);
                                setCountingNumber(_countingNumber);

                                const promise_PDF_XML = await new Promise(async (resolve) => {
                                    await axios
                                        .post(
                                            postUrl,
                                            {
                                                amountTotal: _amountTotal,
                                                company: {
                                                    ...company,
                                                    includeVat: includeVat,
                                                },
                                                reference: selectedItems[i].orderId,
                                                customer: _customer,
                                                date: _date,
                                                dateBE: _dateBE,
                                                dateCE: _dateCE,
                                                discount: _discount,
                                                grandTotal: _grandTotal,
                                                nonVatPriceTotal: _nonVatPriceTotal,
                                                zeroVatPriceTotal: _zeroVatPriceTotal,
                                                vatPriceTotal: _vatPriceTotal,
                                                items: _orderItems,
                                                percentVat: registerVat ? percentVat : 0,
                                                vatTotal: _vatTotal,
                                                reIssue: false,
                                                includeVat: includeVat,
                                                number: _number,
                                                note: selectedItems[i].note,
                                                receivedBy: createdBy,
                                                createdBy: createdBy,
                                            },
                                            {
                                                headers: {
                                                    Authorization: "Bearer " + tokenResponse.accessToken,
                                                    "cid": companyId
                                                },
                                            }
                                        )
                                        .then(
                                            async (response) => {
                                                console.log("Create document response: ", response);

                                                let count = 0;

                                                let interval = await setInterval(async () => {
                                                    await axios
                                                        .get(`${API_URL}/etax/jobs/${response.data.id}`, {
                                                            headers: {
                                                                Authorization: "Bearer " + tokenResponse.accessToken,
                                                                "Content-Type": "application/json",
                                                                "cid": companyId
                                                            },
                                                        })
                                                        .then((jobsResponse) => {
                                                            count += 1;
                                                            console.log("Jobs response: ", j, jobsResponse.data.status, count);

                                                            if (jobsResponse.data.status === "complete") {
                                                                _items[j].status = "successful";
                                                                _items[j].etaxCreated = true;

                                                                setItems([..._items]);

                                                                clearInterval(interval);

                                                                resolve(true);
                                                            } else if (count >= 300) {
                                                                _items[j].status = "fail";

                                                                setItems([..._items]);

                                                                clearInterval(interval);

                                                                resolve(false);
                                                            }
                                                        });
                                                }, 1000);
                                            },
                                            (error) => {
                                                console.log("Error Create document response: ", error);
                                                _items[j].status = "fail";
                                                setItems([..._items]);
                                                resolve(false);
                                            }
                                        );
                                }); //promise

                                Promise.all([promise_PDF_XML]).then((value) => {
                                    console.log(i, value);
                                });
                            } //if
                        } //for j
                    } //for i
                })
                .catch((error) => {
                    //Acquire token silent failure, and send an interactive request
                    console.log(error);
                    instance.acquireTokenRedirect(silentRequest);
                });

            setIsCreateDocumentComplete(true);
            setIsCreateDocument(false);
        }

        setIsCreateDocumentComplete(true);
        setIsCreateDocument(false);
    };

      async function createSelectedDocumentsOnClick(documentType) {
        if (selectedItems.some(_document => _document.etaxCreated)) {
            setDialogConfirm((prev) => ({
              ...prev,
              isClick: true,
              header: t("shopee.etaxCreatedOrdersnHeader"),
              message: '',
              maxWidth: 680,
              content: 
                <Stack tokens={{ childrenGap: 10}}>
                    <>
                      <label>{t("shopee.etaxCreatedOrdersn")}</label>
                      <div style={styles.scrollable}>
                        <table style={styles.table}>
                          <thead>
                            <tr>
                              <th style={styles.th}>{t("shopee.ordersn")}</th>
                            </tr>
                          </thead>
                          <tbody>
                            {selectedItems.filter(_doc => _doc.etaxCreated).map(_doc => (
                              <tr key={_doc.key}>
                                <td style={styles.td}>{_doc.orderId}</td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
    
                      </div>
                    </>
    
                  <label>{t("shopee.proceedMessage")} {<strong>"{t("documents.confirm")}"</strong>}</label>
                </Stack>,
              onConfirm: async () => {
                setDialogConfirm((prev) => ({
                  isClick: false,
                  header: '',
                  message: '',
                  onConfirm: null,
                  onCancel: null,
                }));
    
                setIsCreateDocument(true);
                await createSelectedDocuments(documentType);
              },
              onCancel: () =>
                setDialogConfirm((prev) => ({
                  isClick: false,
                  header: '',
                  message: '',
                  onConfirm: null,
                  onCancel: null,
                })),
            }));
        } else {
          setIsCreateDocument(true);
          await createSelectedDocuments(documentType);
    
        }
        
      }

    useEffect(() => {
        documentDateTypeOptionsUpdate(orderType.key)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [i18n.language]);

    useEffect(() => {
        console.log("userEffect initial call!");

        // const init = async () => {

            setIsLoading(true);

            if (localStorage.getItem("showShippingFee") === "no") {
                setShowShippingFee(false);
            } else {
                setShowShippingFee(true);
            }

            instance
                .acquireTokenSilent({ ...silentRequest, account: account })
                .then((tokenResponse) => {
                    axios
                        .get(API_URL + "/etax/companies", {
                            headers: {
                                Authorization: "Bearer " + tokenResponse.accessToken,
                                "cid": companyId
                            },
                        })
                        .then(
                            (response) => {
                                if (response.data[0]) {
                                    if (companyId && response.data.find(_company => _company.id === companyId)) {
                                        const _company = response.data.find(_company => _company.id === companyId)
                                    
                                        setCompany(_company);
    
                                        if (_company.registerVat) {
                                            setIncludeVat(true);
                                            setRegisterVat(true);
                                        } else {
                                            setIncludeVat(false);
                                            setRegisterVat(false);
                                        }
                                    } else {
                                        setCompany(response.data[0]);
    
                                        if (response.data[0].registerVat) {
                                            setIncludeVat(true);
                                            setRegisterVat(true);
                                        } else {
                                            setIncludeVat(false);
                                            setRegisterVat(false);
                                        }

                                    }

                                    setGetNewDocuments(true);
                                } else {
                                    history.push({
                                        pathname: "/etax/companies/profiles",
                                        state: {},
                                    });
                                }

                                setIsLoading(false);
                            },
                            (error) => {
                                console.log(error);
                            }
                        );
                })
                .catch((error) => {
                    //Acquire token silent failure, and send an interactive request
                    console.log(error);
                    instance.acquireTokenRedirect(silentRequest);
                });

            instance
                .acquireTokenSilent({ ...silentRequest, account: account })
                .then((tokenResponse) => {
                    axios
                        .get(API_URL + "/sources/shopee", {
                            headers: {
                                Authorization: "Bearer " + tokenResponse.accessToken,
                                "cid": companyId
                            },
                        })
                        .then(
                            (response) => {
                                console.log("shopee source response", response);

                                if (response.data?.automation?.enable) {
                                    // setEnableAutomation(response.data.automation.enable);
                                    // setTriggerStatus(_triggerStatus);
                                    // setReceiptTaxInvoice(_receiptTaxInvoice);
                                    // setSendEmail(_sendEmail);
                                    // setShowShippingFee(_showShippingFee);
                                    // setOnlyBillingAddress(_onlyBillingAddress);
                                }

                                setShopId(response.data.shopId);

                                setGetNewDocuments(true);

                                setIsLoading(false);
                                

                            },
                            (error) => {
                                console.log(error);
                            }
                        );
                })
                .catch((error) => {
                    //Acquire token silent failure, and send an interactive request
                    console.log(error);
                    instance.acquireTokenRedirect(silentRequest);
                });

        // }

        // init();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (getNewDocuments && (shopId !== 0)) {
            if (search) {
                instance
                    .acquireTokenSilent({ ...silentRequest, account: account })
                    .then(async (tokenResponse) => {
                        const _docs = [];

                        const [response, documentsResponse] = await Promise.all([
                            axios
                                .get(API_URL + "/sources/shopee/orderdetails", {
                                    headers: {
                                        Authorization: "Bearer " + tokenResponse.accessToken,
                                        "cid": companyId
                                    },
                                    params: {
                                        order_sn_list: search + ",",
                                        shop_id: shopId,
                                    },
                                })
                                .catch((error) => console.log(error)),

                            axios
                                .get(`${API_URL}/documents/?referenceList=${search}`, {
                                    headers: {
                                        Authorization: "Bearer " + tokenResponse.accessToken,
                                        "cid": companyId
                                    },
                                })
                                .catch((error) => console.log(error)),
                        ]);

                        if (!response.data.error) {
                            console.log("response orderdetails:", response);

                            response.data.order_list.forEach((order) => {
                                let createdTime = timestamp_toDate(order.create_time);
                                let createdTimestamp = order.create_time;

                                let updatedTime = timestamp_toDate(order.update_time);
                                let updatedTimestamp = order.update_time;

                                let paidTime = timestamp_toDate(order.pay_time);
                                let paidTimestamp = order.pay_time;

                                let pickupDoneTime = timestamp_toDate(order.pickup_done_time);
                                let pickupDoneTimestamp = order.pickup_done_time;

                                let _items = [];
                                let _customerPhone = "";
                                let _amountTotal = 0;
                                let _vatTotal = 0;
                                let _vatPriceTotal = 0;
                                let _zeroVatPriceTotal = 0;
                                let _nonVatPriceTotal = 0;
                                let _grandTotal = 0;
                                let _taxId = "";
                                let _address = "";

                                let _taxNumberType = "";
                                let _branchNumber = "";
                                let _branchText = "";

                                let _addressLineOne = "";
                                let _postcode = "";

                                let _email = "";
                                let _phoneNumber = "";

                                let _name = "";
                                let _isRequest = false

                                if (order.buyer_invoice_info?.invoice_type) {
                                    _isRequest = true
                                    const invoiceInfo = order.buyer_invoice_info;

                                    if (invoiceInfo.invoice_type === "company") {
                                        _taxNumberType = "TXID";

                                        if (invoiceInfo.invoice_detail.company_head_office === "yes") {
                                            _branchNumber = "00000";
                                            _branchText = "สำนักงานใหญ่";
                                        } else {
                                            _branchNumber = invoiceInfo.invoice_detail.company_branch_id.padStart(5, "0");
                                            _branchText = "สาขาที่ " + invoiceInfo.invoice_detail.company_branch_id;
                                        }

                                        _taxId = invoiceInfo.invoice_detail.company_tax_id;

                                        const { 
                                            company_state, 
                                            company_district, 
                                            company_town, 
                                            company_postcode, 
                                            company_detailed_address, 
                                        } = invoiceInfo.invoice_detail.company_address_breakdown;

                                        _postcode = company_postcode;

                                        _addressLineOne = company_detailed_address + " " + company_town + " " + company_district + " " + company_state;

                                        _email = invoiceInfo.invoice_detail.company_email;
                                        _phoneNumber = invoiceInfo.invoice_detail.company_phone_number;
                                        _name = invoiceInfo.invoice_detail.company_name;
                                    } else {
                                        _taxNumberType = "NIDN";
                                        _taxId = invoiceInfo.invoice_detail.tax_id;

                                        const { 
                                            state, 
                                            district, 
                                            town, 
                                            postcode, 
                                            detailed_address, 
                                        } = invoiceInfo.invoice_detail.address_breakdown;

                                        _postcode = postcode;
                                        _addressLineOne = detailed_address + " " + town + " " + district + " " + state;
                                        _email = invoiceInfo.invoice_detail.email;
                                        _phoneNumber = invoiceInfo.invoice_detail.phone_number;
                                        _name = invoiceInfo.invoice_detail.name;
                                    }
                                } else {
                                    if (order.recipient_address && order.recipient_address !== null && order.recipient_address !== undefined) {
                                        _customerPhone = order.recipient_address.phone.replace("66", "0");
                                        _address = order.recipient_address.full_address;
                                        _postcode = order.recipient_address.zipcode

                                        if (_postcode.includes("*")) {
                                            _postcode = "00000"
                                        }

                                        if (_address.includes(order.recipient_address.zipcode) && order.recipient_address.zipcode !== "") {
                                            // let replace = new RegExp(" " + order.recipient_address.zipcode, "g");
                                            if (!order.recipient_address.zipcode.includes("*")) {
                                                _address = _address.replace(order.recipient_address.zipcode, "");
                                            }
                                        }
                                    }
                                    if (order.invoice_data !== null) {
                                        _taxId = order.invoice_data.tax_code;
                                    }
                                }

                                if (order.item_list) {
                                    if (registerVat) {
                                        order.item_list.forEach((item, index) => {
                                            let item_obj = {
                                                number: index + 1 + "",
                                                description: item.item_name,
                                                quantity: item.model_quantity_purchased,
                                                unitCode: "",
                                                unitName: "-",
                                                price: item.model_original_price,
                                                discount: item.model_original_price - item.model_discounted_price,
                                                percentVat: percentVat,
                                                percentVatText: percentVatText,
                                                total: item.model_discounted_price * item.model_quantity_purchased,
                                            };

                                            _items.push(item_obj);
                                        });

                                        if (showShippingFee) {
                                            _items.push({
                                                number: order.item_list.length + 1 + "",
                                                description: "ค่าขนส่ง",
                                                quantity: 1,
                                                unitCode: "",
                                                unitName: "-",
                                                price: order.estimated_shipping_fee,
                                                discount: 0,
                                                percentVat: percentVat,
                                                percentVatText: percentVatText,
                                                total: order.estimated_shipping_fee,
                                            });
                                        }

                                        if (includeVat) {
                                            for (let i = 0; i < _items.length; i++) {
                                                _vatPriceTotal = _vatPriceTotal + _items[i]["total"];
                                                _grandTotal = _grandTotal + _items[i]["total"];
                                            }

                                            _vatPriceTotal = Math.round(_vatPriceTotal * 100) / 100;
                                            _grandTotal = Math.round(_grandTotal * 100) / 100;

                                            _vatTotal = Math.round(((_vatPriceTotal * percentVat) / (100 + percentVat)) * 100) / 100 + Math.round(((_zeroVatPriceTotal * zeroVat) / (100 + zeroVat)) * 100) / 100;

                                            _amountTotal = _grandTotal - _vatTotal;
                                            _vatPriceTotal = _vatPriceTotal - Math.round(((_vatPriceTotal * percentVat) / (100 + percentVat)) * 100) / 100;
                                        } else {
                                            for (let i = 0; i < _items.length; i++) {
                                                _vatPriceTotal = _vatPriceTotal + _items[i]["total"];
                                                _amountTotal = _amountTotal + _items[i]["total"];
                                            }

                                            _vatPriceTotal = Math.round(_vatPriceTotal * 100) / 100;
                                            _amountTotal = Math.round(_amountTotal * 100) / 100;

                                            _vatTotal = Math.round(((_vatPriceTotal * percentVat) / (100 + percentVat)) * 100) / 100 + Math.round(((_zeroVatPriceTotal * zeroVat) / (100 + zeroVat)) * 100) / 100;

                                            _grandTotal = _amountTotal + _vatTotal;
                                        }
                                    } else {
                                        order.item_list.forEach((item, index) => {
                                            let item_obj = {
                                                number: index + 1 + "",
                                                description: item.item_name,
                                                quantity: item.model_quantity_purchased,
                                                unitCode: "",
                                                unitName: "-",
                                                price: item.model_original_price,
                                                discount: item.model_original_price - item.model_discounted_price,
                                                percentVat: zeroVat,
                                                percentVatText: zeroVatText,
                                                total: item.model_discounted_price * item.model_quantity_purchased,
                                            };

                                            _items.push(item_obj);
                                        });

                                        if (showShippingFee) {
                                            _items.push({
                                                number: order.item_list.length + 1 + "",
                                                description: "ค่าขนส่ง",
                                                quantity: 1,
                                                unitCode: "",
                                                unitName: "-",
                                                price: order.estimated_shipping_fee,
                                                discount: 0,
                                                percentVat: zeroVat,
                                                percentVatText: zeroVatText,
                                                total: order.estimated_shipping_fee,
                                            });
                                        }

                                        for (let i = 0; i < _items.length; i++) {
                                            _zeroVatPriceTotal = _zeroVatPriceTotal + _items[i]["total"];
                                            _amountTotal = _amountTotal + _items[i]["total"];
                                            _grandTotal = _grandTotal + _items[i]["total"];
                                        }
                                    }
                                }

                                if (_phoneNumber.includes("*")) {
                                    _phoneNumber = "";
                                }

                                if (_customerPhone.includes("*")) {
                                    _customerPhone = "";
                                }

                                if (_email.includes("*")) {
                                    _email = "";
                                }

                                if (_taxId.includes("*") || !_taxId) {
                                    _taxNumberType = "OTHR";
                                    _taxId = "N/A";
                                    _branchNumber = "";
                                    _branchText = "";
                                }

                                _docs.push({
                                    key: order.order_sn,
                                    createdTime: createdTime,
                                    createdTimestamp: createdTimestamp,
                                    updatedTime: updatedTime,
                                    updatedTimestamp: updatedTimestamp,
                                    paidTime: paidTime,
                                    paidTimestamp: paidTimestamp,
                                    pickupDoneTime: pickupDoneTime,
                                    pickupDoneTimestamp: pickupDoneTimestamp,
                                    orderId: order.order_sn,
                                    customerName: _name || order.recipient_address.name,
                                    customerAddress: _addressLineOne || _address,
                                    customerPostcode: _postcode || order.recipient_address.zipcode,
                                    customerPhone: _phoneNumber || _customerPhone,
                                    customerEmail: _email,
                                    taxNumberType: _taxNumberType,
                                    branchNumber: _branchNumber,
                                    branchText: _branchText,
                                    items: _items,
                                    amountTotal: _amountTotal,
                                    vatTotal: _vatTotal,
                                    grandTotal: _grandTotal,
                                    nonVatPriceTotal: _nonVatPriceTotal,
                                    zeroVatPriceTotal: _zeroVatPriceTotal,
                                    vatPriceTotal: _vatPriceTotal,
                                    customerTaxId: _taxId,
                                    buyerPayment: order.total_amount,
                                    note: order.note || "",
                                    etaxCreated: documentsResponse.data.some(_doc => _doc.data.reference === order.order_sn),
                                    shippingFee: showShippingFee ? order.estimated_shipping_fee : 0,
                                    isRequest: _isRequest
                                });
                            });

                            console.log(_docs);
                            setItems(_docs);
                        } else {
                            if (response.data.error === "error_auth") {
                                console.log(response.data.error);
                                disconnect(true);
                            } else {
                                console.log(response.data.error);

                                setItems([]);

                                setLoadDataComplete(true);
                                setGetNewDocuments(false);
                                setIsCreateDocumentComplete(false);
                            }
                        }

                        setLoadDataComplete(true);
                        setGetNewDocuments(false);
                        setIsCreateDocumentComplete(false);
                    })
                    .catch((error) => {
                        //Acquire token silent failure, and send an interactive request
                        console.log("error search", error);
                        // instance.acquireTokenRedirect(silentRequest);

                        setItems([]);
                        setLoadDataComplete(true);
                        setGetNewDocuments(false);
                        setIsCreateDocumentComplete(false);
                    });
            } else {
                console.log("getNewOrder Running!!!");

                instance
                    .acquireTokenSilent({ ...silentRequest, account: account })
                    .then(async (tokenResponse) => {
                        const [response] = await Promise.all([
                            axios
                                .get(API_URL + "/sources/shopee/orders", {
                                    headers: {
                                        Authorization: "Bearer " + tokenResponse.accessToken,
                                        "cid": companyId
                                    },
                                    params: {
                                        time_from: from2,
                                        time_to: to2,
                                        page: currentPage,
                                        page_size: pageSize,
                                        order_status: orderType.key,
                                        time_range_field: timeRangeField.key,
                                        shop_id: shopId,
                                        // shop_id: 36871707,
                                    },
                                })
                                .catch((error) => console.log(error)),
                        ]);

                        console.log("response data: ", response);


                        if (response && response.data && !response.data.error) {
                            let order_list = response.data.order_list;

                            if (search) {
                                order_list = response.data.order_list.filter((order) => order.includes(search));
                            }

                            // if (currentPage > Math.ceil(order_list.length / pageSize)) {
                            //   setCurrentPage(1);
                            // }

                            setPageData(paginate(pageSize * 100, 1, pageSize, 10));

                            let order_sn_list = "";
                            const _docs = [];

                            if (order_list.length > 0) {
                                if (primarySort === "number") {
                                    console.log("number", true);
                                    if (isSortedDescendingNumber) {
                                        order_list.sort((a, b) => {
                                            return /[A-Za-z]/.test(b) - /[A-Za-z]/.test(a) || (b.toUpperCase() < a.toUpperCase() ? -1 : b.toUpperCase() > a.toUpperCase() ? 1 : 0);
                                        });
                                    } else {
                                        order_list.sort((a, b) => {
                                            return /[A-Za-z]/.test(a) - /[A-Za-z]/.test(b) || (a.toUpperCase() < b.toUpperCase() ? -1 : a.toUpperCase() > b.toUpperCase() ? 1 : 0);
                                        });
                                    }
                                }

                                // for (let i = (currentPage - 1) * pageSize; i < Math.min(currentPage * pageSize, order_list.length); i++) {
                                //   order_sn_list += order_list[i] + ',';
                                // }

                                order_list.forEach((orderNumber) => (order_sn_list = order_sn_list + orderNumber + ","));

                                const _referenceList = order_list.join(",")

                                const [orderDetailsResponse, documentsResponse] = await Promise.all([
                                    axios
                                    .get(API_URL + "/sources/shopee/orderdetails", {
                                        headers: {
                                            Authorization: "Bearer " + tokenResponse.accessToken,
                                            "cid": companyId
                                        },
                                        params: {
                                            order_sn_list: order_sn_list,
                                            shop_id: shopId,
                                        },
                                    }),
        
                                    axios
                                        .get(`${API_URL}/documents/?referenceList=${_referenceList}`, {
                                            headers: {
                                                Authorization: "Bearer " + tokenResponse.accessToken,
                                                "cid": companyId
                                            },
                                        })
                                        .catch((error) => console.log(error)),
                                ]);

                                console.log("documentsResponse", documentsResponse)

                                if (!orderDetailsResponse.data.error) {
                                    console.log("orderDetailsResponse orderdetails:", orderDetailsResponse);

                                    orderDetailsResponse.data.order_list.forEach((order) => {
                                        let createdTime = timestamp_toDate(order.create_time);
                                        let createdTimestamp = order.create_time;

                                        let updatedTime = timestamp_toDate(order.update_time);
                                        let updatedTimestamp = order.update_time;

                                        let paidTime = timestamp_toDate(order.pay_time);
                                        let paidTimestamp = order.pay_time;

                                        let pickupDoneTime = timestamp_toDate(order.pickup_done_time);
                                        let pickupDoneTimestamp = order.pickup_done_time;

                                        let _items = [];
                                        let _customerPhone = "";
                                        let _amountTotal = 0;
                                        let _vatTotal = 0;
                                        let _grandTotal = 0;
                                        let _vatPriceTotal = 0;
                                        let _zeroVatPriceTotal = 0;
                                        let _nonVatPriceTotal = 0;
                                        let _taxId = "";
                                        let _address = "";

                                        let _taxNumberType = "";
                                        let _branchNumber = "";
                                        let _branchText = "";

                                        let _addressLineOne = "";
                                        let _postcode = "";

                                        let _email = "";
                                        let _phoneNumber = "";

                                        let _name = "";
                                        let _isRequest = false

                                        if (order.buyer_invoice_info?.invoice_type) {
                                            _isRequest = true
                                            const invoiceInfo = order.buyer_invoice_info;

                                            if (invoiceInfo.invoice_type === "company") {
                                                _taxNumberType = "TXID";

                                                if (invoiceInfo.invoice_detail.company_head_office === "yes") {
                                                    _branchNumber = "00000";
                                                    _branchText = "สำนักงานใหญ่";
                                                } else {
                                                    // if (invoiceInfo.invoice_detail.company_branch_id) {
                                                    _branchNumber = invoiceInfo.invoice_detail.company_branch_id.padStart(5, "0");
                                                    _branchText = "สาขาที่ " + invoiceInfo.invoice_detail.company_branch_id;
                                                    // }
                                                }

                                                _taxId = invoiceInfo.invoice_detail.company_tax_id;

                                                const { 
                                                    company_state, 
                                                    company_district, 
                                                    company_town, 
                                                    company_postcode, 
                                                    company_detailed_address, 
                                                } = invoiceInfo.invoice_detail.company_address_breakdown;
        

                                                _postcode = company_postcode;

                                                _addressLineOne = company_detailed_address + " " + company_town + " " + company_district + " " + company_state;

                                                _email = invoiceInfo.invoice_detail.company_email;
                                                _phoneNumber = invoiceInfo.invoice_detail.company_phone_number;
                                                _name = invoiceInfo.invoice_detail.company_name;
                                            } else {
                                                _taxNumberType = "NIDN";
                                                _taxId = invoiceInfo.invoice_detail.tax_id;

                                                const { 
                                                    state, 
                                                    district, 
                                                    town, 
                                                    postcode, 
                                                    detailed_address, 
                                                } = invoiceInfo.invoice_detail.address_breakdown;
        
                                                _postcode = postcode;
                                                _addressLineOne = detailed_address + " " + town + " " + district + " " + state;
                                                _email = invoiceInfo.invoice_detail.email;
                                                _phoneNumber = invoiceInfo.invoice_detail.phone_number;
                                                _name = invoiceInfo.invoice_detail.name;
                                            }
                                        } else {
                                            if (order.recipient_address && order.recipient_address !== null && order.recipient_address !== undefined) {
                                                _customerPhone = order.recipient_address.phone.replace("66", "0");
                                                _address = order.recipient_address.full_address;
                                                _postcode = order.recipient_address.zipcode;

                                                if (_postcode.includes("*")) {
                                                    _postcode = "00000"
                                                }
                                                // if (_address.includes(order.recipient_address.town) && order.recipient_address.town !== "") {
                                                //     let replace = new RegExp(" " + order.recipient_address.town, "g");
                                                //     _address = _address.replace(replace, "");
                                                //     _address += " " + order.recipient_address.town;
                                                // }
                                                // if (_address.includes(order.recipient_address.district) && order.recipient_address.district !== "") {
                                                //     let replace = new RegExp(" " + order.recipient_address.district, "g");
                                                //     _address = _address.replace(replace, "");
                                                //     _address += " " + order.recipient_address.district;
                                                // }
                                                // if (_address.includes(order.recipient_address.city) && order.recipient_address.city !== "") {
                                                //     let replace = new RegExp(" " + order.recipient_address.city, "g");
                                                //     _address = _address.replace(replace, "");
                                                //     _address += " " + order.recipient_address.city;
                                                // }
                                                // if (_address.includes(order.recipient_address.region) && order.recipient_address.region !== "") {
                                                //     let replace = new RegExp(" " + order.recipient_address.region, "g");
                                                //     _address = _address.replace(replace, "");
                                                // }
                                                // if (_address.includes(order.recipient_address.state) && order.recipient_address.state !== "") {
                                                //     let replace = new RegExp(" " + order.recipient_address.state, "g");
                                                //     _address = _address.replace(replace, "");

                                                //     if (order.recipient_address.state.includes("กรุงเทพ")) {
                                                //         order.recipient_address.state = order.recipient_address.state.replace("จังหวัด", "");
                                                //         _address += " " + order.recipient_address.state;
                                                //     } else {
                                                //         _address += " " + order.recipient_address.state;
                                                //     }
                                                // }
                                                if (_address.includes(order.recipient_address.zipcode) && order.recipient_address.zipcode !== "") {
                                                    // let replace = new RegExp(" " + order.recipient_address.zipcode, "g");
                                                    if (!order.recipient_address.zipcode.includes("*")) {
                                                        _address = _address.replace(order.recipient_address.zipcode, "");
                                                    }
                                                }
                                            }

                                            if (order.invoice_data !== null) {
                                                _taxId = order.invoice_data.tax_code;
                                            }
                                        }

                                        if (order.item_list) {
                                            if (registerVat) {
                                                order.item_list.forEach((item, index) => {
                                                    let item_obj = {
                                                        number: index + 1 + "",
                                                        description: item.item_name,
                                                        quantity: item.model_quantity_purchased,
                                                        unitCode: "",
                                                        unitName: "-",
                                                        price: item.model_original_price,
                                                        discount: item.model_original_price - item.model_discounted_price,
                                                        percentVat: percentVat,
                                                        percentVatText: percentVatText,
                                                        total: item.model_discounted_price * item.model_quantity_purchased,
                                                    };

                                                    _items.push(item_obj);
                                                });

                                                if (showShippingFee) {
                                                    _items.push({
                                                        number: order.item_list.length + 1 + "",
                                                        description: "ค่าขนส่ง",
                                                        quantity: 1,
                                                        unitCode: "",
                                                        unitName: "-",
                                                        price: order.estimated_shipping_fee,
                                                        discount: 0,
                                                        percentVat: percentVat,
                                                        percentVatText: percentVatText,
                                                        total: order.estimated_shipping_fee,
                                                    });
                                                }

                                                if (includeVat) {
                                                    for (let i = 0; i < _items.length; i++) {
                                                        _vatPriceTotal = _vatPriceTotal + _items[i]["total"];
                                                        _grandTotal = _grandTotal + _items[i]["total"];
                                                    }

                                                    _vatPriceTotal = Math.round(_vatPriceTotal * 100) / 100;
                                                    _grandTotal = Math.round(_grandTotal * 100) / 100;

                                                    _vatTotal = Math.round(((_vatPriceTotal * percentVat) / (100 + percentVat)) * 100) / 100 + Math.round(((_zeroVatPriceTotal * zeroVat) / (100 + zeroVat)) * 100) / 100;

                                                    _amountTotal = _grandTotal - _vatTotal;
                                                    _vatPriceTotal = _vatPriceTotal - Math.round(((_vatPriceTotal * percentVat) / (100 + percentVat)) * 100) / 100;
                                                } else {
                                                    for (let i = 0; i < _items.length; i++) {
                                                        _vatPriceTotal = _vatPriceTotal + _items[i]["total"];
                                                        _amountTotal = _amountTotal + _items[i]["total"];
                                                    }

                                                    _vatPriceTotal = Math.round(_vatPriceTotal * 100) / 100;
                                                    _amountTotal = Math.round(_amountTotal * 100) / 100;

                                                    _vatTotal = Math.round(((_vatPriceTotal * percentVat) / (100 + percentVat)) * 100) / 100 + Math.round(((_zeroVatPriceTotal * zeroVat) / (100 + zeroVat)) * 100) / 100;

                                                    _grandTotal = _amountTotal + _vatTotal;
                                                }
                                            } else {
                                                order.item_list.forEach((item, index) => {
                                                    let item_obj = {
                                                        number: index + 1 + "",
                                                        description: item.item_name,
                                                        quantity: item.model_quantity_purchased,
                                                        unitCode: "",
                                                        unitName: "-",
                                                        price: item.model_original_price,
                                                        discount: item.model_original_price - item.model_discounted_price,
                                                        percentVat: zeroVat,
                                                        percentVatText: zeroVatText,
                                                        total: item.model_discounted_price * item.model_quantity_purchased,
                                                    };

                                                    _items.push(item_obj);
                                                });

                                                if (showShippingFee) {
                                                    _items.push({
                                                        number: order.item_list.length + 1 + "",
                                                        description: "ค่าขนส่ง",
                                                        quantity: 1,
                                                        unitCode: "",
                                                        unitName: "-",
                                                        price: order.estimated_shipping_fee,
                                                        discount: 0,
                                                        percentVat: zeroVat,
                                                        percentVatText: zeroVatText,
                                                        total: order.estimated_shipping_fee,
                                                    });
                                                }

                                                for (let i = 0; i < _items.length; i++) {
                                                    _zeroVatPriceTotal = _zeroVatPriceTotal + _items[i]["total"];
                                                    _amountTotal = _amountTotal + _items[i]["total"];
                                                    _grandTotal = _grandTotal + _items[i]["total"];
                                                }
                                            }
                                        }

                                        if (_phoneNumber.includes("*")) {
                                            _phoneNumber = "";
                                        }

                                        if (_customerPhone.includes("*")) {
                                            _customerPhone = "";
                                        }

                                        if (_email.includes("*")) {
                                            _email = "";
                                        }

                                        if (_taxId.includes("*") || !_taxId) {
                                            _taxNumberType = "OTHR";
                                            _taxId = "N/A";
                                            _branchNumber = "";
                                            _branchText = "";
                                        }

                                        _docs.push({
                                            key: order.order_sn,
                                            createdTime: createdTime,
                                            createdTimestamp: createdTimestamp,
                                            updatedTime: updatedTime,
                                            updatedTimestamp: updatedTimestamp,
                                            paidTime: paidTime,
                                            paidTimestamp: paidTimestamp,
                                            pickupDoneTime: pickupDoneTime,
                                            pickupDoneTimestamp: pickupDoneTimestamp,
                                            orderId: order.order_sn,
                                            customerName: _name || order.recipient_address.name,
                                            customerAddress: _addressLineOne || _address,
                                            customerPostcode: _postcode || order.recipient_address.zipcode,
                                            customerPhone: _phoneNumber || _customerPhone,
                                            customerEmail: _email,
                                            taxNumberType: _taxNumberType,
                                            branchNumber: _branchNumber,
                                            branchText: _branchText,
                                            items: _items,
                                            amountTotal: _amountTotal,
                                            vatTotal: _vatTotal,
                                            grandTotal: _grandTotal,
                                            nonVatPriceTotal: _nonVatPriceTotal,
                                            zeroVatPriceTotal: _zeroVatPriceTotal,
                                            vatPriceTotal: _vatPriceTotal,
                                            customerTaxId: _taxId,
                                            buyerPayment: order.total_amount,
                                            note: order.note || "",
                                            etaxCreated: documentsResponse.data.some(_doc => _doc.data.reference === order.order_sn),
                                            shippingFee: showShippingFee ? order.estimated_shipping_fee : 0,
                                            isRequest: _isRequest
                                        });
                                    });
                                } else {
                                    if (response.data.error === "error_auth") {
                                        console.log(response.data.error);
                                        disconnect(true);
                                    }
                                }
                            }

                            if (_docs.length > 0) {
                                if (primarySort === "customerName") {
                                    console.log("customerName", true);
                                    if (isSortedDescendingCustomerName) {
                                        _docs.sort((a, b) => {
                                            if (b.customerName < a.customerName) {
                                                return -1;
                                            }
                                            if (b.customerName > a.customerName) {
                                                return 1;
                                            }
                                            return 0;
                                        });
                                    } else {
                                        _docs.sort((a, b) => {
                                            if (a.customerName < b.customerName) {
                                                return -1;
                                            }
                                            if (a.customerName > b.customerName) {
                                                return 1;
                                            }
                                            return 0;
                                        });
                                    }
                                } else if (primarySort === "buyerPayment") {
                                    if (isSortedDescendingBuyerPayment) {
                                        _docs.sort(function (a, b) {
                                            return b.buyerPayment - a.buyerPayment;
                                        });
                                    } else {
                                        _docs.sort(function (a, b) {
                                            return a.buyerPayment - b.buyerPayment;
                                        });
                                    }
                                } else if (primarySort === "isRequest") {
                                    if (isSortedDescendingIsRequest) {
                                        _docs.sort(function(x, y) {
                                            return (x.isRequest === y.isRequest) ? 0 : x.isRequest ? -1 : 1;
                                        })
                
                                    } else {
                                        _docs.sort(function(x, y) {
                                            return (x.isRequest === y.isRequest) ? 0 : x.isRequest ? 1 : -1;
                                        })
                    
                                    }
                                }
                            }

                            setItems(_docs);
                            setLoadDataComplete(true);
                            setGetNewDocuments(false);
                        } else {
                            if (response?.data?.error === "error_auth") {
                                console.log(response?.data?.error);
                                disconnect(true);
                            }
                        }

                        setLoadDataComplete(true);
                        setGetNewDocuments(false);
                        setIsCreateDocumentComplete(false);
                    })
                    .catch((error) => {
                        //Acquire token silent failure, and send an interactive request
                        console.log("error getNewDocument", error);
                        // setItems([]);
                        // setLoadDataComplete(true);
                        // setGetNewDocuments(false);
                        // setIsCreateDocumentComplete(false);
                        // instance.acquireTokenRedirect(silentRequest);
                        // disconnect(true);
                    });
            }
        } else {
            setGetNewDocuments(false);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getNewDocuments]);

    useEffect(() => {
        sessionStorage.setItem("shopee-currentPage", currentPage);

        setLoadDataComplete(false);
        setItems([]);
        setGetNewDocuments(true);

        setSearch("");

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentPage]);

    useEffect(() => {
        console.log("isCreateDocumentComplete useEffect: ", isCreateDocumentComplete);

        if (loadDataComplete && !isCreateDocument) {
            let _items = selection.getItems();
            console.log("_items: ", _items);

            let _selectedItems = selection.getSelection();

            if (isCreateDocumentComplete) {
                setIsCreateDocumentComplete(false);

                for (let i = 0; i < _items.length; i++) {
                    if (_items[i].status !== "") {
                        _items[i].status = "";
                    }
                }
            } else {
                for (let i = 0; i < _items.length; i++) {
                    if (_items[i].status !== "") {
                        _items[i].status = "";
                    }
                }

                for (let i = 0; i < _selectedItems.length; i++) {
                    console.log("select item key: ", _selectedItems[i].key);

                    for (let j = 0; j < _items.length; j++) {
                        if (_items[j].key === _selectedItems[i].key) {
                            console.log("set pending item key: ", _items[j].key);
                            _items[j].status = "pending";
                            console.log("_items: ", _items);
                        }
                    }
                }
            }

            setItems(_items);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedItems]);

    const handleSort = (primary, isSorted) => {
        if (items.length > 0) {
            // console.log('selection.getItems()', selection.getItems());
            let _items = selection.getItems();
            let _selectedItems = selection.getSelection();
            // console.log('selection: ', selection.getSelection());

            for (let i = 0; i < _selectedItems.length; i++) {
                // console.log('select item key: ', _selectedItems[i].key);
                _selectedItems[i].status = "";

                for (let j = 0; j < _items.length; j++) {
                    if (_items[j].key === _selectedItems[i].key) {
                        console.log("item key: ", _items[j].key);
                        _items[j].status = "";
                        _selectedItems[i].status = "";
                    }
                }
            }

            selection.setAllSelected(false);

            if (primary === "number") {
                if (isSorted) {
                    _items.sort((a, b) => {
                        if (b.orderId < a.orderId) {
                            return -1;
                        }
                        if (b.orderId > a.orderId) {
                            return 1;
                        }
                        return 0;
                    });

                    setItems(_items);
                } else {
                    _items.sort((a, b) => {
                        if (a.orderId < b.orderId) {
                            return -1;
                        }
                        if (a.orderId > b.orderId) {
                            return 1;
                        }
                        return 0;
                    });

                    setItems(_items);
                }
            } else if (primary === "createdTime") {
                if (isSorted) {
                    _items.sort(function (a, b) {
                        return b.createdTimestamp - a.createdTimestamp;
                    });

                    setItems(_items);
                } else {
                    _items.sort(function (a, b) {
                        return a.createdTimestamp - b.createdTimestamp;
                    });

                    setItems(_items);
                }
            } else if (primary === "updatedTime") {
                if (isSorted) {
                    _items.sort(function (a, b) {
                        return b.updatedTimestamp - a.updatedTimestamp;
                    });

                    setItems(_items);
                } else {
                    _items.sort(function (a, b) {
                        return a.updatedTimestamp - b.updatedTimestamp;
                    });

                    setItems(_items);
                }
            } else if (primary === "customerName") {
                if (isSorted) {
                    _items.sort((a, b) => {
                        if (b.customerName < a.customerName) {
                            return -1;
                        }
                        if (b.customerName > a.customerName) {
                            return 1;
                        }
                        return 0;
                    });

                    setItems(_items);
                } else {
                    _items.sort((a, b) => {
                        if (a.customerName < b.customerName) {
                            return -1;
                        }
                        if (a.customerName > b.customerName) {
                            return 1;
                        }
                        return 0;
                    });

                    setItems(_items);
                }
            } else if (primary === "vatTotal") {
                if (isSorted) {
                    _items.sort(function (a, b) {
                        return b.vatTotal - a.vatTotal;
                    });

                    setItems(_items);
                } else {
                    _items.sort(function (a, b) {
                        return a.vatTotal - b.vatTotal;
                    });

                    setItems(_items);
                }
            } else if (primary === "buyerPayment") {
                if (isSorted) {
                    _items.sort(function (a, b) {
                        return b.buyerPayment - a.buyerPayment;
                    });

                    setItems(_items);
                } else {
                    _items.sort(function (a, b) {
                        return a.buyerPayment - b.buyerPayment;
                    });

                    setItems(_items);
                }
            } else if (primary === "isRequest") {
                if (isSorted) {
                    _items.sort(function(x, y) {
                        return (x.isRequest === y.isRequest) ? 0 : x.isRequest ? -1 : 1;
                    })

                    setItems(_items);
                } else {
                    _items.sort(function(x, y) {
                        return (x.isRequest === y.isRequest) ? 0 : x.isRequest ? 1 : -1;
                    })

                    setItems(_items);
                }
            }
        }
    };

    const onPageChange = (selectedPageIndex) => {
        console.log("selectedPageIndex: ", selectedPageIndex);
        setCurrentPage(selectedPageIndex + 1);
    };

    return (
        <div style={homeStyles}>
            {!isLoading ? (
                <div>
                    <Stack>
                        <center>
                            <h2 style={{ marginLeft: "0px", marginTop: "25px" }}>{t("shopee.header")}</h2>
                            {!loadDataComplete ? (
                                <div>
                                    <Spinner
                                        label={t("shopee.loading")}
                                        labelPosition="right"
                                        style={{
                                            marginTop: "21px",
                                            marginBottom: "2px",
                                        }}
                                    />
                                    <br />
                                </div>
                            ) : null}
                            <Stack horizontal horizontalAlign="center">
                                <Toggle
                                    label={t("shopee.displayShipCost")}
                                    inlineLabel
                                    onText={t("shopee.on")}
                                    offText={t("shopee.off")}
                                    checked={showShippingFee}
                                    disabled={!loadDataComplete || isCreateDocument}
                                    onChange={() => {
                                        setShowShippingFee(!showShippingFee);

                                        if (!showShippingFee) {
                                            localStorage.setItem("showShippingFee", "yes");
                                        } else {
                                            localStorage.setItem("showShippingFee", "no");
                                        }

                                        setLoadDataComplete(false);
                                        setItems([]);
                                        setGetNewDocuments(true);
                                    }}
                                />
                            </Stack>
                        </center>
                    </Stack>

                    {(selectedItems.length > 0 || isCreateDocument || isCreateDocumentComplete) && (
                        <Stack vertical tokens={{ childrenGap: "10px" }}>
                            <Stack horizontal horizontalAlign="center" tokens={{ childrenGap: "10px" }}>
                                <h3>{t("shopee.subHeader")}</h3>
                            </Stack>
                            <Stack horizontal horizontalAlign="center" tokens={{ childrenGap: "10px" }}>
                                <TextField
                                    name="prefixNumber"
                                    label={t("shopee.prefixNumber")}
                                    description={t("shopee.prefixNumberDescription")}
                                    styles={{ root: { width: 200 } }}
                                    onChange={(e) => {
                                        setPrefixNumber(e.target.value);
                                    }}
                                    value={prefixNumber}
                                    disabled={isCreateDocument}
                                />
                                <TextField
                                    name="countingNumber"
                                    label={t("shopee.countingNumber")}
                                    description={countingNumber.length === 0 ? "" : t("shopee.countingNumberDesciption")}
                                    styles={{ root: { width: 150 } }}
                                    onChange={(e) => {
                                        if (!/\D/.test(e.target.value)) {
                                            setCountingNumber(e.target.value);
                                        }
                                    }}
                                    value={countingNumber}
                                    errorMessage={countingNumber.length === 0 ? t("shopee.countingNumberErrorMessage") : ""}
                                    disabled={isCreateDocument}
                                    required
                                />
                            </Stack>
                            <Stack horizontalAlign="center" tokens={{ childrenGap: '10px' }}>
                                <Dropdown
                                    name="documentDateType"
                                    placeholder={t("shopee.pleaseSelect")}
                                    label={t("shopee.documentDateType")}
                                    options={documentDateTypeOptions}
                                    styles={{ root: { width: 200 } }}                                    
                                    selectedKey={selectedDocumentDateType}
                                    disabled={isCreateDocument}
                                    onChange={(event, selectedOption) => {
                                        console.log("selectedOption", selectedOption)
                                        setSelectedDocumentDateType(selectedOption.key)

                                        sessionStorage.setItem("shopee-documentDateType", selectedOption.key);
                                    }}
                                />
                                {selectedDocumentDateType === "paidTime" || selectedDocumentDateType === "pickupDoneTime" ? (
                                    <p
                                        style={{
                                            width: 200,
                                            marginTop: 0,
                                            marginBottom: 0,
                                            fontSize: 12,
                                            fontWeight: 400,
                                            color: "#605e5c",
                                            fontFamily:
                                                '"Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif',
                                        }}
                                    >
                                        {selectedDocumentDateType === "paidTime" ? t("shopee.documentDateTimeDesc") : ""}
                                        {selectedDocumentDateType === "pickupDoneTime" ? t("shopee.documentDateTimeDesc2") : ""}
                                    </p>
                                    
                                ) : null}
                            </Stack>
                            <Stack horizontal horizontalAlign="center" tokens={{ childrenGap: '10px' }}>
                                <TextField
                                    name="createdBy"
                                    label={t('shopee.createdBy')}
                                    description={t('shopee.createdByDesc')}
                                    styles={{ root: { width: 200 } }}
                                    onChange={(e) => {
                                        setCreatedBy(e.target.value);
                                    }}
                                    value={createdBy}
                                    disabled={isCreateDocument}
                                />
                            </Stack>
                            {countingNumber.length > 0 ? (
                                <Stack horizontal horizontalAlign="center" tokens={{ childrenGap: "10px" }}>
                                    {!isCreateDocument ? (
                                        <h4>
                                            {t("shopee.startDocumentNumber")} {prefixNumber}
                                            {countingNumber}
                                        </h4>
                                    ) : (
                                        <h4>
                                            {t("shopee.endDocumentNumber")} {prefixNumber}
                                            {countingNumber}
                                        </h4>
                                    )}
                                </Stack>
                            ) : (
                                <br />
                            )}
                        </Stack>
                    )}

                    {selectedItems.length > 0 && !isCreateDocument && (
                        <Stack horizontal horizontalAlign="center" tokens={{ childrenGap: "10px" }}>
                            <PrimaryButton
                                text={t("shopee.createDocument")}
                                iconProps={{ iconName: "Add" }}
                                menuProps={{
                                    items: [
                                        {
                                            key: "RECEIPT-TAXINVOICE",
                                            text: t("shopee.receipt-taxinvoice"),
                                            iconProps: { iconName: "PageAdd" },
                                            onClick: async () => {
                                                if (countingNumber.length > 0) {
                                                    // setIsCreateDocument(true);
                                                    // await createSelectedDocuments("RECEIPT-TAXINVOICE");
                                                    await createSelectedDocumentsOnClick("RECEIPT-TAXINVOICE");
                                                }
                                            },
                                        },
                                        {
                                            key: "RECEIPT-TAXINVOICE-ABB",
                                            text: t("shopee.receipt-taxinvoice-abb"),
                                            iconProps: { iconName: "PageAdd" },
                                            onClick: async () => {
                                                if (countingNumber.length > 0) {
                                                    // setIsCreateDocument(true);
                                                    // await createSelectedDocuments("RECEIPT-TAXINVOICE-ABB");
                                                    await createSelectedDocumentsOnClick("RECEIPT-TAXINVOICE-ABB");
                                                }
                                            },
                                        },
                                        {
                                            key: "RECEIPT",
                                            text: t("shopee.receipt"),
                                            iconProps: { iconName: "PageAdd" },
                                            onClick: async () => {
                                                if (countingNumber.length > 0) {
                                                    // setIsCreateDocument(true);
                                                    // await createSelectedDocuments("RECEIPT");
                                                    await createSelectedDocumentsOnClick("RECEIPT");
                                                }
                                            },
                                        },
                                        {
                                            key: "INVOICE-TAXINVOICE",
                                            text: t("shopee.invoice-taxinvoice"),
                                            iconProps: { iconName: "PageAdd" },
                                            onClick: async () => {
                                                if (countingNumber.length > 0) {
                                                    // setIsCreateDocument(true);
                                                    // await createSelectedDocuments("INVOICE-TAXINVOICE");
                                                    await createSelectedDocumentsOnClick("INVOICE-TAXINVOICE");
                                                }
                                            },
                                        },
                                        {
                                            key: "DELIVERYORDER-TAXINVOICE",
                                            text: t("shopee.deliveryorder-taxinvoice"),
                                            iconProps: { iconName: "PageAdd" },
                                            onClick: async () => {
                                                if (countingNumber.length > 0) {
                                                    // setIsCreateDocument(true);
                                                    // await createSelectedDocuments("DELIVERYORDER-TAXINVOICE");
                                                    await createSelectedDocumentsOnClick("DELIVERYORDER-TAXINVOICE");
                                                }
                                            },
                                        },
                                        {
                                            key: "TAXINVOICE",
                                            text: t("shopee.taxinvoice"),
                                            iconProps: { iconName: "PageAdd" },
                                            onClick: async () => {
                                                if (countingNumber.length > 0) {
                                                    // setIsCreateDocument(true);
                                                    // await createSelectedDocuments("TAXINVOICE");
                                                    await createSelectedDocumentsOnClick("TAXINVOICE");
                                                }
                                            },
                                        },
                                    ],
                                    shouldFocusOnMount: true,
                                    directionalHintFixed: true,
                                }}
                                // Optional callback to customize menu rendering
                                menuAs={(props) => <ContextualMenu {...props} />}
                            />
                            <DefaultButton
                                text={t("shopee.cancel")}
                                style={{ marginLeft: "10px" }}
                                onClick={() => {
                                    let _items = selection.getItems();
                                    let _selectedItems = selection.getSelection();
                                    console.log("selection: ", selection.getSelection());

                                    for (let i = 0; i < _selectedItems.length; i++) {
                                        console.log("select item key: ", _selectedItems[i].key);
                                        _selectedItems[i].status = "";

                                        for (let j = 0; j < _items.length; j++) {
                                            if (_items[j].key === _selectedItems[i].key) {
                                                console.log("item key: ", _items[j].key);
                                                _items[j].status = "";
                                                _selectedItems[i].status = "";
                                            }
                                        }
                                    }

                                    selection.setAllSelected(false);

                                    setItems(_items);
                                    console.log("selection: ", selection.getSelection());
                                }}
                            />
                            <br />
                            <br />
                        </Stack>
                    )}

                    {isCreateDocument && (
                        <div>
                            <Spinner label={t("shopee.signProcessing")} size={SpinnerSize.large} />
                        </div>
                    )}

                    {isCreateDocumentComplete && (
                        <center>
                            <PrimaryButton
                                text={t("shopee.createdDocuments")}
                                iconProps={{ iconName: "Documentation" }}
                                onClick={async () => {
                                    console.log(selectedItems);
                                    history.push({
                                        pathname: "/etax/documents/all",
                                        state: {},
                                    });
                                }}
                            />
                        </center>
                    )}

                    <br />
{/* 
                    <Stack horizontal horizontalAlign="end" styles={{ root: { marginRight: 50, marginTop: -90 } }}>
                        <h2>
                            <Icon iconName="Robot" style={{ color: "#106ebe", marginRight: "7px" }} />
                        </h2>
                        <Link
                            onClick={() => {
                                history.push({
                                    pathname: "/etax/sources/shopee/automation",
                                    state: {},
                                });
                            }}
                        >
                            <h3 style={{ marginTop: 20, width: 140 }}>{t("shopee.automation")}</h3>
                        </Link>
                    </Stack>

                    {enableAutomation ? (
                        <Stack horizontal horizontalAlign="end" styles={{ root: { marginRight: 50, marginTop: -35, marginBottom: -10 } }}>
                            <h2 style={{ marginTop: 20 }}>
                                <Icon iconName="SyncStatusSolid" style={{ color: "#008000", marginRight: "7px" }} />
                            </h2>
                            <span style={{ marginTop: 22, width: 140, color: "#008000" }}>{t("shopee.onAutomationStatus")}</span>
                        </Stack>
                    ) : (
                        <Stack horizontal horizontalAlign="end" styles={{ root: { marginRight: 50, marginTop: -35, marginBottom: -10 } }}>
                            <h2 style={{ marginTop: 18 }}>
                                <Icon iconName="Blocked2" style={{ color: "#106ebe", marginRight: "7px" }} />
                            </h2>
                            <span style={{ marginTop: 20, width: 140 }}>{t("shopee.offAutomationStatus")}</span>
                        </Stack>
                    )}
 */}
                    <Stack horizontal horizontalAlign="center">
                        <Stack
                            vertical
                            style={{
                                width: "98%",
                                minWidth: "800px",
                                maxWidth: "1350px",
                            }}
                        >

                            {/* <Stack horizontal horizontalAlign="end" style={{ marginRight: 15, marginTop: -50 }}>
                                <Icon iconName="Robot" style={{ color: "#106ebe", marginRight: "7px", marginTop: "-3px", fontSize: "21px" }} />
                                <Link
                                    disabled={true}
                                    onClick={() => {
                                        history.push({
                                            pathname: "/etax/sources/shopee/automation",
                                            state: {},
                                        });
                                    }}
                                >
                                    <h3 style={{ marginTop: 0, width: 140 }}>{t("shopee.automation")}</h3>
                                </Link>
                            </Stack>

                            {enableAutomation ? (
                                <Stack horizontal horizontalAlign="end" style={{ marginRight: 13, marginTop: -10, marginBottom: 10 }}>
                                    <Icon iconName="SyncStatusSolid" style={{ color: "#008000", marginTop: "-2px", marginRight: "10px", fontSize: "20px" }} />
                                    <span style={{ marginTop: 0, width: 140, color: "#008000" }}>{t("shopee.onAutomationStatus")}</span>
                                </Stack>
                            ) : (
                                <Stack horizontal horizontalAlign="end" style={{ marginRight: 13, marginTop: -10, marginBottom: 10 }}>
                                        <Icon iconName="Blocked2" style={{ color: "#106ebe", marginTop: "-2px", marginRight: "10px", fontSize: "20px" }} />
                                    <span style={{ marginTop: 0, width: 140 }}>{t("shopee.offAutomationStatus")}</span>
                                </Stack>
                            )} */}

                            <Stack 
                                horizontal 
                                styles={{
                                    root: {
                                      backgroundColor: 'white',
                                    },
                                  }} 
                                horizontalAlign="space-between"
                            >
                                <Stack
                                    styles={{
                                        root: {
                                        width: "calc(100vw - 700px)",
                                        minWidth: "700px",
                                        maxWidth: "900px",
                                        },
                                    }}
                                >
                                    <CommandBar
                                        items={commandBarItems}
                                        overflowItems={overflowItems}
                                    />
                                </Stack>
                                <Stack
                                    horizontal
                                    styles={{
                                        root: {
                                            height: 44,
                                        },
                                    }}
                                    tokens={{ childrenGap: "10px" }}
                                >
                                    <SearchBox
                                        className="ms-borderColor-themePrimary"
                                        styles={{
                                            root: {
                                                marginTop: 6,
                                                marginLeft: 6,
                                                width: 200,
                                                fontSize: 13,
                                                fontWeight: 0,
                                            },
                                        }}
                                        disabled={!loadDataComplete || isCreateDocument}
                                        placeholder={t("shopee.searchBoxPlaceholder")}
                                        onChange={(e) => setSearch(e ? e.target.value.replace(/\s/g, "") : "")}
                                        onClear={() => {
                                            setLoadDataComplete(false);
                                            setItems([]);
                                            setGetNewDocuments(true);

                                            setCurrentPage(1);
                                        }}
                                        onSearch={() => {
                                            setLoadDataComplete(false);
                                            setItems([]);
                                            setGetNewDocuments(true);

                                            setCurrentPage(1);
                                        }}
                                        value={search}
                                    />
                                    <PrimaryButton
                                        disabled={!loadDataComplete || isCreateDocument || !search}
                                        styles={{
                                            root: {
                                                marginTop: 6,
                                                marginRight: 8,
                                                width: 90,
                                            },
                                        }}
                                        text={t("shopee.search")}
                                        onClick={() => {
                                            setLoadDataComplete(false);
                                            setItems([]);
                                            setGetNewDocuments(true);

                                            setCurrentPage(1);
                                        }}
                                    />
                                </Stack>
                            </Stack>
                            <ShimmeredDetailsList
                                items={items || []}
                                enableShimmer={!loadDataComplete}
                                compact={isCompactMode}
                                columns={columns}
                                selection={selection}
                                selectionMode={!loadDataComplete || isCreateDocument ? SelectionMode.none : SelectionMode.multiple}
                                layoutMode={DetailsListLayoutMode.justified}
                                isHeaderVisible={true}
                                checkboxVisibility={CheckboxVisibility.always}
                                selectionPreservedOnEmptyClick={true}
                            />
                        </Stack>
                    </Stack>
                    <br />
                    <center>
                        {!(currentPage === 1 && items.length < pageSize) && !(!loadDataComplete || isCreateDocument) && (
                            <Pagination
                                selectedPageIndex={currentPage - 1}
                                pageCount={pageData.totalPages}
                                onPageChange={onPageChange}
                                format
                                firstPageIconProps={{
                                    iconName: "DoubleChevronLeft",
                                }}
                                previousPageIconProps={{
                                    iconName: "ChevronLeft",
                                }}
                                nextPageIconProps={{
                                    iconName: "ChevronRight",
                                }}
                                lastPageIconProps={{
                                    iconName: "DoubleChevronRight",
                                }}
                            />
                        )}

                        <br />
                        <br />
                    </center>
                </div>
            ) : (
                <Stack>
                    <Stack horizontal horizontalAlign="center">
                        <br />
                        <br />
                        <div className="certificates-loading-center">
                            <Spinner label={t("shopee.loading")} labelPosition="down" styles={spinnerStyles} />
                        </div>
                        <br />
                    </Stack>
                </Stack>
            )}
            <Dialog
                hidden={!dialogConfirm.isClick}
                onDismiss={dialogConfirm.onCancel}
                styles={{ main: { minHeight: 0 } }}
                maxWidth={dialogConfirm.maxWidth}
                dialogContentProps={{
                    type: DialogType.largeHeader,
                    title: dialogConfirm.header,
                    styles: { header: { textAlign: "center" }, title: { paddingRight: 24 }, subText: { textAlign: "center" } },
                    subText: dialogConfirm.message,
                }}
            >
                {dialogConfirm.content}
                <DialogFooter styles={{ actionsRight: { textAlign: "center" } }}>
                    <PrimaryButton onClick={dialogConfirm.onConfirm} text={t("documents.confirm")} />
                    <DefaultButton onClick={dialogConfirm.onCancel} text={t("documents.cancel")} />
                </DialogFooter>
            </Dialog>
        </div>
    );
}

export default Shopee;
