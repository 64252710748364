import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import axios from "axios";

import { Stack, getTheme, MessageBar, MessageBarType } from "@fluentui/react";
import {
  Field,
  Input,
  makeStyles,
  tokens,
  shorthands,
  useId,
  Label,
  Option,
  Dropdown,
  Textarea,
  Button,
  typographyStyles,
  Text,
  Dialog,
  DialogTrigger,
  DialogSurface,
  DialogTitle,
  DialogContent,
  DialogBody,
  DialogActions,
} from "@fluentui/react-components";

import { useMsal, useAccount } from "@azure/msal-react";
import { silentRequest } from "../../authConfig";
import {
  getProvinceList,
  getDistrictList,
  getSubDistrictList,
} from "../../providers/AreaData";

const API_URL_FORM = process.env.REACT_APP_REQUEST_FORM_API_URL;
const theme = getTheme();
const useStyles = makeStyles({
  root: {
    // Stack the label above the field with a gap
    display: "grid",
    gridTemplateRows: "repeat(1fr)",
    justifyItems: "start",
    ...shorthands.gap("20px"),
    maxWidth: "400px",
    "> div": {
      display: "grid",
      gridTemplateRows: "repeat(1fr)",
      justifyItems: "start",
      ...shorthands.gap("2px"),
      // need padding to see the background color for filled variants
      ...shorthands.padding("5px", "20px", "10px"),
    },
  },
  base: {
    display: "flex",
    flexDirection: "column",
    maxWidth: "400px",
  },
  field: {
    display: "grid",
    gridRowGap: tokens.spacingVerticalXXS,
    marginTop: tokens.spacingVerticalMNudge,
    ...shorthands.padding(tokens.spacingHorizontalMNudge),
  },
  title: typographyStyles.subtitle2Stronger,
  languagechange: {
    ...typographyStyles.subtitle2,
    color: "var(--secondary-color)",
  },

  cardStyle: {
    width: "600px",
    paddingLeft: "30px",
    paddingRight: "30px",
    paddingTop: "20px",
    paddingBottom: "20px",

    marginLeft: "0",
    marginTop: "10px",
    boxShadow: theme.effects.elevation4,
    backgroundColor: "white",
  },
});


const centerItem = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};

const EditContacts = ({
  setStep,

  isLoadComplete,
  setIsLoadComplete,
}) => {
  const { t, i18n } = useTranslation();
  const dropdownId = useId("dropdown");
  const styles = useStyles();
  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0] || {});
  const outlineId = useId("input-outline");


  const [name, setName] = useState("");

  const [taxTypeText, setTaxTypeText] = useState("");
  const [taxNumberType, setTaxNumberType] = useState("");
  useEffect(() => {
    if (taxNumberType) {
      const updatedText = getDocumentTypeText(taxNumberType, i18n.language);
      setTaxTypeText(updatedText);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n.language, taxNumberType]);

  const [buildingNumber, setBuildingNumber] = useState("");
  const [address, setAddress] = useState("");
  const [streetName, setStreetName] = useState("");
  const [provincePrefix, setProvincePrefix] = useState("");
  const [province, setProvince] = useState({ key: "", text: "" });
  const [provinceKey, setProvinceKey] = useState(null);

  const [taxNumberText, setTaxNumberText] = useState("");
  const taxNumberTypeRef = useRef();
  const taxBranchNumberTypeRef = useRef();
  const provinceRef = useRef();
  const districtRef = useRef();
  const subDistrictRef = useRef();
  const countryCode = "TH";

  const [taxBranchNumberType, setTaxBranchNumberType] = useState("");


  const [districtPrefix, setDistrictPrefix] = useState("");
  const [district, setDistrict] = useState({ key: "", text: "" });
  const [districtList, setDistrictList] = useState([]);

  const [subDistrictPrefix, setSubDistrictPrefix] = useState("");
  const [subDistrict, setSubDistrict] = useState({ key: "", text: "" });
  const [subDistrictList, setSubDistrictList] = useState([]);

  const [postcode, setPostCode] = useState("");
  const [note, setNote] = useState("");
  const [taxInvAbbNumber, setTaxInvAbbNumber] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  // const [website, setWebsite] = useState("");
  // const [fax, setFax] = useState("");
  const [taxId, setTaxId] = useState("");
  // const [branch, setBranch] = useState("00000");
  const [branchNumber, setBranchNumber] = useState("00000");
  // const [taxBranchNumberText, setBranchNumberText] = useState(
  //   t("requestFormEditContact.Headoffice")
  // );
  const [showBranchNumber, setShowBranchNumber] = useState(false);
  const [branchText, setBranchText] = useState("สำนักงานใหญ่");

  const [branchDisplay, setBranchDisplay] = useState([
    { key: "00000", text: "สำนักงานใหญ่" },
    { key: "branchNumber", text: "สาขาเลขที่" },
  ]);

  // const [branchDisplayTH, setBranchDisplayTH] = useState([
  //   { key: "00000", text: t("requestFormEditContact.Headoffice") },
  //   { key: "branchNumber", text: t("requestFormEditContact.branchNumber") },
  // ]);

  const BranchoptionsTh = [
    { key: "00000", text: "สำนักงานใหญ่" },
    { key: "branchNumber", text: "สาขาเลขที่" },
  ];
  const BranchoptionsEn = [
    { key: "00000", text: "Head office" },
    { key: "branchNumber", text: "Branch Number" },
  ];

  // const [bankList, setBankList] = useState([
  //   { key: "KBANK", text: "ธนาคารกสิกรไทย" },
  //   { key: "SCB", text: "ธนาคารไทยพาณิชย์" },
  //   { key: "KTB", text: "ธนาคารกรุงไทย" },
  //   { key: "TTB", text: "ธนาคารทีทีบี" },
  //   { key: "BBL", text: "ธนาคารกรุงเทพ" },
  //   { key: "BAY", text: "ธนาคารกรุงศรีอยุธยา" },
  //   { key: "UOB", text: "ธนาคารยูโอบี" },
  // ]);
  // const [accountTypeList, setAccountTypeList] = useState([
  //   { key: "SAVING", text: "ออมทรัพย์" },
  //   { key: "CURRENT", text: "กระแสรายวัน" },
  // ]);
  // const [accountName, setAccountName] = useState("");
  // const [banks, setBanks] = useState([
  //   {
  //     bankCode: "",
  //     bankName: "",
  //     branch: "",
  //     typeCode: "",
  //     typeName: "",
  //     accountNumber: "",
  //   },
  // ]);

  const [showValidateTaxID, setShowValidateTaxID] = useState(false);
  const [showValidateTaxIDText, setShowValidateTaxIDText] = useState(false);

  const [showValidateBuildingNumber, setShowValidateBuildingNumber] =
    useState(false);

  const [showValidateBranchNumber, setShowValidateBranchNumber] =
    useState(false);

  const [showValidateAddress, setShowValidateAddress] = useState(false);

  const [showValidateProvince, setShowValidateProvince] = useState(false);

  const [showValidateDistrict, setShowValidateDistrict] = useState(false);
  const [showValidateSubDistrict, setShowValidateSubDistrict] = useState(false);

  const [showValidateEmail, setShowValidateEmail] = useState(false);

  const [showMessageBarSuccess, setShowMessageBarSuccess] = useState(false);
  const [showMessageBarError, setShowMessageBarError] = useState(false);
  const [showMessageBarErrorName, setShowMessageBarErrorName] = useState(false);
  // const [showMessageBarErrorPhone, setShowMessageBarErrorPhone] =
  //   useState(false);

  const [showMessageBarErrorPostcode, setShowMessageBarPostcode] =
    useState(false);
  const [showMessageBarRepeatTaxId, setShowMessageBarRepeatTaxId] =
    useState(false);
  const [showMessageBarRepeatOTHR, setShowMessageBarRepeatOTHR] =
    useState(false);

  const [showMessageBarSearchError, setShowMessageBarSearchError] =
    useState(false);
  const [showMessageBarSearchNotFound, setShowMessageBarSearchNotFound] =
    useState(false);
  // const [paymentNote, setPaymentNote] = useState("");

  const language = "th";
  const [provinceList, setProvinceList] = useState([]);
  //Error Message
  const [TypeErrMsg, setTypeErrMsg] = useState("");
  const [branchErrMsg, setBranchErrMsg] = useState("");
  const [provinceErrMsg, setProvinceErrMsg] = useState("");
  const [districtErrMsg, setDistrictErrMsg] = useState("");
  const [subDistrictErrMsg, setSubDistrictErrMsg] = useState("");
  const DropdownErrMsg = t("requestFormEditContact.dropdownErrMsg");

  // const phoneRegEx = /(^0[1-9][0-9]{1,13}$|^$)/g;
  // const [phoneNumberError, setPhoneNumberError] = useState(false);

  // const [label, setLabel] = useState({ key: "", text: "" });
  const [branchBlankDisplay, setBranchBlankDisplay] = useState({
    key: "",
    text: "",
  });
  // const companyId = sessionStorage.getItem("companyId") || "";

  const history = useHistory();
  const location = useLocation();
  const contactId = location?.state?.id

  useEffect(() => {
    setProvinceList(getProvinceList(i18n.language));
  }, [i18n.language]);

  useEffect(() => {
    if (province.key) {
      const updatedText = getProvinceList(i18n.language).find(
        (p) => p.key === province.key
      )?.text;
      setProvince((prev) => ({ ...prev, text: updatedText || prev.text }));
    }
  }, [i18n.language, province.key]);

  useEffect(() => {
    const updatedDistrictList = getDistrictList(province.key, i18n.language);
    console.log("Updated District List:", updatedDistrictList);
    setDistrictList(updatedDistrictList);

  }, [i18n.language, province]);



  useEffect(() => {
    if (district.key) {
      const updatedText = getDistrictList(province.key, i18n.language).find(
        (d) => d.key === district.key
      )?.text;

      console.log("Updated District Text:", updatedText);

      setDistrict((prev) => ({ ...prev, text: updatedText || prev.text }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n.language, district.key]);

  useEffect(() => {
    const updatedSubDistrictList = getSubDistrictList(district.key, i18n.language);
    console.log("Updated subDistrict List:", updatedSubDistrictList);
    setSubDistrictList(updatedSubDistrictList)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n.language, province]);



  useEffect(() => {
    if (subDistrict.key) {
      const updatedText = getSubDistrictList(district.key, i18n.language).find(
        (sd) => sd.key === subDistrict.key)?.text;
      setSubDistrict((prev) => ({ ...prev, text: updatedText || prev.text }))

    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n.language, subDistrict.key])

  useEffect(() => {
    if (provinceKey !== null) {
      setAreaPrefix(provinceKey)
    }
  }, [provinceKey, i18n.language])




  const getDocumentTypeText = (selectedValue) => {
    if (i18n.language === "th") {
      const foundOption = documentOptionswithTextTh.find(
        (documentoptions) => documentoptions.key === selectedValue
      );

      return foundOption ? foundOption.text : taxNumberText;
    } else {
      const foundOption = documentOptionswithTextEn.find(
        (documentoptions) => documentoptions.key === selectedValue
      );
      return foundOption ? foundOption.text : taxNumberText;
    }
  };

  const getBranchTypeText = (selectedValue) => {
    if (i18n.language === "th") {
      const foundOption = BranchoptionsTh.find(
        (documentoptions) => documentoptions.key === selectedValue
      );

      return foundOption ? foundOption.text : null;
    } else {
      const foundOption = BranchoptionsEn.find(
        (documentoptions) => documentoptions.key === selectedValue
      );
      return foundOption ? foundOption.text : null;
    }
  };


  const [databack, setDataback] = useState(null);
  // const confirmSuccuss = location.Function.EditConfirm;
  // const dataById = location.state.filteredData.find(
  //   (item) => item.id === contactId
  // );

  // useEffect(() => {
  //   autoSetData();
  // }, [databack]);
  const autoSetData = () => {
    setTaxNumberType(databack.taxNumberType);

    const selectedTaxIdOption = options.find(
      (option) => option.key === databack.taxNumberType
    );

    handleonClick(selectedTaxIdOption, i18n.language);

    setTaxId(databack.taxId);

    if (databack.branchNumber === "00000") {
      setTaxBranchNumberType("00000");
      handleBranch("00000", i18n.language);
    } else {
      setTaxBranchNumberType("branchNumber");
      handleBranch(databack.branchNumber, i18n.language);
    }
    setName(databack.name);
    setBuildingNumber(databack.buildingNumber);
    setAddress(databack.address);

    setStreetName(databack.streetName);
    setPostCode(databack.postcode);
    setProvincePrefix(databack.provincePrefix);
    setProvince({
      "key": databack.provinceCode,
      "text": databack.provinceName,
    });
    // setProvinceKey(databack.provinceCode);

    setDistrict({
      "key": databack.districtCode,
      "text": databack.districtName,
    });
    setSubDistrictPrefix(databack.subDistrictPrefix);
    setSubDistrict({
      "key": databack.subDistrictCode,
      "text": databack.subDistrictName,
    });

    setDistrictPrefix(databack.districtPrefix);
    setEmail(databack.email);
    setPhone(databack.phone)
    setNote(databack.note);
    setTaxInvAbbNumber(databack.taxInvAbbNumber)
  };

  const dataById = location.state.data.find(
    (item) => item.id === contactId
  );
  useEffect(() => {

    setDataback(dataById?.data);

    if (databack) {
      autoSetData(); // Call autoSetData when databack has data
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [databack]);


  const handleSubmit = () => {
    let isCorrect = true;
    if (!taxNumberType) {
      isCorrect = false;
      // setShowValidateTaxID(true);
      taxNumberTypeRef.current.focus();
      taxNumberTypeRef.current.blur();
    }

    if (taxNumberType === "TXID" && taxBranchNumberType === "") {
      isCorrect = false;
      taxBranchNumberTypeRef.current?.focus();
      taxBranchNumberTypeRef.current?.blur();
    }

    if (province.key === "") {
      isCorrect = false;

      provinceRef.current?.focus();
      provinceRef.current?.blur();
    }
    if (district.key === "") {
      isCorrect = false;

      districtRef.current?.focus();
      districtRef.current?.blur();
    }

    if (subDistrict.key === "") {
      isCorrect = false;

      subDistrictRef.current?.focus();
      subDistrictRef.current?.blur();
    }

    if (
      province.key === undefined ||
      province.key === null ||
      province.key === ""
    ) {
      isCorrect = false;
    }

    if (
      district.key === undefined ||
      district.key === null ||
      district.key === ""
    ) {
      isCorrect = false;
    }

    if (
      subDistrict.key === undefined ||
      subDistrict.key === null ||
      subDistrict.key === ""
    ) {
      isCorrect = false;
    }

    if (
      taxNumberType === undefined ||
      taxNumberType === null ||
      taxNumberType === ""
    ) {
      isCorrect = false;
    }

    let _branchNumber = branchNumber;

    if (taxNumberType === "TXID") {
      if (taxId.length !== 13) {
        isCorrect = false;
      }

      if (isNaN(taxId)) {
        isCorrect = false;
      }

      if (isNaN(branchNumber)) {
        if (
          branchText.toLowerCase() === "head office" ||
          branchText === "สำนักงานใหญ่"
        ) {
          _branchNumber = "00000";
        } else {
          isCorrect = false;
        }
      } else {
        if (branchNumber.length !== 5) {
          if (
            branchText.toLowerCase() === "head office" ||
            branchText === "สำนักงานใหญ่"
          ) {
            _branchNumber = "00000";
          } else {
            isCorrect = false;
          }
        }
      }
    } else if (taxNumberType === "NIDN") {
      if (taxId.length !== 13) {
        isCorrect = false;
      }

      if (isNaN(taxId)) {
        isCorrect = false;
      }
    } else if (taxNumberType === "CCPT") {
      if (taxId.length === 0) {
        isCorrect = false;
      }
    }

    if (taxId === "0000000000000" || Number(taxId) === 0) {
      isCorrect = false;
    }

    if (postcode) {
      if (isNaN(postcode)) {
        isCorrect = false;
      }

      if (postcode.length !== 5) {
        isCorrect = false;
      }
    }

    if (name.length > 140) {
      isCorrect = false;
    }

    if (buildingNumber.length > 16) {
      isCorrect = false;
    }

    if (address.length > 256) {
      isCorrect = false;
    }

    if (streetName.length > 70) {
      isCorrect = false;
    }

    if (postcode.length > 16) {
      isCorrect = false;
    }

    if (taxId.length > 35) {
      isCorrect = false;
    }

    if (_branchNumber.length > 5) {
      isCorrect = false;
    }

    if (phone.length > 35) {
      isCorrect = false;
    }

    if (email.length > 256) {
      isCorrect = false;
    }

    if (note.length > 500) {
      isCorrect = false;
    }

    const formData = {
      taxNumberType: taxNumberType,
      taxID: taxId,
      branch: branchText,
      branchNumber: branchNumber,
      taxBranchNumberType: taxBranchNumberType,
      name,
      buildingNo: buildingNumber,
      address,
      street: streetName,
      provincePrefix,
      province: province,
      districtPrefix,
      district: district,
      subdistrictPrefix: subDistrictPrefix,
      subdistrict: subDistrict,
      postcode,
      email,
      tel: phone, // Assuming phone state is the phone number
      note,
      id: contactId,
      taxInvAbbNumber: taxInvAbbNumber
    };

    // Redirect to PreviewContacts page with form data
    if (isCorrect) {
      setIsLoadComplete(false);

      editContact(contactId, formData);
      // EditConfirm();
      // history.push({
      //   pathname: "/request-forms/contacts-client-list",
      //   state: { isback: true,editSuccess:true  },
      // });
    }
  };

  const editContact = async (id, formData) => {
    setIsLoadComplete(false);

    const formDatasave = {
      "address": formData.address,
      "branchNumber": formData.branchNumber,
      "branchText": formData.branch,
      "buildingNumber": formData.buildingNo,
      "streetPrefix": formData.streetPrefix,
      "streetName": formData.street,
      "districtPrefix": formData.districtPrefix,
      "districtCode": formData.district.key,
      "districtName": formData.district.text,
      "email": formData.email,
      "name": formData.name,
      "phone": formData.tel,
      "phoneCountryCode": formData.tel[0] !== "0" ? "+66" : "foreign",
      "postcode": formData.postcode,
      "provincePrefix": formData.provincePrefix,
      "provinceCode": formData.province.key,
      "provinceName": formData.province.text,
      "subDistrictPrefix": formData.subdistrictPrefix,
      "subDistrictCode": formData.subdistrict.key,
      "subDistrictName": formData.subdistrict.text,
      "taxId": formData.taxID,
      "taxNumberType": formData.taxNumberType,
      "note": formData.note,
      "id": formData.id,
      "taxInvAbbNumber": formData.taxInvAbbNumber
    };

    try {
      formDatasave.created = new Date();
      // Construct the URL for the Azure Function
      // const url = `http://localhost:7071/api/Editformation`;

      // Send a POST request with formData and id to the backend
      // const response = await axios.post(url, { id, formDatasave });

      let tokenResponse;
      try {
        tokenResponse = await instance.acquireTokenSilent({
          ...silentRequest,
          account: account,
        });
      } catch (error) {
        console.error("Error acquiring token: ", error);
        instance.acquireTokenRedirect(silentRequest);
        return;
      }


      const response = await axios.patch(`${API_URL_FORM}/requests/${id}`, {
        "replace": "/data",
        "value": {
          "address": formData.address,
          "branchNumber": formData.branchNumber,
          "branchText": formData.branch,
          "buildingNumber": formData.buildingNo,
          "streetPrefix": formData.streetPrefix,
          "streetName": formData.street,
          "districtPrefix": formData.districtPrefix,
          "districtCode": formData.district.key,
          "districtName": formData.district.text,
          "email": formData.email,
          "name": formData.name,
          "phone": formData.tel,
          "phoneCountryCode": formData.tel[0] !== "0" ? "+66" : "foreign",
          "postcode": formData.postcode,
          "provincePrefix": formData.provincePrefix,
          "provinceCode": formData.province.key,
          "provinceName": formData.province.text,
          "subDistrictPrefix": formData.subdistrictPrefix,
          "subDistrictCode": formData.subdistrict.key,
          "subDistrictName": formData.subdistrict.text,
          "taxId": formData.taxID,
          "taxNumberType": formData.taxNumberType,
          "note": formData.note,
          "id": formData.id,
          "taxInvAbbNumber": formData.taxInvAbbNumber
        }
      }, {
        headers: {
          Authorization: "Bearer " + tokenResponse.accessToken,
        },
      });

      // If the request is successful, return the response data

      if (response.data.statusCode === 200) {
        history.push({
          pathname: "/request-forms/contacts-client-list",
          state: { isback: true, editSuccess: true },
        });
        return response.data;
      } else {
        return response.data.statusCode;
      }
    } catch (error) {
      // If there's an error, throw it so that it can be caught by the caller
      instance.acquireTokenRedirect(silentRequest);
      history.push({
        pathname: "/request-forms/contacts-client-list",
        state: { isback: true, editSuccess: false },
      });
      throw error;
    }
  };

  const handleTaxType = (selectedOption, language) => {
    setTaxNumberType(selectedOption.key);
    setTaxNumberText(selectedOption.text);

    if (selectedOption.key === "TXID") {
      setBranchNumber("00000");
      if (language === "en") {
        setBranchText("Head office");
      } else {
        setBranchText("สำนักงานใหญ่");
      }
    } else {
      setBranchText("");
      setBranchNumber("");
    }

    if (selectedOption.key === "OTHR") {
      setTaxId("N/A");
    } else {
      setTaxId("");
    }
  };



  useEffect(() => {
    handleBranch(taxBranchNumberType, i18n.language)
  }, [i18n.language, taxBranchNumberType])


  const handleBranch = (branchNum, lang) => {
    if (lang === "en") {
      setBranchDisplay([
        { key: "00000", text: "Head office" },
        { key: "branchNumber", text: "Branch" },
      ]);
    } else if (lang === "th") {
      setBranchDisplay([
        { key: "00000", text: "สำนักงานใหญ่" },
        { key: "branchNumber", text: "สาขาเลขที่" },
      ]);
    }

    if (branchNum === "00000") {
      setShowBranchNumber(false);
      // setBranch("00000");
      setBranchNumber("00000");

      if (lang === "en") {
        setBranchText("Head office");
      } else {
        setBranchText("สำนักงานใหญ่");
      }
    } else {
      if (branchNum !== "") {
        // setBranch("branchNumber");
        if (branchNum === "branchNumber") {
          setBranchNumber("00000");

          if (lang === "en") {
            setBranchText("Branch 00000");
          } else {
            setBranchText("สาขา 00000");
          }
        } else {
          setBranchNumber(branchNum);

          if (lang === "en") {
            setBranchText("Branch " + branchNum);
          } else {
            setBranchText("สาขา " + branchNum);
          }
        }
        setShowBranchNumber(true);
      }
    }
  };



  const getErrorMessage = (value) => {
    return value && value !== "0"
      ? ""
      : t("requestFormEditContact.getErrorMessage");
  };

  const getTaxIdErrorMessage = (value) => {
    if (!value) return t("requestFormEditContact.getErrorMessage");
    if (taxNumberType === "TXID" || taxNumberType === "NIDN") {
      if (isNaN(value))
        return t("requestFormEditContact.getBranchNumberErrorMessage1");
      if (value.length !== 13) return t("requestFormEditContact.taxIdError");
    }
    return "";
  };

  const getBranchNumberErrorMessage = (value) => {
    if (!value) return t("requestFormEditContact.pleaseSelectBranch");
    if (isNaN(value))
      return t("requestFormEditContact.getBranchNumberErrorMessage1");
    if (value.length !== 5)
      return t("requestFormEditContact.getBranchNumberErrorMessage2");
    return "";
  };

  const getEmailValidateMessage = (email) => {
    if (!email) {
      return t("requestFormEditContact.pleaseEnterYourEmail");
    }
    if (!isValidEmail(email)) {
      return t("requestFormEditContact.pleaseCheckYourEmail");
    }
    return "";
  };

  // Function to check if email is valid
  const isValidEmail = (email) => {
    // Regular expression for validating email format
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const getTypeNull = (name) => {
    if (!name) {
      return t("requestFormEditContact.pleaseSelectTaxPayerType");
    }

    return "";
  };

  const getNameNull = (name) => {
    if (!name) {
      return t("requestFormEditContact.pleaseEnterYourName");
    }

    return "";
  };

  const getฺBuildingNumberNull = (name) => {
    if (!name) {
      return t("requestFormEditContact.pleaseEnterTheNumber");
    }

    return "";
  };

  const getฺAddressNull = (name) => {
    if (!name) {
      return t("requestFormEditContact.pleaseEnterTheAddress");
    }

    return "";
  };

  const getฺProvinceNull = (name) => {
    if (!name) {
      return t("requestFormEditContact.pleaseSelectProvince");
    }

    return "";
  };

  const getฺDistrictNull = (name) => {
    if (!name) {
      return t("requestFormEditContact.pleaseSelectDistrict");
    }

    return "";
  };

  const getฺsubDistrictNull = (name) => {
    if (!name) {
      return t("requestFormEditContact.pleaseSelectSubDistrict");
    }

    return "";
  };

  const getPostCodeErrorMessage = (value) => {
    if (countryCode === "TH") {
      if (isNaN(value))
        return t("requestFormEditContact.getPostCodeErrorMessage1");
      if (!value || value.length !== 5)
        return t("requestFormEditContact.getPostCodeErrorMessage2");
    }

    if (countryCode === "EN") {
      if (isNaN(value)) return "Please fill 16 numeric characters";
    }
  };

  const [typeContact, setTypeContact] = useState({ key: "", text: "" });

  const documentOptionswithTextTh = [
    { key: "TXID", text: "เลขประจำตัวผู้เสียภาษี (นิติบุคคล)" },
    { key: "NIDN", text: "เลขประจำตัวประชาชน (บุคคลธรรมดา)" },
    { key: "CCPT", text: "เลขที่หนังสือเดินทาง" },
  ];

  const documentOptionswithTextEn = [
    { key: "TXID", text: "Tax ID (Juristic Person) " },
    { key: "NIDN", text: "ID Number (Ordinary Person)" },
    { key: "CCPT", text: "Passport Number" },
  ];

  const options = [
    { key: "TXID", text: t("requestFormEditContact.txid") },
    { key: "NIDN", text: t("requestFormEditContact.nidn") },
    { key: "CCPT", text: t("requestFormEditContact.ccpt") },
  ];

  const handleonClick = (newValue) => {
    handleTaxType(newValue);
  };


  const setPostCodelength = (e) => {
    if (e.target.value.length <= 16 && language === "en") {
      setPostCode(e.target.value);
    }

    if (e.target.value.length <= 5 && language === "th") {
      setPostCode(e.target.value);
    }
  };
  const handlesetbuildingNumber = (e) => {
    setShowValidateBranchNumber(false);
    setBuildingNumber(e.target.value);
  };

  const setNotelength = (e) => {
    // The controlled input pattern can be used for other purposes besides validation,
    // but validation is a useful example
    if (e.target.value.length <= 500) {
      setNote(e.target.value);
    }
  };

  const setTaxIdlength = (e) => {
    // The controlled input pattern can be used for other purposes besides validation,
    // but validation is a useful example
    if (e.length <= 13) {
      setTaxId(e);
    }
  };


  const setTaxInvAbblength = (e) => {
    if (e.length <= 35) {
      setTaxInvAbbNumber(e);
    }
  };



  const setBranchlength = (e) => {
    if (e.length <= 5) {
      setBranchNumber(e);
    }
  };

  const setPhonelength = (e) => {
    if (e.length <= 10) {
      setPhone(e);
    }
  };

  const currentStep = 1;

  useState(() => {
    setStep(currentStep);
  }, []);

  const setAreaPrefix = (_provinceCode) => {
    if (_provinceCode === "10") {
      if (language === "th") {
        setSubDistrictPrefix("แขวง");
        setDistrictPrefix("เขต");
        setProvincePrefix("");
      } else {
        setSubDistrictPrefix("");
        setDistrictPrefix("");
        setProvincePrefix("");
      }
    } else {
      if (language === "en") {
        setSubDistrictPrefix("");
        setDistrictPrefix("");
        setProvincePrefix("");
      } else {
        setSubDistrictPrefix("ต.");
        setDistrictPrefix("อ.");
        setProvincePrefix("จ.");
      }
    }
  };

  return (
    <div className="responsive-element">
      {showMessageBarSuccess && (
        <MessageBar
          messageBarType={MessageBarType.success}
          isMultiline={false}
          onDismiss={() => setShowMessageBarSuccess(false)}
          dismissButtonAriaLabel="Close"
        >
          {t("requestFormEditContact.updateSuccess")}
        </MessageBar>
      )}
      {showMessageBarError && (
        <MessageBar
          messageBarType={MessageBarType.error}
          isMultiline={false}
          onDismiss={() => setShowMessageBarError(false)}
          dismissButtonAriaLabel="Close"
        >
          {t("requestFormEditContact.updateFail")}
        </MessageBar>
      )}
      {showMessageBarRepeatTaxId && (
        <MessageBar
          messageBarType={MessageBarType.error}
          isMultiline={false}
          onDismiss={() => setShowMessageBarRepeatTaxId(false)}
          dismissButtonAriaLabel="Close"
        >
          {t("requestFormEditContact.alreadyHaveTaxId")}
        </MessageBar>
      )}
      {showMessageBarRepeatOTHR && (
        <MessageBar
          messageBarType={MessageBarType.error}
          isMultiline={false}
          onDismiss={() => setShowMessageBarRepeatOTHR(false)}
          dismissButtonAriaLabel="Close"
        >
          {t("requestFormEditContact.alreadyHaveContact")}
        </MessageBar>
      )}
      {showMessageBarSearchError && (
        <MessageBar
          messageBarType={MessageBarType.error}
          isMultiline={false}
          onDismiss={() => setShowMessageBarSearchError(false)}
          dismissButtonAriaLabel="Close"
        >
          {t("company.searchFail")}
        </MessageBar>
      )}
      {showMessageBarSearchNotFound && (
        <MessageBar
          messageBarType={MessageBarType.error}
          isMultiline={false}
          onDismiss={() => setShowMessageBarSearchNotFound(false)}
          dismissButtonAriaLabel="Close"
        >
          {t("company.searchNotFound")}
        </MessageBar>
      )}

      <form onSubmit={handleSubmit}>
        <div className="header-part">
          <div className="header-topic">
            <div className="header-topic-word">
              <Text as="h1" block className={styles.title}>
                {" "}
              </Text>
            </div>
          </div>
        </div>

        <div className="new-section-container">
          <div className={styles.cardStyle}>
            <div className="field-long">
              <Label
                style={{
                  fontSize: "14px",
                  alignSelf: "center",
                  fontWeight: "bold",
                  // color:"var(--secondary-color)"
                }}
              >
                {t("requestFormEditContact.enterClient")}
              </Label>
            </div>

            <div className="first-part">
              <div className="company-information">
                <div className="tax-number-type">
                  <div className="field-long">
                    <Field
                      label={
                        <Label
                          htmlFor={outlineId}
                          style={{
                            fontSize: "14px",

                            fontWeight: "bold",
                            alignSelf: "left",
                            marginBottom: "0px",
                          }}
                        >
                          {t("requestFormEditContact.taxNumberType")}
                        </Label>
                      }
                      required={true}
                      // label={t("requestFormEditContact.taxId")}
                      style={{
                        textAlign: "left",
                        display: "flex",
                        flexDirection: "column",
                        width: "100%",
                      }}
                      size="medium"
                      validationMessage={
                        showValidateTaxID ? getTypeNull(options.key) : ""
                      }
                    >
                      <Dropdown
                        key={i18n.language}
                        aria-labelledby={`${dropdownId}-outline`}
                        placeholder={t("addContact.pleaseSelect")}
                        onOptionSelect={(_, data) => {
                          handleonClick(data.optionValue, i18n.language);
                          setTypeContact(data.optionValue);
                          setShowValidateTaxID(false);

                          // ตั้งค่าเป็น text โดยตรง
                          setTaxTypeText(data.optionValue.text);
                        }}
                        appearance="outline"
                        size="medium"
                        style={{ minWidth: "25%" }}
                        required={true}
                        value={taxTypeText} // ใช้ text ของ dropdown
                        ref={taxNumberTypeRef}
                        onBlur={() => {
                          if (typeContact.key === "") {
                            setTypeErrMsg(DropdownErrMsg);
                            setShowValidateTaxID(true);
                          } else {
                            setTypeErrMsg("");
                          }
                        }}
                        errorMessage={TypeErrMsg}
                        disabled={!isLoadComplete}
                      >
                        {options.map((option) => (
                          <Option
                            key={option.key}
                            value={option} // ต้องตรงกับการใช้ใน onOptionSelect
                            text={option.text}
                          >
                            {option.text}
                          </Option>
                        ))}
                      </Dropdown>
                    </Field>
                  </div>
                </div>

                  <div className="field-long">
                    <Field
                      label={
                        <Label
                          htmlFor={outlineId}
                          style={{
                            fontSize: "14px",
                            fontWeight: "bold",
                            alignSelf: "left",
                            marginBottom: "1px",
                          }}
                        >
                          {taxNumberType === "NIDN"
                            ? t("requestFormEditContact.ninText")
                            : taxNumberType === "TXID"
                              ? t("requestFormEditContact.tinText")
                              : taxNumberType === "OTHR"
                                ? t("requestFormEditContact.passportText")
                                : t("requestFormEditContact.passportText")}
                        </Label>
                      }
                      required
                      // label={t("requestFormEditContact.taxId")}
                      style={{
                        textAlign: "left",
                        display: "flex",
                        flexDirection: "column",
                        width: "100%",
                      }}
                      size="medium"
                      validationMessage={
                        showValidateTaxIDText ? getTaxIdErrorMessage(taxId) : ""
                      }
                    >
                      <Input
                        value={taxId}
                        disabled={!isLoadComplete}
                        onChange={(e) => {
                          setShowValidateTaxIDText(false);
                          if (
                            taxNumberType === "TXID" ||
                            taxNumberType === "NIDN"
                          ) {
                            if (!isNaN(e.target.value)) {
                              setTaxIdlength(e.target.value);
                            }
                          } else {
                            setTaxIdlength(e.target.value);
                          }
                        }}
                        validateOnLoad={false}
                        validateOnFocusOut
                        onBlur={(e) => {
                          setShowValidateTaxIDText(true);
                        }}
                      />
                    </Field>
                  </div>
        

                {taxNumberType === "" && !isLoadComplete && (
                  <div className="field-long">
                    <Field
                      label={
                        <Label
                          htmlFor={outlineId}
                          style={{
                            fontSize: "14px",

                            fontWeight: "bold",
                            alignSelf: "left",
                            marginBottom: "0px",
                          }}
                        >
                          {t("requestFormEditContact.name")}
                        </Label>
                      }
                      style={{
                        textAlign: "left",
                        display: "flex",
                        flexDirection: "column",
                        width: "100%",
                      }}
                      size="medium"
                      required
                      validationMessage={
                        showMessageBarErrorName ? getNameNull(name) : ""
                      }
                    >
                      {" "}
                      <Input
                        disabled={!isLoadComplete}
                        appearance="outline"
                        id={outlineId}
                        size="medium"
                        name="name"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        onGetErrorMessage={getErrorMessage}
                        validateOnLoad={false}
                        validateOnFocusOut
                        required
                      />
                    </Field>
                  </div>
                )}

                {taxNumberType === "TXID" && (
                  <>
                    <div className="branch-display">
                      <div className="field-long">
                        <Field
                          label={
                            <Label
                              htmlFor={outlineId}
                              style={{
                                fontSize: "14px",

                                fontWeight: "bold",
                                alignSelf: "left",
                                marginBottom: "0px",
                              }}
                            >
                              {t("requestFormEditContact.branchDisplay")}
                            </Label>
                          }
                          // label={t("requestFormEditContact.taxId")}
                          style={{
                            textAlign: "left",
                            display: "flex",
                            flexDirection: "column",
                            width: "100%",
                          }}
                          size="medium"
                          validationMessage={
                            showValidateBranchNumber
                              ? getBranchNumberErrorMessage(branchDisplay.key)
                              : ""
                          }
                          required
                        >
                          <Dropdown
                            key={i18n.language}
                            aria-labelledby={`${dropdownId}-outline`}
                            size="medium"
                            style={{ minWidth: "25%" }}
                            placeholder={t("addContact.pleaseSelect")}
                            appearance="outline"
                            required
                            value={getBranchTypeText(taxBranchNumberType)}
                            ref={taxBranchNumberTypeRef}
                            onBlur={() => {
                              if (branchBlankDisplay.key === "") {
                                setBranchErrMsg(DropdownErrMsg);
                                setShowValidateBranchNumber(true);
                              } else {
                                setBranchErrMsg("");
                              }
                            }}
                            errorMessage={branchErrMsg}
                            disabled={!isLoadComplete}
                          >
                            {branchDisplay.map((option) => (
                              <Option
                                key={option.key}
                                onClick={() => {
                                  setBranchBlankDisplay(option);
                                  setTaxBranchNumberType(option.key);
                                  setShowValidateBranchNumber(false);
                                  handleBranch(option.key, i18n.language);
                                }}
                                text={option.text}
                              >
                                {option.text}
                              </Option>
                            ))}
                          </Dropdown>
                        </Field>
                      </div>
                    </div>

                    {showBranchNumber && (
                      <div className="branch-number">
                        <div className="field-long">
                          <Field
                            label={
                              <Label
                                htmlFor={outlineId}
                                style={{
                                  fontSize: "14px",

                                  fontWeight: "bold",
                                  alignSelf: "left",
                                  marginBottom: "0px",
                                }}
                              >
                                {t("requestFormEditContact.branchNumber")}
                              </Label>
                            }
                            required
                            style={{
                              textAlign: "left",
                              display: "flex",
                              flexDirection: "column",
                              width: "100%",
                            }}
                            size="medium"
                            validationMessage={
                              showValidateBranchNumber
                                ? getBranchNumberErrorMessage(branchNumber)
                                : ""
                            }
                          >
                            <Input
                              disabled={!isLoadComplete}
                              value={branchNumber}
                              onChange={(e) => {
                                setShowValidateBranchNumber(false);
                                if (!isNaN(e.target.value)) {
                                  setBranchlength(e.target.value);

                                  if (language === "en") {
                                    setBranchText("Branch " + e.target.value);
                                  } else {
                                    setBranchText("สาขา " + e.target.value);
                                  }
                                }
                              }}
                            />
                          </Field>
                        </div>
                      </div>
                    )}
                  </>
                )}

                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "row",
                    height: "80px",
                  }}
                >
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "row",
                      height: "80px",
                    }}
                  >
                    {(taxNumberType === "TXID" ||
                      taxNumberType === "OTHR" ||
                      taxNumberType === "CCPT") && (
                        <div className="field-long">
                          <Field
                            label={
                              <Label
                                htmlFor={outlineId}
                                style={{
                                  fontSize: "14px",

                                  fontWeight: "bold",
                                  alignSelf: "left",
                                  marginBottom: "0px",
                                }}
                              >
                                {taxNumberType === "NIDN"
                                  ? t("requestFormEditContact.companyName")
                                  : taxNumberType === "TXID"
                                    ? t("requestFormEditContact.companyName")
                                    : taxNumberType === "OTHR"
                                      ? t("requestFormEditContact.onlyName")
                                      : t("requestFormEditContact.onlyName")}
                              </Label>
                            }
                            style={{
                              textAlign: "left",
                              display: "flex",
                              flexDirection: "column",
                              width: "100%",
                            }}
                            size="medium"
                            required
                            validationMessage={
                              showMessageBarErrorName ? getNameNull(name) : ""
                            }
                          >
                            <Input
                              disabled={!isLoadComplete}
                              appearance="outline"
                              id={outlineId}
                              size="medium"
                              name="name"
                              value={name}
                              onChange={(e) => setName(e.target.value)}
                              onGetErrorMessage={getErrorMessage}
                              validateOnLoad={false}
                              validateOnFocusOut
                              required
                              onBlur={(e) => {
                                setShowMessageBarErrorName(true);
                              }}
                            />
                          </Field>
                        </div>
                      )}
                    {taxNumberType === "NIDN" && (
                      <div className="field-long">
                        <Field
                          label={
                            <Label
                              htmlFor={outlineId}
                              style={{
                                fontSize: "14px",

                                fontWeight: "bold",
                                alignSelf: "left",
                                marginBottom: "0px",
                              }}
                            >
                              {t("requestFormEditContact.name")}
                            </Label>
                          }
                          style={{
                            textAlign: "left",
                            display: "flex",
                            flexDirection: "column",
                            width: "100%",
                          }}
                          size="medium"
                          required
                          validationMessage={
                            showMessageBarErrorName ? getNameNull(name) : ""
                          }
                        >
                          {" "}
                          <Input
                            disabled={!isLoadComplete}
                            appearance="outline"
                            id={outlineId}
                            size="medium"
                            name="name"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            onGetErrorMessage={getErrorMessage}
                            validateOnLoad={false}
                            validateOnFocusOut
                            onBlur={(e) => {
                              setShowMessageBarErrorName(true);
                            }}
                            required
                          />
                        </Field>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <br />

          <div className={styles.cardStyle}>
            <div className="field-long">
              <Label
                style={{
                  fontSize: "14px",

                  fontWeight: "bold",
                  alignSelf: "center",
                }}
              >
                {t("requestFormEditContact.addressHeader")}
              </Label>
            </div>
            {/* <Stack
              horizontal
              tokens={stackTokens}
              styles={{ root: { marginTop: 10 } }}
            ></Stack> */}

            <div
              style={{
                display: "flex",
                flexDirection: "column",
              }}
              className="new-section-container"
            >
              <div className="build-number-address">
                <div className="field-long">
                  <Field
                    label={
                      <Label
                        htmlFor={outlineId}
                        style={{
                          fontSize: "14px",

                          fontWeight: "bold",
                          alignSelf: "left",
                          marginBottom: "0px",
                        }}
                      >
                        {t("requestFormEditContact.buildingNumber")}
                      </Label>
                    }
                    style={{
                      textAlign: "left",
                      display: "flex",
                      flexDirection: "column",
                      width: "100%",
                    }}
                    size="medium"
                    validationMessage={
                      showValidateBuildingNumber
                        ? getฺBuildingNumberNull(buildingNumber)
                        : ""
                    }
                    required
                  >
                    {" "}
                    <Input
                      disabled={!isLoadComplete}
                      appearance="outline"
                      id={outlineId}
                      value={buildingNumber}
                      min-width="25%"
                      size="medium"
                      name="buildingNumber"
                      onChange={handlesetbuildingNumber}
                      validateOnLoad={false}
                      validateOnFocusOut
                      required
                      onBlur={(e) => {
                        setShowValidateBuildingNumber(true);
                      }}
                    />
                  </Field>
                </div>

                <div className="field-long">
                  <Field
                    label={
                      <Label
                        htmlFor={outlineId}
                        style={{
                          fontSize: "14px",

                          fontWeight: "bold",
                          alignSelf: "left",
                          marginBottom: "0px",
                        }}
                      >
                        {t("requestFormEditContact.address")}
                      </Label>
                    }
                    style={{
                      textAlign: "left",
                      display: "flex",
                      flexDirection: "column",
                      width: "100%",
                    }}
                    size="medium"
                    validationMessage={
                      showValidateAddress ? getฺAddressNull(address) : ""
                    }
                    required
                  >
                    <Input
                      disabled={!isLoadComplete}
                      appearance="outline"
                      size="medium"
                      id={outlineId}
                      name="address"
                      label={t("requestFormEditContact.address")}
                      value={address}
                      onChange={(e) => {
                        setShowValidateAddress(false);
                        setAddress(e.target.value);
                      }}
                      validateOnLoad={false}
                      validateOnFocusOut
                      required
                      onBlur={(e) => {
                        setShowValidateAddress(true);
                      }}
                    />
                  </Field>
                </div>
              </div>

              <div className="field-long">
                <div className="street">
                  <Field
                    label={
                      <Label
                        htmlFor={outlineId}
                        style={{
                          fontSize: "14px",

                          fontWeight: "bold",
                          alignSelf: "left",
                          marginBottom: "0px",
                        }}
                      >
                        {t("requestFormEditContact.streetName")}
                      </Label>
                    }
                    style={{
                      textAlign: "left",
                      display: "flex",
                      flexDirection: "column",
                      width: "100%",
                    }}
                    size="medium"
                  >
                    <Input
                      disabled={!isLoadComplete}
                      appearance="outline"
                      id={outlineId}
                      size="medium"
                      name="streetName"
                      label={t("requestFormEditContact.streetName")}
                      value={streetName}
                      onChange={(e) => setStreetName(e.target.value)}
                    />
                  </Field>
                </div>
              </div>

              <div className="province">
                <div className="field-long">
                  <Field
                    label={
                      <Label
                        style={{
                          fontSize: "14px",

                          fontWeight: "bold",
                          alignSelf: "left",
                          marginBottom: "0px",
                        }}
                      >
                        {t("requestFormEditContact.province")}
                      </Label>
                    }
                    style={{
                      textAlign: "left",
                      display: "flex",
                      flexDirection: "column",
                      width: "100%",
                    }}
                    size="medium"
                    validationMessage={
                      showValidateProvince
                        ? getฺProvinceNull(provinceList.key)
                        : ""
                    }
                    required
                  >
                    <Dropdown
                      key={i18n.language}
                      aria-labelledby={`${dropdownId}-outline`}
                      placeholder={t("addContact.pleaseSelect")}
                      onOptionSelect={(_, data) => {
                        console.log("data", data);
                        setProvince(data.optionValue);
                        setAreaPrefix(data.optionValue.key);
                        setProvinceKey(data.optionValue.key);
                        setDistrict({ key: "", text: "" });
                        setDistrictList(
                          getDistrictList(data.optionValue.key, i18n.language)
                        );
                        setSubDistrict({ key: "", text: "" });
                        setProvinceErrMsg("");
                        setDistrictErrMsg("");
                        setSubDistrictErrMsg("");
                        setShowValidateProvince(false);
                      }}
                      appearance="outline"
                      name="province"
                      required
                      size="medium"
                      style={{ minWidth: "25%" }}
                      label={t("addContact.province")}
                      value={province.text}
                      ref={provinceRef}
                      onBlur={() => {
                        if (province.key === "") {
                          setProvinceErrMsg(DropdownErrMsg);
                          setShowValidateProvince(true);
                        } else {
                          setProvinceErrMsg("");
                        }
                      }}
                      errorMessage={provinceErrMsg}
                      disabled={!isLoadComplete}
                    >
                      {provinceList.map((option) => (
                        <Option value={option} key={option.key}>
                          {option.text}
                        </Option>
                      ))}
                    </Dropdown>
                  </Field>
                </div>
              </div>

              <div className="district">
                <div className="field-long">
                  <Field
                    label={
                      <Label
                        id={`${dropdownId}-outline`}
                        style={{
                          fontSize: "14px",

                          fontWeight: "bold",
                          alignSelf: "left",
                          marginBottom: "0px",
                        }}
                      >
                        {t("requestFormEditContact.district")}
                      </Label>
                    }
                    style={{
                      textAlign: "left",
                      display: "flex",
                      flexDirection: "column",
                      width: "100%",
                    }}
                    size="medium"
                    validationMessage={
                      showValidateDistrict
                        ? getฺDistrictNull(districtList.key)
                        : ""
                    }
                    required
                  >
                    <Dropdown
                      key={i18n.language}
                      placeholder={t("addContact.pleaseSelect")}
                      onOptionSelect={(_, data) => {
                        console.log("data", data);
                        setDistrict(data.optionValue);
                        setSubDistrict({ key: "", text: "" });
                        setSubDistrictList(
                          getSubDistrictList(data.optionValue.key, i18n.language)
                        );
                        setDistrictErrMsg("");
                        setSubDistrictErrMsg("");
                        setShowValidateDistrict(false);
                      }}
                      appearance="outline"
                      required
                      style={{ minWidth: "25%" }}
                      size="medium"
                      label={t("addContact.district")}
                      value={district.text}
                      errorMessage={districtErrMsg}
                      disabled={!isLoadComplete}
                    >
                      {districtList.map((option) => (
                        <Option value={option} key={option.key}>
                          {option.text}
                        </Option>
                      ))}
                    </Dropdown>
                  </Field>{" "}
                </div>
              </div>

              <div className="subdistrict">
                <div className="field-long">
                  <Field
                    label={
                      <Label
                        style={{
                          fontSize: "14px",

                          fontWeight: "bold",
                          alignSelf: "left",
                          marginBottom: "0px",
                        }}
                      >
                        {t("requestFormEditContact.subDistrict")}
                      </Label>
                    }
                    style={{
                      textAlign: "left",
                      display: "flex",
                      flexDirection: "column",
                      width: "100%",
                    }}
                    size="medium"
                    validationMessage={
                      showValidateSubDistrict
                        ? getฺsubDistrictNull(subDistrictList.key)
                        : ""
                    }
                    required
                  >
                    <Dropdown
                      key={i18n.language}
                      aria-labelledby={`${dropdownId}-outline`}
                      placeholder={t("addContact.pleaseSelect")}
                      onOptionSelect={(_, data) => {
                        setSubDistrict(data.optionValue);
                        setSubDistrictErrMsg("");
                        setShowValidateSubDistrict(false);

                      }}
                      appearance="outline"
                      style={{ minWidth: "25%" }}
                      required
                      size="medium"
                      label={t("addContact.subDistrict")}
                      value={subDistrict.text}
                      ref={subDistrictRef}
                      onBlur={(e) => {
                        if (district.key !== "" && subDistrict.key === "") {
                          setSubDistrictErrMsg(DropdownErrMsg);
                          setShowValidateSubDistrict(true);
                        } else {
                          setSubDistrictErrMsg("");
                        }
                      }}
                      errorMessage={subDistrictErrMsg}
                      disabled={!isLoadComplete}
                    >
                      {subDistrictList.map((option) => (
                        <Option
                          key={option.key}
                          value={option}
                          text={option.text}
                        >
                          {option.text}
                        </Option>
                      ))}

                    </Dropdown>
                  </Field>{" "}
                </div>
              </div>
              <div className="build-number-address">
                <div className="postcode">
                  <div className="field-long">
                    <Field
                      label={
                        <Label
                          htmlFor={outlineId}
                          style={{
                            fontSize: "14px",

                            fontWeight: "bold",
                            alignSelf: "left",
                            marginBottom: "0px",
                          }}
                        >
                          {t("requestFormEditContact.postCode")}
                        </Label>
                      }
                      style={{
                        textAlign: "left",
                        display: "flex",
                        flexDirection: "column",
                        width: "100%",
                      }}
                      size="medium"
                      validationMessage={
                        showMessageBarErrorPostcode
                          ? getPostCodeErrorMessage(postcode)
                          : ""
                      }
                      required
                    >
                      <Input
                        disabled={!isLoadComplete}
                        appearance="outline"
                        id={outlineId}
                        name="postcode"
                        type="number"
                        size="medium"
                        min-width="25%"
                        label={t("requestFormEditContact.postCode")}
                        value={postcode}
                        onChange={setPostCodelength}
                        onGetErrorMessage={getPostCodeErrorMessage}
                        validateOnLoad={false}
                        validateOnFocusOut
                        required
                        onBlur={(e) => {
                          setShowMessageBarPostcode(true);
                        }}
                      />
                    </Field>{" "}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <br />

        <div className={styles.cardStyle}>
          <div className="field-long">
            {" "}
            <Label
              style={{
                fontSize: "14px",

                fontWeight: "bold",
                alignSelf: "center",
              }}
            >
              {t("requestFormEditContact.contactHeader")}
            </Label>
          </div>

          <div className="contact">
            <div className="field-long">
              <Field
                label={
                  <Label
                    htmlFor={outlineId}
                    style={{
                      fontSize: "14px",
                      fontWeight: "bold",
                      alignSelf: "left",
                      marginBottom: "0px",
                    }}
                  >
                    {t("requestFormEditContact.email")}
                  </Label>
                }
                style={{
                  textAlign: "left",
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                }}
                size="medium"
                required
                validationMessage={
                  showValidateEmail ? getEmailValidateMessage(email) : ""
                }
              >
                <Input
                  disabled={!isLoadComplete}
                  appearance="outline"
                  id={outlineId}
                  type="email"
                  name="email"
                  size="medium"
                  label={t("requestFormEditContact.email")}
                  minWidth="80%"
                  value={email}
                  onChange={(e) => {
                    setShowValidateEmail(false);
                    setEmail(e.target.value);
                  }}
                  onBlur={(e) => {
                    setShowValidateEmail(true);
                  }}
                />
              </Field>
            </div>

            <div className="field-long">
              <Label
                htmlFor={outlineId}
                style={{
                  fontSize: "14px",

                  fontWeight: "bold",
                  alignSelf: "left",
                  marginBottom: "0px",
                }}
              >
                {t("requestFormEditContact.phone")}
              </Label>
              <Field
                style={{
                  textAlign: "left",
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                }}
                size="medium"
              >
                <Input
                  disabled={!isLoadComplete}
                  appearance="outline"
                  id={outlineId}
                  name="phone"
                  size="medium"
                  label={t("requestFormEditContact.phone")}
                  minWidth="80%"
                  value={phone}
                  onChange={(e) => setPhonelength(e.target.value)}
                />
              </Field>
            </div>
          </div>

          <div className="field-long">
            <Field
              label={
                <Label
                  style={{
                    fontSize: "14px",
                    fontWeight: "bold",
                    textAlign: "left",
                  }}
                >
                  {t("requestFormEditContact.note")}
                </Label>
              }
              size="medium"
              style={{
                textAlign: "left",
                display: "flex",
                flexDirection: "column",
                width: "100%",
              }}
            >
              <Textarea
                disabled={!isLoadComplete}
                onChange={setNotelength}
                value={note}
                size="medium"
                resize="both"
              />
            </Field>
          </div>
        </div>

        <br />
        <div className={styles.cardStyle}>
          <div className="field-long">
            {" "}
            <Label
              style={{
                fontSize: "14px",
                fontWeight: "bold",
                alignSelf: "center",
              }}
            >
              {t("requestFormEditContact.taxInvAbb")}
            </Label>
          </div>

          <div className="contact">
            <div className="field-long">
              <Field
                label={<Label
                  htmlFor={outlineId}
                  style={{
                    fontSize: "14px",
                    fontWeight: "bold",
                    alignSelf: "left",
                  }}
                >
                  {t("requestFormEditContact.number")}
                </Label>}
                style={{
                  textAlign: "left",
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                }}
                size="medium"
              >

                <Input
                  disabled={!isLoadComplete}
                  appearance="outline"
                  id={outlineId}
                  name="taxInvAbbNumber"
                  size="medium"
                  label={t("requestFormEditContact.phone")}
                  minWidth="80%"
                  value={taxInvAbbNumber}
                  onChange={(e) => setTaxInvAbblength(e.target.value)}
                />
              </Field>
            </div>


          </div>
        </div>

        <div className="responsive-boxbutton" style={{ marginTop: "20px" }}>
          <Stack horizontal style={centerItem} tokens={{ childrenGap: 30 }}>
            <Dialog modalType="modal">
              <DialogTrigger disableButtonEnhancement>
                <Button
                  appearance="primary"
                  style={{ marginRight: "10px" }}
                  disabled={!isLoadComplete}
                >
                  {t("requestFormAdminFormEditor.save")}
                </Button>
              </DialogTrigger>

              <DialogSurface className="fluentui-dialog">
                <DialogBody className="dialog-body">
                  <DialogTitle>
                    {" "}
                    {t("requestFormEditContact.confirmToSaveHeader")}
                  </DialogTitle>

                  <DialogContent>
                    {" "}
                    {t("requestFormEditContact.confirmToSaveData")}
                  </DialogContent>
                  <DialogActions>
                    <DialogTrigger disableButtonEnhancement>
                      <Button
                        appearance="primary"
                        className="button-dialog"
                        value="next"
                        onClick={() => handleSubmit()}
                      >
                        {t("requestFormEditContact.confirm")}
                      </Button>
                    </DialogTrigger>

                    <DialogTrigger disableButtonEnhancement>
                      <Button appearance="secondary" className="button-dialog">
                        {t("requestFormEditContact.cancel")}
                      </Button>
                    </DialogTrigger>
                  </DialogActions>
                </DialogBody>
              </DialogSurface>
            </Dialog>
            <Button
              appearance="secondary"
              disabled={!isLoadComplete}
              onClick={() => {
                history.push({
                  pathname: "/request-forms/contacts-client-list",
                  state: { isback: true },
                });
              }}
            >
              {t("requestFormEditContact.back")}
            </Button>
          </Stack>
        </div>

        <br />
        <br />
      </form>
    </div>
  );
};

export default EditContacts;
